// @ts-ignore
import bidiFactory from "bidi-js";

let _bidi: any;
function bidi() {
  if (!_bidi) {
    _bidi = bidiFactory();
  }
  return _bidi;
}

export function reverseBidiChars(input: string): string {
  if (!hasRTL(input)) {
    return input;
  }

  const embeddingLevels = bidi().getEmbeddingLevels(input);
  return bidi().getReorderedString(
    input, //the full input string
    embeddingLevels //the full result object from getEmbeddingLevels
  );
}

const HAS_RTL_REGEX = /[\p{Script=Arabic}\p{Script=Hebrew}]/giu;
function hasRTL(input: string): boolean {
  return HAS_RTL_REGEX.test(input);
}
