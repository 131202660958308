import type { schema } from "@editor/schema";
import type { Background, BackgroundAsset, BackgroundImage, Category, ColorPack, CSSColor, DesignDef, FontPack } from "./types";

import defaultsDeep from "lodash.defaultsdeep";
import { poser } from "@pkgs/poser";
import { traverse } from "./helpers";
import type { ProCapName } from "@ui/currentUser";

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

const pos = poser<{ id: string; $order: Position }>("id", "$order");

// Lodash assign customizer
type AssignCustomizer = (objectValue: any, sourceValue: any, key?: string, object?: {}, source?: {}) => any;

type TimeRange = {
  start: Date;
  end: Date;
};

type Badge = {
  text: string;
  bg?: CSSColor;
  color?: CSSColor;
  $active?: boolean | TimeRange;
};

// types of patterns
type PatternKey = "noise";

type Position = string;

export type CSSColorWithPattern =
  | CSSColor
  | {
      color: CSSColor;
      pattern: PatternKey;
    };

// Basic design asset
interface JSONDesignAsset<TTypeKey extends string = string> {
  id: string;
  name?: string;
  $type: TTypeKey;
  $category: string;
  $order: Position;
  $active?: boolean | TimeRange;
  $badge?: Badge;
  proCap?: ProCapName;
}

export type JSONDesignDef = {
  id: string;
  name: string;
  icon: string;
  $order: Position;
  $active?: boolean | TimeRange | "dev";
  $defaults?: {
    color?: RecursivePartial<JSONColorPack>;
    font?: RecursivePartial<JSONFontPack>;
    bg?: RecursivePartial<JSONBackground>;
  };
  assets: Record<string, JSONAnyDesignAsset>;
  categories: {
    color?: Record<string, JSONCategory>;
    font?: Record<string, JSONCategory>;
    bg?: Record<string, JSONCategory>;
  };
};

type JSONCategory = {
  id: string;
  name: string;
  $order: string;
  proCap?: ProCapName;
};

export type JSONAnyDesignAsset = JSONColorPack | JSONFontPack | JSONAnyBackground;

/// Fontpack options

export interface JSONFontPack extends JSONDesignAsset<"fontpack"> {
  "id": string;
  "name": string;

  "header": {
    title: CustomFontStyle;
    subtitle: CustomFontStyle;
    spacing?: number;
  };

  "text.title": CustomFontStyleWithVariants;

  "body": {
    family: string;
    size: number;
  } & FontStyle;
}

type CustomFontStyle = FontStyle & {
  customFont: ResourceLookup<"font">;
  minSize: number;
  maxSize: number;
  ext?: FontExtension[];
};

type CustomFontStyleWithVariants = FontStyle & {
  customFont: ResourceLookup<"font">;
} & {
  [id: string]: number;
};

// Margins / curves / any shit that we will want
type FontExtension = {};

type FontStyle = {
  // letter spacing (in px)
  // example: 0.1
  letterSpacing?: number;
  // line height (in em units)
  // example: 1.2
  lineHeight?: number;

  spaceTracking?: number;
  weight?: string;
};

// Background options

type BaseBackgroundAsset = JSONDesignAsset<"background:page-only" | "background:blurred" | "background" | "background:solid"> & {
  excludedColorIds: string[];
};

type JSONPageOnlyBackgroundAsset = BaseBackgroundAsset & {
  $type: "background:page-only";
  image?: ResourceLookup<"image">;
};

type JSONSolidBackgroundAsset = BaseBackgroundAsset & {
  $type: "background:solid";
  color: CSSColorWithPattern;
};

type JSONBlurredBackgroundAsset = BaseBackgroundAsset & {
  $type: "background:blurred";
  image?: ResourceLookup<"image">;
};

type JSONBackground = BaseBackgroundAsset & {
  $type: "background";
  dark?: boolean;
  thumbnail: {
    color: CSSColor;
    image?: ResourceLookup<"image">;
  };
  body?: {
    background?: CSSColor;
    shadow?: boolean;
  };
  assets: Array<{
    type: "pattern" | "image" | "solid" | "gradient";
    location: "page-full" | "page-top" | "header" | "page-footer";
    filters?: Array<{}>;
    layer?: number;
    color?: CSSColor;
    image?: ResourceLookup<"image">;
  }>;
};

export type JSONAnyBackground = JSONBackground | JSONPageOnlyBackgroundAsset | JSONSolidBackgroundAsset | JSONBlurredBackgroundAsset;

type ResourceLookup<TType = "image" | "font"> = {
  $type: TType;
  id: string;
};

// Color

export type JSONColorPack = JSONDesignAsset<"colorpack"> & {
  $type: "colorpack";
  dark?: boolean;
  thumbnail: {
    primary: CSSColorWithPattern;
    secondary: CSSColorWithPattern;
  };

  // Block customization + newsletter colors
} & NewsletterColors &
  {
    [P in keyof BlockCustomizations]: BlockCustomizations[P];
  };

type NewsletterColors = {
  "global.text"?: CSSColor;
  "global.link"?: CSSColor;
};

interface BlockCustomizations {
  "header": {
    title: CSSColor;
    subtitle: CSSColor;
    tint?: CSSColorWithPattern;
    bg?: CSSColorWithPattern;
  };

  "text.title": {
    stripe?: CSSColor;
    text: CSSColor;
  };

  "button": {
    background: CSSColorWithPattern;
    text: CSSColor;
  };

  "event": {
    background: CSSColorWithPattern;
    text: CSSColor;
  };

  "signature": {
    background: CSSColorWithPattern;
    text: CSSColor;
  };

  "separator": {
    stripe: CSSColor;
  };
}

type FeelResourcesDict = {
  backgrounds: Record<string, BackgroundImage>;
  fonts: Record<string, any>;
};

// Load from the design json, convert to design def
export function fromJSON(input: JSONDesignDef, feelResources: FeelResourcesDict): DesignDef {
  // setup
  const d: DesignDef = {
    id: input.id as schema.DesignFeelKey,
    $order: input.$order,
    $active: input.$active === "dev" ? import.meta.env.DEV : input.$active !== false,
    icon: input.icon,
    name: input.name,
    backgrounds: [],
    colorPacks: [],
    fontPacks: []
  };

  loadCategories(d, input);

  function resolveResourceLookup<T extends object>(asset: T): T {
    traverse(asset, (key, value, object) => {
      if (isResourceLookup(value)) {
        switch (value.$type) {
          case "font":
            object[key] = feelResources.fonts[value.id];
            break;
          case "image":
            object[key] = staticBg(value.id, feelResources.backgrounds[value.id]);
            break;
        }
      }
    });

    return asset;
  }

  Object.values(input.assets).forEach((asset) => {
    switch (asset.$type) {
      case "fontpack":
        return fontPackFromJSON(asset, d, resolveResourceLookup, input?.$defaults?.font);
      case "colorpack":
        return colorPackFromJSON(asset, d, resolveResourceLookup, input?.$defaults?.color);
      case "background":
      case "background:solid":
      case "background:blurred":
      case "background:page-only":
        return backgroundFromJSON(asset, d, resolveResourceLookup, input?.$defaults?.bg);
    }
  });

  // sort all items
  [...d.backgrounds, ...d.colorPacks, ...d.fontPacks].forEach((cat) => pos.sort(cat.items));

  return d;
}

function loadCategories(d: DesignDef, input: JSONDesignDef) {
  Object.values(input.categories.bg || {}).forEach((c) => {
    d.backgrounds.push({
      ...c,
      items: []
    });
  });

  Object.values(input.categories.color || {}).forEach((c) => {
    d.colorPacks.push({
      ...c,
      items: []
    });
  });

  Object.values(input.categories.font || {}).forEach((c) => {
    d.fontPacks.push({
      ...c,
      items: []
    });
  });

  // sort everything after loading
  [d.fontPacks, d.colorPacks, d.backgrounds].forEach((i) => pos.sort(i));
}

function fontPackFromJSON(asset: JSONFontPack, d: DesignDef, resolveResourceLookup: any, defaults?: RecursivePartial<JSONFontPack>) {
  const p: FontPack = resolveResourceLookup(defaultsDeep({}, defaults, asset)) as FontPack;

  appendToCategory(d.fontPacks, p);
}

function colorPackFromJSON(asset: JSONColorPack, d: DesignDef, resolveResourceLookup: any, defaults?: RecursivePartial<JSONColorPack>) {
  const p: ColorPack = resolveResourceLookup(defaultsDeep({}, defaults, asset)) as ColorPack;

  // Here we set defaults (as we added more colors but want a fallback

  p.event = isEmpty(p.event)
    ? {
        text: p.button.text || "#ffffff",
        background: p["text.title"].stripe || "#505060"
      }
    : p.event;

  // signature
  p.signature = isEmpty(p.signature)
    ? {
        text: p.button.text || "#ffffff",
        background: p["text.title"].stripe || "#505060"
      }
    : p.signature;

  // separator
  p.separator = isEmpty(p.separator) ? { stripe: p["text.title"].stripe || "#cccccc" } : p.separator;

  appendToCategory(d.colorPacks, p);
}

function isEmpty(o: object) {
  return !o || !Object.values(o).filter((i) => i).length;
}

const ROOT = "https://cdn.smore.com/_fr";
function toStatic(id: string, variant: string) {
  return `${ROOT}/${id}.${variant}`;
}
function staticBg(id: string, bg: BackgroundImage): BackgroundImage {
  return {
    xs: bg.xs,
    hex: bg.hex,
    sm: toStatic(id, bg.sm),
    xl: toStatic(id, bg.xl),
    g: bg.g,
    blur: toStatic(id, bg.blur)
  };
}

function backgroundFromJSON(
  asset: JSONAnyBackground,
  d: DesignDef,
  resolveResourceLookup: any,
  defaults?: RecursivePartial<JSONBackground>
) {
  const bg: Background = resolveResourceLookup(defaultsDeep({}, defaults, expandBackgroundAsset(asset))) as Background;

  appendToCategory(d.backgrounds, bg);
}

function expandBackgroundAsset(asset: JSONAnyBackground) {
  switch (asset.$type) {
    case "background":
      return asset;

    case "background:blurred":
    case "background:page-only":
      const { image, $type } = asset;
      delete asset.image;

      let assets: any[] = [];

      if ($type === "background:blurred") {
        assets = [
          { location: "page-full", type: "image", image, filters: ["blur"] },
          { location: "header", type: "image", image }
        ];
      } else if ($type === "background:page-only") {
        assets = [{ location: "page-full", type: "image", image }];
      }

      return {
        ...asset,
        thumbnail: {
          image,
          color: "#ccc"
        },
        assets
      };
    case "background:solid":
      const { color } = asset;

      return {
        ...asset,
        thumbnail: {
          color
        },
        assets: [
          { location: "page-full", type: "solid", color },
          { location: "header", type: "solid", color }
        ]
      };
  }
}

function appendToCategory<T extends { $order: Position; $category: string }>(categories: Category<T>[], asset: T) {
  const cat = categories.find((i) => i.id === asset.$category);
  if (cat) {
    cat.items.push(asset);
  }
}

function isResourceLookup(input: any): input is ResourceLookup {
  if (input && typeof input === "object" && "$type" in input && "id" in input && input.$type in { font: 1, image: 1 }) {
    return true;
  }

  return false;
}
