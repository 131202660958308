<script lang="ts">
  import { getNewsletterStores } from "../../newsletterStores";

  export let staticMapUrl: string = "";
  export let address: string = "";
  export let width: number = 380;
  export let height: number = 164;
  const { ctx } = getNewsletterStores();

  function getMapUrl(address: string) {
    if (!address || address.trim().length === 0) {
      return "";
    }

    return ctx.href(`/app/pages/google_map_url?width=${width}&height=${height}&address=${encodeURI(address)}`, {
      type: "event",
      ignoreStorage: true
    });
  }

  // Note: We need to escape the single quotes in the background image URL, otherwise it does not work.
  $: backgroundMap = `background-image: url(${staticMapUrl || getMapUrl(address).replace(/'/g, "%27")});`;
</script>

<div style={backgroundMap} class="absolute inset-0 flex items-center justify-center bg-center bg-no-repeat bg-cover">
  {#if !address || !address.trim()}
    <div class="flex flex-col items-center">
      <i class="mb-1.5 text-gray-600 material-icons notranslate">map</i>
      <p class="text-gray-600 text-12">No map preview</p>
    </div>
  {/if}
</div>
