import { Padding, padPosition, padSize, Position, Size, splitColor, TextComponent, normalizePadding } from "./base";
import type {ChildTransformInfo, TransformFunction} from "./layout";


export function textShadow(pad: Padding, bg: string, borderRadius: number = 8): TransformFunction {
    const [color, opacity] = splitColor(bg);

    pad = normalizePadding(pad)



    return (av: Size, pos: Position) => {
        
        const ps = padSize(pad, av);
        const pp = padPosition(pad, pos);
        
        
        return [
            [ps, pp],
            (svg: string, children: ChildTransformInfo[]) => {

                const lines = children.map(({c, pos, size}) => {
                    // handle the line shadows
                    const textLines = (c as TextComponent).linesInfo(size, pos);
                    
                    return textLines.map(({p, size}) => 
                    `<rect rx="${borderRadius}" x="${p.x - pad[3]}" y="${p.y - pad[0]}" width="${size.w + pad[1] + pad[3]}" height="${size.h + pad[0] + pad[2]}" r="4"/>`).join("")
                })

                const shadow = `<g transform="translate(${pos.x}, ${pos.y})" fill="${color}" 
                ${opacity !== "1.00" ? `opacity="${opacity}"`: ""}
                >${lines.join("")}</g>`  

                return shadow + svg;
            }
        ]
    }
} 
