import BlockTitleLg from "./text.title.lg.svg";
import BlockTitleMd from "./text.title.md.svg";
import BlockTitleSm from "./text.title.sm.svg";

import BlockSeparatorSm from "./misc.separator.sm.svg";
import BlockSeparatorMd from "./misc.separator.md.svg";
import BlockSeparatorLg from "./misc.separator.lg.svg";

import BlockVideoSideLeft from "./embed.video.side-l.svg";
import BlockVideoLg from "./embed.video.lg.svg";

import BlockItemsList from "./items.list.svg";
import BlockItemsCol2 from "./items.2-columns.svg";
import BlockItemsCol3 from "./items.3-columns.svg";

import BlockParagraphSideLeft from "./text.paragraph.side-l.svg";
import BlockParagraphSideRight from "./text.paragraph.side-r.svg";
import BlockParagraphNoPhoto from "./text.paragraph.-.svg";

import BlockLinkLg from "./embed.link.lg.svg";
import BlockLinkMd from "./embed.link.md.svg";

import BlockLogoSideLeft from "./logo.side-l.svg";
import BlockLogoSideRight from "./logo.side-r.svg";
import BlockLogoMiddle from "./logo.-.svg";

import BlockButtonNarrow from "./button.narrow.svg";
import BlockButtonWide from "./button.wide.svg";

export const IconsMap: { [key: string]: any } = {
  "text.title.lg": BlockTitleLg,
  "text.title.md": BlockTitleMd,
  "text.title.sm": BlockTitleSm,
  "embed.video.lg": BlockVideoLg,
  "embed.video.side-l": BlockVideoSideLeft,
  "items.list": BlockItemsList,
  "items.2-columns": BlockItemsCol2,
  "items.3-columns": BlockItemsCol3,
  "text.paragraph.side-l": BlockParagraphSideLeft,
  "text.paragraph.side-r": BlockParagraphSideRight,
  "text.paragraph.-": BlockParagraphNoPhoto,
  "embed.link.lg": BlockLinkLg,
  "embed.link.md": BlockLinkMd,
  "logo.side-l": BlockLogoSideLeft,
  "logo.side-r": BlockLogoSideRight,
  "logo.-": BlockLogoMiddle,
  "signature.side-l": BlockLogoSideLeft,
  "signature.center": BlockLogoMiddle,
  "misc.separator.sm": BlockSeparatorSm,
  "misc.separator.md": BlockSeparatorMd,
  "misc.separator.lg": BlockSeparatorLg,
  "button.narrow": BlockButtonNarrow,
  "button.wide": BlockButtonWide,
  "button.-": BlockButtonWide
};
