<script context="module" lang="ts">
  export type FileUpload = {
    access_url: string;
    file_type: string;
    file_name: string;
    file_size_in_bytes: number;
    file_description?: string;
  };
</script>

<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import FieldLabel from "./FieldLabel.svelte";
  import type { schema } from "@editor/schema";
  import { setUploadById, uploadById } from "./uploads";

  import { field } from "./store";
  import { requiredTest } from "./validations";
  import FileTarget from "dragshow/FileTarget.svelte";
  import { upload } from "sbelt/net";
  import type { Upload } from "sbelt/net";
  import { blobToDataURL } from "sbelt/image";
  import Spinner from "@ui/Spinner.svelte";
  import { editPhotoModal } from "@editor/pintura";
  import cloneDeep from "lodash.clonedeep";
  import { createEventDispatcher } from "svelte";
  import TextField from "./TextField.svelte";
  import TextAreaField from "./TextAreaField.svelte";
  import CollapsibleSection from "@ui/CollapsibleSection.svelte";
  import Tooltip from "@ui/Tooltip.svelte";
  import { ServerError } from "@ui/http";

  export let value: FileUpload | undefined | null;
  export let uploadEndpoint: string;
  export let label: string | undefined = undefined;
  export let required: boolean = false;
  export let autofocus: boolean = false;
  export let error: string | undefined = undefined;
  export let placeholder: string = "";
  export let disabled: boolean = false;
  export let isUploading: boolean = false;
  export let accept: string | undefined = undefined;
  // an optional id for performing handoffs
  export let handoffId: string | undefined = undefined;

  const hasDescription = !!value?.file_description;

  const dispatch = createEventDispatcher();

  let ft: FileTarget;

  const { id, input, setWait } = field<FileUpload | undefined>(value || undefined, (c) => ([value, error] = c), {
    validate: [required && requiredTest()]
  });

  const errorCodeToMessage: { [code: string]: string } = {
    unsupported_file_type: "Only TXT,CSV,RTF,PDF and Microsoft Office attachments are supported.",
    user_attachments_size_limit_exceeded: "You are over the total attachment file size limit per account. Please contact support."
  };

  // update from outside
  $: $input = value || undefined;

  if (autofocus) {
    onMount(() => !$input && ft.select());
  }

  async function doUpload(file: Blob, replaceOriginal: boolean = false) {
    if (!file) {
      return;
    }

    // if we already have an upload, deal with it
    const body = new FormData();
    body.append("file", file);

    activeUpload = upload(uploadEndpoint, body);
    handoffId && setUploadById(handoffId, { upload: activeUpload });
    processUpload(activeUpload, replaceOriginal);
  }

  let isDestroyed = false;
  onDestroy(() => (isDestroyed = true));
  async function processUpload(upload: Upload<FileUpload>, replaceOriginal = false) {
    activeUpload = upload;
    isUploading = true;
    error = undefined;

    setWait("Uploading file");
    dispatch("upload_start");

    let result: FileUpload;
    try {
      result = await activeUpload.done();
    } catch (e) {
      const err: ServerError = e as ServerError;
      activeUpload = undefined;
      setWait(false);
      if (err.code === "login_needed") {
        dispatch("logged_out");
      } else {
        switch (err.code) {
          case "file_size_exceeded":
            error = "Attachment has to be smaller than " + err.extraData?.file_size_limit + ".";
            break;
          default:
            error = errorCodeToMessage[err.code] || ServerError.GENERIC_ERROR;
        }
        dispatch("upload_end", { error });
      }
      isUploading = false;
      // clear it
      handoffId && setUploadById(handoffId, undefined);
      return;
    }

    activeUpload = undefined;
    if (!isDestroyed) {
      // if we use clear, we don't keep the original input
      $input = {
        ...$input,
        ...result
      };

      dispatch("upload_done", $input);
    } else {
      dispatch("upload_end");
    }
    setWait(false);
    isUploading = false;
    // clear it
    handoffId && setUploadById(handoffId, undefined);
  }

  async function files(e: any) {
    const file = e.detail[0];
    doUpload(file, /*clear*/ true);
  }

  // todo - get it from the global upload store
  let activeUpload: Upload<FileUpload> | undefined = undefined;

  const handoff = uploadById<FileUpload>(handoffId);
  if (handoff) {
    processUpload(handoff.upload);
  }
</script>

<FieldLabel {label} {id} {required} {error} />
<FileTarget
  bind:this={ft}
  on:files={files}
  let:isOver
  let:activeDrag
  let:isRelevantDrag
  {accept}
  multiple={false}
  canClick={!value && !activeUpload}>
  <div class="relative rounded group">
    {#if error && !label}
      <slot name="error" {error}>
        <div class="w-full py-1 text-sm text-center text-white bg-red-600 bg-opacity-75">
          {error}
        </div>
      </slot>
    {/if}
    {#if value}
      <slot name="edit" {isOver} {isRelevantDrag} {activeDrag} {activeUpload}>
        <div class="flex">
          <div class="relative self-start -ml-1">
            <div class="text-5xl material-icons-round text-nxgray-300">insert_drive_file</div>
            <div class="absolute inset-0 flex items-center justify-center font-bold tracking-wider text-white uppercase text-10">
              {value.file_type}
            </div>
          </div>

          <div class="flex-grow ml-2">
            <TextField label="File Name" bind:value={value.file_name} required limit={80} />
            <CollapsibleSection title="Add a file description" captionClasses="mt-2" icon="info" startOpen={hasDescription} autofocus>
              <div class="mt-3">
                <TextAreaField label="Description" bind:value={value.file_description} limit={150} />
              </div>
            </CollapsibleSection>
            <div />
          </div>
        </div></slot>
    {:else}
      <slot name="empty" {isOver} {isRelevantDrag} {activeDrag}>
        <div
          class="flex flex-col items-center justify-center py-6 transition-all bg-gray-300 rounded hover:bg-green-300 hover:text-green-700"
          class:bg-green-600={isOver}
          class:text-white={isOver}
          class:text-nxgray-400={!isOver}>
          <div class="text-4xl transition-all material-icons">upload_file</div>
          {#if isRelevantDrag}
            <p class="font-medium transition-all text-14">Drop here</p>
            <p class="transition-all text-13">(to start your upload)</p>
          {:else}
            <p class="font-medium transition-all text-14">Select a file</p>
            <p class="transition-all text-13">We support all Microsoft office suite files and PDFs.</p>
          {/if}
        </div>

        {#if isRelevantDrag}
          <div
            transition:fade|local={{ duration: 150 }}
            class="absolute transition-all border-2 border-dashed rounded text-13 inset-2"
            class:border-white={isOver}
            class:border-nxgray-400={!isOver} />
        {/if}
      </slot>
    {/if}

    {#if activeUpload}
      <div
        transition:fade|local={{ duration: 150 }}
        class="absolute inset-0 transition-all backgdrop-filter backdrop-grayscale"
        style="width:{100 - $activeUpload.progress}%" />
      <div
        transition:fade|local={{ duration: 150 }}
        class="absolute inset-y-0 left-0 transition-all bg-green-700 bg-opacity-50"
        style="width:{$activeUpload.progress}%" />
      <div class="absolute inset-0 flex items-center justify-center" transition:fade|local={{ duration: 150 }}>
        <Spinner size={40} color="#fff" />
      </div>
    {/if}

    {#if value && !activeUpload}
      <button
        type="button"
        class="absolute top-0 right-0 hover:text-red-600 material-icons md-18"
        on:click|preventDefault|stopPropagation={() => {
          $input = undefined;
        }}
        >close

        <Tooltip>Clear selected file</Tooltip>
      </button>
    {/if}

    <slot name="overlay">
      {#if isRelevantDrag && value}
        <div
          class="absolute inset-0 transition-all rounded"
          class:bg-black={!isOver}
          class:bg-green-600={isOver}
          class:bg-opacity-100={isOver}
          class:bg-opacity-50={!isOver} />
        <div
          class="absolute flex items-center justify-center text-center text-white transition-all bg-opacity-50 border-2 border-dashed rounded text-13 inset-2"
          class:border-white={isOver}>
          Drop here
        </div>
      {/if}
    </slot>
  </div>
</FileTarget>
