<script lang="ts">
  import { activeDrag, fromEvent } from "./store";
  import type { DragFilter } from "./store";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let disabled = false;
  export let filter: DragFilter = () => true;

  let el: HTMLDivElement;

  function drop(e: DragEvent) {
    e.preventDefault();
    if (disabled || !isOver) {
      return;
    }
    const p = fromEvent(e);
    dispatch("drop", p);
  }

  function customDrop(e: CustomEvent) {
    const p = e.detail;
    if (disabled || !isOver) {
      return;
    }
    e.preventDefault();
    dispatch("drop", p);
  }

  $: isOver = !disabled && $activeDrag?.el && el && ($activeDrag?.el === el || el.contains($activeDrag?.el)) && filter($activeDrag);
  $: isRelevantDrag = !disabled && $activeDrag && filter($activeDrag);

</script>

<div on:drop={drop} on:dragshow:drop={customDrop} bind:this={el}>
  <slot {isOver} activeDrag={!disabled && $activeDrag} {isRelevantDrag} />
</div>

<style>
  div {
    display: contents;
  }
</style>
