import type { schema } from "@editor/schema";

type SupportedFormats = "datetime:minimal" | "datetime:short" | "datetime:long" | "date:minimal" | "date:short" | "date:long";

const FORMAT_OPTIONS: { [key in SupportedFormats]: Intl.DateTimeFormatOptions } = {
  "datetime:minimal": { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" },
  "datetime:short": { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", hour12: true },
  "datetime:long": {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "long"
  },
  "date:minimal": { year: "numeric", month: "numeric", day: "numeric" },
  "date:short": { weekday: "short", year: "numeric", month: "short", day: "numeric" },
  "date:long": { weekday: "long", year: "numeric", month: "long", day: "numeric" }
};

export const DateTimeTZUtils = {
  info() {
    const o = Intl.DateTimeFormat().resolvedOptions();
    return { locale: o.locale, timeZone: o.timeZone };
  },

  fromDate(d_start: Date, d_end?: Date): schema.DateTimeTZ {
    return {
      startTime: d_start.toISOString(),
      ...(d_end ? { endTime: d_end.toISOString() } : undefined),
      ...DateTimeTZUtils.info()
    };
  },

  format(tz_date: schema.DateTimeTZ, f: SupportedFormats = "datetime:short") {
    if (!tz_date) {
      return "";
    }

    const r: any = new Intl.DateTimeFormat(tz_date.locale, { timeZone: tz_date.timeZone, ...FORMAT_OPTIONS[f] });
    const [s, e] = [DateTimeTZUtils.start(tz_date), DateTimeTZUtils.end(tz_date)];

    return e ? (s < e ? r.formatRange(s, e) : r.formatRange(e, s)) : r.format(s);
  },

  timezoneStrings(tz_date: schema.DateTimeTZ) {
    if (!tz_date) {
      return "";
    }

    const r = new Intl.DateTimeFormat(tz_date.locale, { timeZone: tz_date.timeZone, timeZoneName: "short" });
    const parts = r.formatToParts(DateTimeTZUtils.start(tz_date));
    const n = parts.filter((p) => p.type === "timeZoneName")[0]?.value;

    return [tz_date.timeZone, n];
  },

  // return the JS date
  start(tz_date: schema.DateTimeTZ): Date {
    return new Date(tz_date.startTime);
  },

  end(tz_date: schema.DateTimeTZ): Date | undefined {
    return tz_date.endTime ? new Date(tz_date.endTime) : undefined;
  },

  isRange(tz_date: schema.DateTimeTZ): boolean {
    return !!tz_date.endTime;
  }
};
