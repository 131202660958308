import { getNewsletterStores, LinkOptions, LinkTypesWithOutDeprecated, RenderContext } from "../../newsletterStores";
import { getContext, setContext } from "svelte";
import LinkContent from "./LinkContent.svelte";
import type { schema } from "@editor/schema";
import clonedeep from "lodash.clonedeep";
import type { Photo } from "pages/editor/schema/defs/version_1";
import SVGRunningText from "./SVGRunningText.svelte";

export { LinkContent, SVGRunningText };

export function id(prefix: string = "w") {
  return `${prefix}-${Math.random().toString(36).substr(2, 9)}`;
}

export function isEditor() {
  const { ctx } = getNewsletterStores();
  return ctx ? ctx.mode === "editor" : !import.meta.env.SSR;
}

export function isShowAuthorPage(): boolean {
  return !import.meta.env.SSR && window && window.document && window.document.querySelector(".author-show") !== null;
}

const BLOCK_CTX_KEY = {};

export function blockCtx<T extends schema.Block>() {
  return (getContext(BLOCK_CTX_KEY) || {}) as T;
}

export function setBlockCtx(b: schema.Block) {
  return setContext(BLOCK_CTX_KEY, b);
}

// get the current block id from ctx
export function getBlockId() {
  return blockCtx()._id;
}

// combine defaults and non defaults to rendering values
export function withDefaults<TBlock extends schema.BaseBlock>(
  block: TBlock,
  // Add extra defaults
  defaults?: Partial<TBlock> | ((block: TBlock) => Partial<TBlock>),
  // An extra transformation to run on the data we return
  transform: (block: TBlock, defaults?: Partial<TBlock>) => TBlock = (b) => b
): [TBlock, (val: any) => boolean] {
  if (typeof defaults === "function") {
    defaults = defaults(block);
  }

  return [
    transform(clonedeep({ ...defaults, ...block._defaults, ...stripEmptyKeys(block) }), defaults),
    makeIsDefaultFunc(block, defaults || {})
  ];
}

function makeIsDefaultFunc(block: schema.BaseBlock, defaults: {}) {
  const _defaults = Object.values(defaults)
    .concat(Object.values({ ...defaults, ...block._defaults }))
    .concat(Object.values(block._defaults || {}))
    .filter((v) => v !== undefined && v !== null)
    // JSON to support handling of objects
    .map((v) => JSON.stringify(v, replacer));

  return (value: any) => {
    return value === "" || value === undefined || value === null || _defaults.includes(JSON.stringify(value, replacer));
  };
}

// filter out null values
function replacer(key: string, value: any) {
  return value === null || (Array.isArray(value) && !value.length) ? undefined : value;
}

function stripEmptyKeys(obj: any): any {
  if (!obj) {
    return obj;
  }
  const r: any = {};
  for (let key in obj) {
    if (obj[key] !== undefined && obj[key] !== null) {
      r[key] = obj[key];
    }
  }

  return r;
}

export function toArray<T>(input: Record<string, T> | T[]): T[] {
  if (Array.isArray(input)) {
    return input;
  }
  return Object.values(input);
}

export function safeHex(input: string) {
  if (!input) {
    return "transparent";
  }

  if (input.length > 7) {
    return input.slice(0, 7);
  }
  return input;
}

export function ctaToHref(
  ctx: RenderContext,
  cta: schema.CallToAction | undefined,
  type: LinkTypesWithOutDeprecated,
  text?: string,
  blockId?: string
) {
  if (!cta) return "";
  return ctx.href(coerceUri(cta.url), { id: cta.id, type, text, blockId });
}

export function photoToCTA(photo: Photo): schema.CallToAction | undefined {
  return photo.cta;

  // if (photo.cta) {
  //   return photo.cta;
  // }

  // return { id: `i-${photo.id}`, url: photo.legacy_full_url! };
}

const MAILTO_EMAIL_PATTERN =
  /^mailto:(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const TEL_URL_REGEXP: RegExp = /^(tel:)?(\+[0-9]{1,3})?([-])?([0-9\-]{7,})$/i;

const EMAIL_ADDRESS_PATTERN =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const STARTS_WITH_HTTP_S = /^https?:\/\//i;
export function coerceUri(uri: string | undefined) {
  if (!uri) {
    return "#";
  }

  uri = uri.trim();

  if (STARTS_WITH_HTTP_S.test(uri)) return uri;
  if (MAILTO_EMAIL_PATTERN.test(uri)) return uri;
  if (EMAIL_ADDRESS_PATTERN.test(uri)) return "mailto:" + uri;

  if (TEL_URL_REGEXP.test(uri)) {
    if (!uri.toLowerCase().startsWith("tel:")) {
      uri = "tel:" + uri;
    }
    return uri;
  }

  return "http://" + uri;
}

export type HrefAttributes = {
  "href": string;
  "o-href"?: string;
  "track-href"?: string;
};

export function trackingAndAttributesFromCTA(
  ctx: RenderContext,
  cta: schema.CallToAction | undefined,
  o: LinkOptions
): [string | undefined, HrefAttributes | {}] {
  if (!cta) {
    return [undefined, {}];
  }

  const { type, text, blockId } = o;

  let trackUrl = ctaToHref(ctx, cta, type, text, blockId);
  const rel = "nofollow noopener noreferrer";
  switch (ctx.mode) {
    case "render-email":
      if (!ctx.dialect) {
        // In email, we have to append the "Newsletter Key" (which encodes the newsletter_id and the recipient email address) to the URL.
        trackUrl = `${trackUrl}&nk=*|NEWSLETTER_CLICK_KEY|*`;
      } else {
        // If we have a dialect, it means we send it via MNS.
        // In this case, we don't have the recipient variables, and therefore should not
        // append the NEWSLETTER_CLICK_KEY. In the future, we specific dialects we can generate something as follows:
        // trackUrl = `${trackUrl}&email={generateEmailVariable(ctx.dialect)}`;
        // Do nothing for now...
      }
      return [
        trackUrl,
        {
          href: trackUrl,
          rel
        }
      ];
    case "render-web":
      return [
        trackUrl,
        {
          "href": coerceUri(cta?.url),
          "o-href": coerceUri(cta?.url),
          "track-href": trackUrl,
          rel
        }
      ];
    case "editor":
    case "template":
    default:
      return [
        trackUrl,
        {
          href: coerceUri(cta?.url),
          rel
        }
      ];
  }
}

export function fancyPicClassIfNoCTA(p: schema.Photo) {
  if (!p.cta || !p.cta?.url) {
    return "fancy-pic";
  }
  return "";
}
