import debounce from "lodash.debounce";
import type { schema } from "@editor/schema";

// Auto save definition
export type AutoSaveHelper<T extends any> = {
  // load a draft based on the input
  load(input: T): any;
  // save draft
  save: (draft: any) => void;
  // clear draft
  clear(input: T): void;

  wait: number;
};

export const noopAutosave: AutoSaveHelper<any> = {
  load() {},
  save: () => {},
  clear() {},
  wait: 100
};

export const blockAutosave: AutoSaveHelper<schema.Block> = {
  load(input) {
    const ls = localStorage.getItem(`as-blk-${input._id}`);
    if (ls) {
      return JSON.parse(ls);
    }
  },
  save(draft) {
    localStorage.setItem(`as-blk-${draft._id}`, JSON.stringify(draft));
  },
  clear(input) {
    localStorage.removeItem(`as-blk-${input._id}`);
  },
  wait: 500
};
