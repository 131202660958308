<script lang="ts" context="module">
  import YoutubeSVG from "./Youtube.svg";
  import type { schema } from "@editor/schema";
  import debounce from "lodash.debounce";
  import { makeCancelable } from "sbelt/promise";
  import { embedBlockFromUrl } from "./embed";
  import { field } from "./store";
  import UrlField from "./UrlField.svelte";
  import { requiredTest } from "./validations";
  import Spinner from "@ui/Spinner.svelte";
  import { getCurrentUser } from "@ui/currentUser";
  import { reportUserEvent, USER_EVENTS } from "@ui/reportEvents";
  import { getNewsletterStores } from "../../newsletterStores";
  import { getEditorStore } from "../../editorStore";

  const RESULT_PER_PAGE = 20;

  type Thumbnail = {
    url: string;
    width: number;
    height: number;
  };
  type Snippet = {
    channelId: string;
    title: string;
    description: string;
    thumbnails: {
      default: Thumbnail;
      medium: Thumbnail;
      high: Thumbnail;
    };
    channelTitle: string;
  };
  type YouTubeSearchResultItem = {
    id: {
      kind: string;
      videoId: string;
    };
    snippet: Snippet;
  };
  type YoutubeSearchResult = {
    error?: {
      code: number;
    };
    items?: YouTubeSearchResultItem[];
    nextPageToken?: string;
    pageInfo?: {
      totalResults: number;
      resultsPerPage: number;
    };
  };
</script>

<script lang="ts">
  export let value: schema.Block | undefined;
  export let label: string | undefined;
  export let icon: string = "link";
  export let required: boolean = false;
  export let error: string | undefined = undefined;
  export let placeholder: string = "Enter a url";
  export let previewPlaceholder: string = "Paste a link to a website or a blog to embed it.";
  export let autofocus: boolean = false;
  export let urlOnly: boolean = true;
  export let type: "video" | "link" | "form" = "link";

  let el: UrlField;
  let cancelRequest: () => void = () => {};
  let errorMessage: string | undefined;
  let youtubeSearchResults: YouTubeSearchResultItem[] | undefined = undefined;
  let isSearchingYoutube: boolean = false;
  let nextYoutubeSearchPageToken: string | undefined = undefined;
  let isFetchingMore: boolean = false;
  let gotYoutubeOutOfQuota: boolean = false;
  // I needed to use those variables in order to detect whenever the input or the list of youtube videos are focused
  // in order to keep the "dropdown" open
  // Upon focus loss, we hide it
  let isFocused: boolean = false;
  let isListFocused: boolean = false;

  const { input, setWait, isWaiting } = field<schema.Block | undefined>(value || undefined, (c) => ([value, error] = c), {
    validate: [required && requiredTest()]
  });

  const currentUser = getCurrentUser();
  const { id: newsletterId } = getNewsletterStores();
  const editorStore = getEditorStore();

  $: isYoutubeSearchSupported = type === "video" && $currentUser.caps.youtube_search;
  $: isYoutubeSearchApplicable = !url.trim().startsWith("http:") && !url.trim().startsWith("https:") && url.trim().length > 0;

  const fetchBlock = debounce(
    async (fu) => {
      // @ts-ignore
      if (fu && value && (value?.url || value?.iframe_url) === fu) {
        return;
      }
      if ((!urlOnly && isYoutubeSearchApplicable) || fu.trim().length === 0) {
        return;
      }

      // cancel previous requests
      cancelRequest();
      setWait("fetching");
      errorMessage = undefined;
      const [req, cancel] = makeCancelable(embedBlockFromUrl(fu));
      cancelRequest = cancel;

      try {
        const result = await req;
        // only update the result if our url hasn't changed

        if (fu === url) {
          if (result) {
            $input = result;
          }
          // stop the wait
          setWait(false);
        }
      } catch (err) {
        if (fu === url) {
          if (error !== "canceled") {
            setWait(false);
            // handle errors here
            errorMessage = `Couldn't load preview information for '${fu}'`;
          }
        }
      }
    },
    500,
    {
      leading: true,
      trailing: true
    }
  );

  function valueChanged() {
    if (!gotYoutubeOutOfQuota) {
      isSearchingYoutube = true;
    }

    doValueChanged();
  }

  const doValueChanged = debounce(async () => {
    if (!isYoutubeSearchSupported || !isYoutubeSearchApplicable || url.trim().length === 0) {
      youtubeSearchResults = undefined;
      isSearchingYoutube = false;
      return;
    }
    youtubeSearchResults = [];
    return searchYouTube();
  }, 500);

  async function searchYouTube() {
    if (gotYoutubeOutOfQuota) {
      isSearchingYoutube = false;
      isFetchingMore = false;
      return;
    }
    const YoutubeSearchUrl = `https://www.googleapis.com/youtube/v3/search?key=${$editorStore.youtubeApiKey}&type=video&part=snippet&safeSearch=moderate&q=${url}&maxResults=${RESULT_PER_PAGE}`;

    const youtubeSearchUrl =
      nextYoutubeSearchPageToken !== undefined ? `${YoutubeSearchUrl}&pageToken=${nextYoutubeSearchPageToken}` : YoutubeSearchUrl;

    try {
      isSearchingYoutube = true;
      nextYoutubeSearchPageToken = undefined;
      const response = await fetch(youtubeSearchUrl);

      const result = (await response.json()) as YoutubeSearchResult;

      if (result.error) {
        // In case we're over the quota...
        if (result.error.code === 403) {
          gotYoutubeOutOfQuota = true;
        }
        youtubeSearchResults = undefined;
      } else {
        youtubeSearchResults = [...(youtubeSearchResults || []), ...result.items!];
        if (result.pageInfo && result.pageInfo.totalResults > youtubeSearchResults.length) {
          nextYoutubeSearchPageToken = result.nextPageToken;
        } else {
          nextYoutubeSearchPageToken = undefined;
        }
      }
    } catch (ex) {
      youtubeSearchResults = undefined;
      nextYoutubeSearchPageToken = undefined;
    } finally {
      isSearchingYoutube = false;
      isFetchingMore = false;
    }
  }

  async function fetchMoreYoutubeResults() {
    isFetchingMore = true;
    await Promise.all([reportUserEvent(USER_EVENTS.NewsletterEditorYoutubeSearchFetchMore, { newsletterId }), searchYouTube()]);
    isListFocused = true;
  }

  async function selectYouTubeVideo(video: YouTubeSearchResultItem) {
    if (isYoutubeSearchSupported) {
      reportUserEvent(USER_EVENTS.NewsletterEditorYoutubeSearchSelectVideo, { newsletterId, videoId: video.id.videoId });
      errorMessage = undefined;
      youtubeSearchResults = undefined;
      url = `http://www.youtube.com/watch?v=${video.id.videoId}`;
      $input = {
        target: "youtube",
        title: video.snippet.title,
        movie: video.id.videoId,
        url,
        thumbnail_url: video.snippet.thumbnails.medium.url
      } as schema.VideoBlock;
      el.focus();
    }
  }

  // @ts-ignore
  let url: string = value?.url || value?.iframe_url || "";

  $: if (!error || error === "Required") {
    if (!url) {
      $input = undefined;
    } else {
      // fetch the block from this
      fetchBlock(url);
    }
  }

  function openYouTubeVideoInNewWindow(video: YouTubeSearchResultItem) {
    // open the video
    window.open(`http://www.youtube.com/watch?v=${video.id.videoId}`, "_blank");

    // keep the list open
    isFocused = true;
  }
</script>

<div class="group">
  <UrlField
    bind:isFocused
    bind:value={url}
    {label}
    {placeholder}
    {required}
    bind:error
    {icon}
    {urlOnly}
    {autofocus}
    bind:this={el}
    on:input={valueChanged} />

  <div
    class="relative"
    style="z-index:999999;"
    on:mouseenter={() => (isListFocused = true)}
    on:mouseleave={() => (isListFocused = false)}
    on:focus={() => (isListFocused = true)}
    on:focusout={() => (isListFocused = false)}
    on:focusin={() => (isListFocused = true)}>
    {#if isYoutubeSearchSupported && isYoutubeSearchApplicable}
      <div
        class="absolute flex-col w-full px-4 py-2 overflow-auto bg-white border border-t-0 rounded rounded-t-none shadow top-full gap-y-1 max-h-72"
        class:flex={isListFocused || isFocused || isFetchingMore}
        class:hidden={!isListFocused && !isFocused && !isFetchingMore}>
        {#if youtubeSearchResults && youtubeSearchResults.length}
          {#each youtubeSearchResults as youtubeSearchResult}
            <button
              class="flex px-2 py-1 -mx-2 rounded outline-none hover:bg-teal-100 clickable video-wrapper"
              on:click={() => selectYouTubeVideo(youtubeSearchResult)}>
              <div class="relative">
                <div
                  class="w-24 h-16 bg-center bg-no-repeat bg-cover border rounded aspect-1"
                  style="background-image:url({youtubeSearchResult.snippet.thumbnails.default.url})" />
                <div
                  class="absolute w-8 h-4 bg-center bg-no-repeat bg-contain top-1/3 left-1/3 aspect-1"
                  style="background-image:url({YoutubeSVG})" />
                <!-- Link to open the video in a new tab without selecting it -->
                <a
                  on:click|stopPropagation|preventDefault={() => openYouTubeVideoInNewWindow(youtubeSearchResult)}
                  class="absolute bottom-0 right-0 hidden p-px text-white rounded bg-nxgray-500 open-in-new-window"
                  href={`http://www.youtube.com/watch?v=${youtubeSearchResult.id.videoId}`}
                  target="_blank">
                  <span class="material-icons text-16"> open_in_new </span>
                </a>
              </div>
              <!-- <YoutubeSVG class="absolute top-0 w-4 aspect-1" /> -->
              <div class="flex flex-col items-start justify-start ml-4 text-left">
                <span class="text-base text-nxgray-600 text-ellipsis">{@html youtubeSearchResult.snippet.title}</span>
                <span class="w-full mt-1 text-xs text-nxgray-500 text-ellipsis">{@html youtubeSearchResult.snippet.description}</span>
              </div>
            </button>
          {/each}
          {#if nextYoutubeSearchPageToken}
            <button
              class="py-1 text-xs text-center bg-gray-100 focus:outline-none clickable hover:bg-teal-100"
              class:pointer-events-none={isFetchingMore}
              on:click|preventDefault|stopPropagation={fetchMoreYoutubeResults}>
              {#if isFetchingMore}
                Loading more...
              {:else}
                More...
              {/if}
            </button>
          {/if}
        {:else if !isSearchingYoutube}
          {#if gotYoutubeOutOfQuota}
            <div class="py-1 text-xs font-thin text-center text-red-500">
              Youtube search is not available at the moment<br /> You can enter a valid url instead
            </div>
          {:else}
            <div class="py-1 text-xs font-normal text-center text-nxgray-500">No videos found</div>
          {/if}
        {:else}
          <div class="py-1 text-xs font-normal text-center text-nxgray-400 animate-pulse">Searching youtube...</div>
        {/if}
      </div>
    {/if}
  </div>
</div>

<div class="relative mt-3 -mx-2 -mb-2">
  {#if $isWaiting}
    <slot name="loading" isWaiting={$isWaiting}>
      <div class="flex items-center p-2 text-center border-t text-13 text-nxgray-500">
        <Spinner size={14} />
        <span class="ml-2">Getting preview information...</span>
      </div>
    </slot>
  {/if}
  {#if errorMessage}
    <slot name="loading" isWaiting={$isWaiting}>
      <div class="relative flex items-center p-2 text-center text-red-600 bg-red-200 border-t text-13">
        <i class="material-icons md-18">warning</i>
        <span class="ml-2">{errorMessage}</span>

        <button
          type="button"
          class="absolute flex items-center justify-center p-1 right-1 material-icons md-18"
          on:click={() => {
            errorMessage = undefined;
            el && el.focus();
          }}>close</button>
      </div>
    </slot>
  {/if}
  <slot name="preview" block={$input} isWaiting={$isWaiting}>
    {#if !$input}
      {#if !$isWaiting && !errorMessage}
        <slot name="empty">
          <div class="p-2 text-center border-t text-13 text-nxgray-500">{@html previewPlaceholder}</div>
        </slot>
      {/if}
    {:else}
      {#if $input._t === "embed.iframe"}
        <iframe class="mx-auto rounded pointer-events-none" tabindex="-1" src={$input.iframe_url} title={""} width="75%" height="200" />
      {:else}
        <div class="relative flex items-center justify-start p-2 border-t border-gray-200 bg-green-50">
          <button
            type="button"
            class="absolute flex items-center justify-center p-1 top-1 right-1 material-icons md-18"
            on:click={() => {
              url = "";
              el && el.focus();
            }}>close</button>

          <div
            style="width:100px;height:68px;"
            class="flex items-center justify-center flex-shrink-0 mr-4 overflow-hidden bg-white bg-center bg-no-repeat bg-contain border rounded image-container">
            {#if $input.thumbnail_url}
              <img src={$input.thumbnail_url} alt={$input.title} />
            {/if}
          </div>
          <div class="flex flex-col items-start justify-start">
            <h1 class="font-medium leading-tight text-14">{@html $input.title}</h1>
            {#if $input._t === "embed.link"}
              <p class="h-10 mt-1 overflow-hidden text-13">{@html $input.description || ""}</p>
            {/if}
          </div>
        </div>
      {/if}

      <!-- <div class="p-2 mt-4 -m-2 text-center text-red-500 border-t border-gray-200 bg-red-50 text-13">{errorMessage}</div> -->
    {/if}
  </slot>
</div>

<style>
  .video-wrapper:hover .open-in-new-window {
    display: flex;
  }
</style>
