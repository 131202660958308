<script lang="ts">
  import { onMount } from "svelte";
  import TextField from "./TextField.svelte";

  export let label: string;
  export let icon: string | undefined = undefined;
  export let preText: string | undefined = "";
  export let value: string | undefined;
  export let pattern: string;
  export let placeholder: string = "";
  export let limit: number | undefined = undefined;

  let textElement: TextField;
  function fixPasteText(e: ClipboardEvent) {
    let input: string = ((e.clipboardData || (window as unknown as any).clipboardData).getData("text") || "").trim();
    const lowerInput = input.toLowerCase();

    if (lowerInput.startsWith("http:") || lowerInput.startsWith("https:")) {
      const indexOfDotCom = lowerInput.indexOf(".com");
      if (indexOfDotCom > 0) {
        e.stopPropagation();
        e.preventDefault();
        value = input.substring(indexOfDotCom + 5);
      }
    }
  }

  onMount(() => {
    if (textElement) {
      textElement.validate();
    }
  });
</script>

<div class="social-profile-field">
  <TextField bind:this={textElement} {label} bind:value {pattern} {icon} {placeholder} on:paste={fixPasteText}>
    <div slot="beforeInput" class="flex items-center flex-shrink-0 text-gray-600 select-none text-11">{preText}</div>
  </TextField>
</div>

<style>
  /* Ugly hack so the field scrollbar doesn't show */
  :global(.social-profile-field input) {
    width: 100%;
  }
</style>
