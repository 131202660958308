import { postJson } from "./http";

export const USER_EVENTS = {
  NewsletterSettingsModalOpen: "NEWSLETTER SETTINGS: open the settings modal",
  NewsletterEditorEditPhotoModalOpen: "NEWSLETTER EDITOR: open the edit photo modal",
  NewsletterEditorYoutubeSearchFetchMore: "NEWSLETTER EDITOR: Clicked on Youtube fetch more",
  NewsletterEditorYoutubeSearchSelectVideo: "NEWSLETTER EDITOR: Select Video from Youtube search",
  PrintOpenPopover: "PRINT: Open popover",
  PrintClickedPrint: "PRINT: Clicked on print",
  PrintCancelGeneration: "PRINT: Cancel generation",
  PrintErrorOnGeneration: "PRINT: Error on generation",
  PrintClickedDownloadedJpg: "PRINT: Clicked on download jpg",
  PrintDownloadedJpg: "PRINT: Downloaded jpg",
  PrintSuccess: "PRINT: print success",
  EmbedNewsletterOpenPopover: "EMBED NEWSLETTER: Open popover",
  EmbedNewsletterCopyLink: "EMBED NEWSLETTER: Copy Link",
  EmbedNewsletterCopyEmbedCode: "EMBED NEWSLETTER: Copy Embed Code",
  ShareButtonPopupOpen: "SHARE POPUP: Open popover",
  ShareButtonPopupCopyLink: "SHARE POPUP: Copy Link",
  PreviewAndShareOpenPrintPopover: "PREVIEW & SHARE: Open Print popover",
  PreviewAndSharePanelCopyLink: "PREVIEW & SHARE: Copy Link",
  PreviewAndSharePrintClicked: "PREVIEW & SHARE: Clicked on Print",
  PreviewAndShareShareWithEmailClicked: "PREVIEW & SHARE: Clicked on Share with Email",
  PreviewAndShareExportMNSClicked: "PREVIEW & SHARE: Clicked on Export MNS",
  PreviewAndShareExportToHiveClicked: "PREVIEW & SHARE: Clicked on Export to Hive",
  PreviewAndShareShareWithFacebookClicked: "PREVIEW & SHARE: Clicked on Share with Facebook",
  PreviewAndShareShareWithTwitterClicked: "PREVIEW & SHARE: Clicked on Share with Twitter",
  PreviewAndShareShareWithLinkedinClicked: "PREVIEW & SHARE: Clicked on Share with Linkedin",
  PreviewAndShareShareWithPinterestClicked: "PREVIEW & SHARE: Clicked on Share with Pinterest",
  AnalyticsPopupOpen: "ANALYTICS POPUP: Open",
  AnalyticsPopupClosed: "ANALYTICS POPUP: Closed",
  AnalyticsPopupNavigateOverview: "ANALYTICS POPUP: Navigate Overview",
  AnalyticsPopupNavigateEmailDeliveries: "ANALYTICS POPUP: Navigate Email deliveries",
  AnalyticsPopupNavigateLocations: "ANALYTICS POPUP: Navigate Locations",
  AnalyticsPopupNavigatePolls: "ANALYTICS POPUP: Navigate Polls",
  AnalyticsPopupNavigateLanguages: "ANALYTICS POPUP: Navigate Languages",
  AnalyticsPopupNavigateInteractions: "ANALYTICS POPUP: Navigate Interactions",
  AnalyticsPopupNavigateTrafficSources: "ANALYTICS POPUP: Navigate Traffic sources",
  AnalyticsPopupNavigateRSVPs: "ANALYTICS POPUP: Navigate RSVPs",
  AnalyticsPopupNavigateContactRequests: "ANALYTICS POPUP: Navigate Contact requests",
  AnalyticsPopupNavigateSettings: "ANALYTICS POPUP: Navigate Settings",
  AnalyticsPopupNavigateDownloadReport: "ANALYTICS POPUP: Navigate Download report",
  AnalyticsPopupOverviewLinksInteractionsClicked: "ANALYTICS POPUP: OVERVIEW Clicked on Links interactions",
  AnalyticsPopupOverviewButtonsInteractionsClicked: "ANALYTICS POPUP: OVERVIEW Clicked on Buttons interactions",
  AnalyticsPopupOverviewVideoInteractionsClicked: "ANALYTICS POPUP: OVERVIEW Clicked on Videos interactions",
  AnalyticsPopupOverviewSourcesClicked: "ANALYTICS POPUP: OVERVIEW Clicked on Sources",
  AnalyticsPopupLocationsMapClicked: "ANALYTICS POPUP: LOCATIONS Clicked on Map view",
  AnalyticsPopupLocationsListClicked: "ANALYTICS POPUP: LOCATIONS Clicked on List view",
  AnalyticsPopupEmailDeliveriesRefreshClicked: "ANALYTICS POPUP: EMAIL DELIVERIES Clicked on Refresh",
  LearnPopupVideoClicked: "LEARN POPUP: Video clicked",
  ConnectShareAnnouncement: "CONNECT: Share Announcement"
};

export async function reportUserEvent(event: string, data: any = undefined) {
  return postJson("/app/users/log_client_event_json", {
    event,
    data
  }).catch(() => {
    // In case of an error, do nothing
  });
}

export async function reportTranslationEvent(newsletterId: string, lang: string, source: "email" | "browser" = "browser") {
  return postJson(`/app/users/log_client_translation_event_json/${newsletterId}`, {
    lang,
    source
  }).catch(() => {
    // Ignore the error and continue
  });
}
