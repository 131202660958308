<script lang="ts">
  import { fly } from "svelte/transition";

  export let label: string = "";
  export let id: string = "";
  export let required: boolean = false;
  export let error: string | undefined = undefined;
</script>

<label for={id} class="block" on:mousedown>
  {#if label}
    <div class="relative flex items-center justify-between mb-1">
      <div class="absolute inset-0 flex items-center pointer-events-none">
        <slot name="background" />
      </div>
      <span class="relative block align-middle font-mediumn text-12 text-nxgray-600">
        {label}
        <slot name="after-label" />
        {#if !required}
          <span class="font-normal text-gray-500 text-12">(optional)</span>
        {:else}
          <span class="text-sm text-red-600">*</span>
          <slot name="after-required" />
        {/if}
      </span>

      <div class="relative flex items-center">
        {#if error}
          <span class="text-xs text-red-600" transition:fly|local={{ duration: 150, y: 10 }}>{error}</span>
        {/if}
        <slot name="right" />
      </div>
    </div>
  {/if}
  <slot />
</label>
