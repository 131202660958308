<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./Block.svelte";
  import { isEditor } from "./utils";
  import LinkContent from "./utils/LinkContent.svelte";

  export let block: schema.EmbedIframeBlock;

  // Sine the google form url will be opened in new tab, or at least not in IFRAME,
  // We should remove the `embedded` flag
  const cta = block.iframe_url ? { url: block.iframe_url.replace(/embedded=true/gi, ""), id: `iframe-${block._id}` } : undefined;
</script>

<Block {block} on:click on:delete on:duplicate let:isEmpty showEmpty layouts={["-"]} size="full">
  {#if isEmpty}
    <div class="w-full text-center">
      empty form
      {#if block._defaults && block._defaults.iframe_url}
        <iframe class="rounded" src={block._defaults.iframe_url} title={""} width="100%" height="400" />
      {/if}
    </div>
  {:else}
    <div class="relative flex flex-col w-full text-center no-screenshot group">
      <iframe class="rounded" class:pointer-events-none={isEditor()} src={block.iframe_url} title={""} width="100%" height="400" />
      {#if !isEditor()}
        <LinkContent
          {cta}
          type="form-emb"
          linkText={block.iframe_url}
          class="absolute flex items-center p-1 text-2xl leading-none text-white rounded shadow-md text-18 bottom-1 right-10 bg-nxgray-500 bg-opacity-80 material-icons notranslate opacity-40 group-hover:opacity-100">
          open_in_new
        </LinkContent>
      {/if}
    </div>

    {#if !isEditor()}
      <!-- Screenshot only version -->
      <div class="w-full text-center only-screenshot">
        <iframe class="rounded" src={block.iframe_url} title={""} width="100%" height="400" scrolling="no" />
      </div>
    {/if}
  {/if}
</Block>
