<script lang="ts" context="module">
  const DATE_RANGE_CALENDAR = `<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9023 17.3477V7.25H2.09766V17.3477H14.9023ZM14.9023 2.65234C15.3893 2.65234 15.819 2.83854 16.1914 3.21094C16.5638 3.58333 16.75 4.01302 16.75 4.5V17.3477C16.75 17.8346 16.5638 18.2643 16.1914 18.6367C15.819 18.9805 15.3893 19.1523 14.9023 19.1523H2.09766C1.58203 19.1523 1.13802 18.9805 0.765625 18.6367C0.421875 18.293 0.25 17.8633 0.25 17.3477V4.5C0.25 4.01302 0.421875 3.58333 0.765625 3.21094C1.13802 2.83854 1.58203 2.65234 2.09766 2.65234H3V0.847656H4.84766V2.65234H12.1523V0.847656H14V2.65234H14.9023ZM13.0977 9.09766V10.9023H11.25V9.09766H13.0977ZM9.40234 9.09766V10.9023H7.59766V9.09766H9.40234ZM5.75 9.09766V10.9023H3.90234V9.09766H5.75Z" fill="#A7A7AF"/>
</svg>
`;
</script>

<script lang="ts">
  import "@ui/dateMixins";
  import type { schema } from "@editor/schema";
  import Block from "./EmailBlock.svelte";
  import { getNewsletterStores } from "../../newsletterStores";
  import { LinkContent } from "../utils";
  import { isRTL } from "sbelt/text";
  import { evaluate } from "../poll-block-editor/durationUtils";

  export let block: schema.PollBlock;
  export let canonicalUrl: string;

  const { design, ctx } = getNewsletterStores();

  $: color = $design!.background.dark ? "#fff" : $design?.color["global.text"];
  $: borderColor = $design!.background.dark ? "#fff" : "#CBCBCF";
  const notDeletedAnswers = block.answers.filter((ans) => !ans.deleted);
  const rtl = isRTL(block.question) || notDeletedAnswers.find((answer) => isRTL(answer.text)) !== undefined;
  const { ended, endDate } = evaluate(block.dateRange);
</script>

<Block {block} let:layout>
  <table width="100%" cellpadding="0" cellspacing="0">
    <tr>
      <td width="100%" {color} style="border-radius:4px;overflow:hidden" valign="top" align={rtl ? "right" : "left"}>
        <span style="font:bold 18px/1.2 sans-serif;color:{color};text-decoration:none;line-height:1;text-align:{rtl ? 'right' : 'left'}"
          >{block.question}</span>
      </td>
    </tr>
    <tr>
      <td height="18" width="100%" style="height:18 !important;line-height:50% !important;;" />
    </tr>
    {#each notDeletedAnswers as answer (answer.id)}
      <tr>
        <td width="100%" {color} style="border:1px solid {borderColor} !important;width:100%;">
          <table width="100%" cellpadding="0" cellspacing="0">
            <tr>
              <td width="8" style="height:8px !important;line-height:50% !important;">&nbsp;</td>
              <td style="height:8px !important;line-height:50% !important;">&nbsp;</td>
              <td width="8" style="height:8px !important;line-height:50% !important;">&nbsp;</td>
            </tr>
            <tr>
              <td width="8">&nbsp;</td>
              <td valign="middle" align={rtl ? "right" : "left"}>
                <LinkContent
                  cta={{ id: `answer-${answer.id}`, url: `${canonicalUrl}?a=${answer.id}#block-${block._id}` }}
                  type="poll"
                  blockId={block._id}
                  linkText={answer.text}
                  style="text-decoration:none;">
                  <span
                    style="width:100%;text-align:{rtl
                      ? 'right'
                      : 'left'};display:block;font:normal 13px sans-serif;color:{color};text-decoration:none;line-height:50%"
                    >{answer.text}</span>
                </LinkContent>
              </td>
              <td width="8">&nbsp;</td>
            </tr>
            <tr>
              <td width="8" style="height:8px !important;line-height:50% !important;">&nbsp;</td>
              <td style="height:8px !important;line-height:50% !important;">&nbsp;</td>
              <td width="8" style="height:8px !important;line-height:50% !important;">&nbsp;</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td height="11" width="100%" style="height:11px !important;line-height:50% !important;">&nbsp;</td>
      </tr>
    {/each}
    {#if ended}
      <tr>
        <td>
          <table width="100%" cellpadding="0" cellspacing="0" align={rtl ? "right" : "left"}>
            <tr>
              <td width="18" style="text-align:left;width:22px;padding-bottom:1px;" valign="middle"
                ><img width="17" height="19" valign="middle" src={ctx.svg(DATE_RANGE_CALENDAR, { shared: true })} alt={""} /></td>
              <td width="3px" />
              <td style="color:#505060;font-size:13px;text-align:left" valign="middle"
                >Voting ended on {endDate.toFormat("{MMMM} {Do}, {YYYY}")}</td>
            </tr>
          </table>
        </td>
      </tr>
    {/if}
  </table>
</Block>
