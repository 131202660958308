<script lang="ts">
  import type { schema } from "@editor/schema";
  import { getNewsletterStores } from "../../../newsletterStores";
  import { isEmpty as isEmptyTest } from "../../blocksConfig";
  import { getBlockId, isEditor, trackingAndAttributesFromCTA } from "../../utils";
  import { Base64PlaySVGImageData } from "./utils";

  export let block: schema.VideoBlock;

  $: isEmpty = isEmptyTest(block);

  function getTimestampParameter() {
    let hasTimestamp = block.url?.match(/t=[0-9%3a0-9]+/gi);
    return hasTimestamp ? `&t=${hasTimestamp[0].split("=")[1]}` : "";
  }

  const cta = block.playerUrl
    ? {
        url: `${block.playerUrl}${getTimestampParameter()}`,
        id: `v-${block._id}`
      }
    : undefined;

  const { ctx } = getNewsletterStores();
  const blockId = getBlockId();
  const [target, hrefAttrs] = trackingAndAttributesFromCTA(ctx, cta, { type: "video", text: block.title, blockId });
</script>

<div class="content no-screenshot widget-video-soundcloud-new">
  <div class="framed-image-container no-drag" class:p-2={isEmpty}>
    <div class="relative video-box">
      {#if isEmpty}
        <div class="absolute inset-0 flex items-center justify-center text-gray-600 bg-gray-100 border-2 border-dashed rounded-lg">
          Soundcloud video
        </div>
      {:else}
        <button
          class="relative flex items-center justify-center w-full h-full bg-center bg-no-repeat bg-cover iframeMe"
          data-video-url={target}
          data-video-title={block.title}
          data-video-original-url={cta?.url}
          style="background-image: url('{block.thumbnail_url}')">
          <span
            class="absolute top-0 left-0 right-0 px-2 py-1 text-left text-white truncate text-16"
            style="background:linear-gradient(#000000A0, #00000050);">
            {block.title}
          </span>
          <img
            src={Base64PlaySVGImageData}
            width="700"
            alt="Click to play: {block.title}"
            style="display:block !important; max-width:700px;width:100%;height:240px;" />
        </button>
      {/if}
    </div>
  </div>
</div>

{#if !isEditor()}
  <div class="content only-screenshot widget-video-soundcloud-new">
    <div class="framed-image-container">
      <div class="video-box">
        <div class="video-thumbnail" style={`background: url("${block.thumbnail_url}") center / cover no-repeat`}>
          <div class="play-overlay" />
          {#if block.title}
            <div class="video-description">{block.title}</div>
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .widget-video-soundcloud-new .framed-image-container {
    position: relative;
    height: 350px;
  }

  .widget-video-soundcloud-new iframe,
  .widget-video-soundcloud-new .video-box {
    height: auto;
    display: block;
    width: 100%;
    height: 100%;
  }

  .widget-video-soundcloud-new .video-shim {
    height: 100%;
    width: 100%;
    z-index: 1000;
    background: red;
    opacity: 0;
    display: block;
    position: absolute;
  }

  .widget-video-soundcloud-new.only-screenshot .video-box {
    overflow: hidden;
    background: black;
  }
  .widget-video-soundcloud-new.only-screenshot img {
    width: 100%;
  }
  .widget-video-soundcloud-new.only-screenshot .video-thumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: contain;
  }
  .widget-video-soundcloud-new.only-screenshot .video-description {
    width: 100%;
    height: 33px;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    font-family: Verdana, arial, sans-serif;
    font-size: 16px;
    padding: 10px 0 0 12px;
  }

  .widget-video-soundcloud-new.only-screenshot .play-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(/images/emails/youtube_overlay.png) no-repeat center center;
    top: 0;
    left: 0;
  }
</style>
