<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let startOpen: boolean = false;
  export let title: string = "Click to add more";
  export let icon: string = "add";
  export let captionClasses: string = "";
  export let autoScroll: boolean = false;
  export let autofocus: boolean | string = false;
  export let inline: boolean = false;
  export let width: "full" | "normal" = "normal";

  const OPEN_DURATION = 100;

  const dispatcher = createEventDispatcher();

  let isOpen = startOpen;

  function open() {
    isOpen = true;
    dispatcher("open");
    // Autocroll

    setTimeout(() => {
      autoScroll && content && content.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
      // yay focus
      autofocus &&
        (content && (content.querySelector(`input,select,[id=${autofocus}],textarea,.fj-content`) as HTMLElement | null))?.focus();
    }, OPEN_DURATION);
  }

  let content: HTMLDivElement | undefined;
</script>

{#if !isOpen}
  <slot name="caption" {open}>
    <button
      type="button"
      on:click={open}
      class:flex={!inline}
      class:inline-flex={inline}
      class:w-full={width === "full"}
      class="px-1 pr-1.5 py-0.5 items-center clickable hover:bg-teal-100 text-teal-600 border border-teal-600 rounded focus:bg-teal-600 focus:text-white focus:outline-none focus:ring-2 focus:ring-teal-200 {captionClasses}">
      <i class="mr-1 material-icons text-16">{icon}</i>
      <span class="text-xs">{title}</span>
    </button>
  </slot>
{:else}
  <div class="" bind:this={content}>
    <slot />
  </div>
{/if}
