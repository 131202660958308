import type { schema } from "@editor/schema";

function info() {
  const o = Intl.DateTimeFormat().resolvedOptions();
  return { locale: o.locale, timeZone: o.timeZone };
}

export function fromDate(startDate: Date, durationInMinutes?: number): schema.DateRange {
  return {
    startsAt: new Date(startDate.toUTCString()).toISOString(),
    durationInMinutes,
    ...info()
  };
}

export function start(dateRange: schema.DateRange): Date {
  return new Date(dateRange.startsAt);
}

export function end(dateRange: schema.DateRange): Date {
  if (dateRange.durationInMinutes) {
    return start(dateRange).addMinutes(dateRange.durationInMinutes);
  }
  return start(dateRange);
}

export function evaluate(dateRange: schema.DateRange) {
  const now = Date.now();
  const startDate = start(dateRange);
  const endDate = end(dateRange);
  return {
    started: now > startDate.getTime(),
    startDate,
    ended: endDate.getTime() < now,
    endDate
  };
}

export function toStartOfDay(date: Date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}
