<script lang="ts">
  import type { BackgroundAsset } from "@editor/design/types";
  import { getNewsletterStores } from "../newsletterStores";
  import type { RenderContext } from "../newsletterStores";

  const { ctx } = getNewsletterStores();

  const isTemplate = ctx.mode === "template";

  export let asset: BackgroundAsset;
</script>

<!-- wrapper -->

<div
  data-id={asset.id || ""}
  class="inset-0 overflow-hidden"
  class:absolute={asset.location == "header"}
  class:fixed={asset.location == "page-full" && !isTemplate}
  class:rounded-t={asset.location == "header"}
  style={asset.location === "page-full" ? "z-index:-1" : ""}>
  {#if asset.color}
    <div class="absolute inset-0 bg-cover z-1" style="background-color: {asset.color}" />
  {/if}

  {#if asset.image}
    <div
      class:bg-fixed={asset.location === "page-full"}
      class:custom-background={asset.location === "page-full"}
      class="absolute inset-0 bg-cover z-2"
      style="background-image: {asset.image.g}" />

    <!-- We do a fancy fade in once we loaded the image -->
    <img
      class="absolute inset-0 block object-cover w-full h-full transition-opacity duration-300 opacity-0 z-4 "
      class:custom-background={asset.location === "page-full"}
      class:bg-fixed={asset.location === "page-full"}
      src={asset.filters ? asset.image.blur : asset.image.xl}
      alt="page background"
      onload="this.style.opacity=1" />

    {#if asset.filters}
      <div class="absolute inset-0 bg-white z-5 bg-opacity-30" />
    {/if}
  {/if}
</div>

<style>
  :global(html.screenshot.no-bg) .custom-background {
    visibility: hidden;
  }
</style>
