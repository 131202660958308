import { DateTimeTZUtils } from "@editor/app/blocks/event/DateTimeTZUtils";
import type { Newsletter, EventBlock } from "../../defs/version_1";
import { migrateBlocks } from "./utils";

type OldEventBlock = Omit<EventBlock, "date"> & {
  startsAt: Date;
  endsAt?: Date;
};

export function migrate_event_start_and_end_dates_structure_change(n: Newsletter) {
  migrateBlocks<OldEventBlock, EventBlock>(
    n.content,
    (block) => {
      // The following check validates that the block is in the old dates format
      return block._t === "event" && Object.keys(block).includes("startsAt") && !Object.keys(block).includes("date");
    },
    (ob) => {
      if (ob.startsAt) {
        return {
          ...ob,
          date: DateTimeTZUtils.fromDate(new Date(ob.startsAt), ob.endsAt ? new Date(ob.endsAt) : undefined)
        };
      }
      return { ...ob } as any;
    }
  );
}
