<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./Block.svelte";

  export let block: schema.Block;
</script>

<Block {block} on:click on:delete let:isEmpty showEmpty>
  <div class="bg-red-200 ">
    <h4>FALLBACK: {block._t}</h4>

    <code class="text-xs"> {JSON.stringify(block)} </code>
  </div>
</Block>
