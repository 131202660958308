import { TextComponent, Component, FontDef, Position, Size, LineInfo, glyphIterator, glyphToSVG } from "./base";
import { splitColor } from "./base";
import type { Glyph } from "../../fonts/types";
import { isSpace, layout, Line, sizeInPx, Word } from "../text/process";

export type SvgWord = {
  svg: string;
  text: string;
  width: string;
  height: string;
};

export type RunningTextProps = {
  children?: [string];
  font: FontDef;
  color?: string;
  size: number;
  fieldKey?: string;
  lineHeight?: number;
  letterSpacing?: number;
  spaceTracking?: number;
  useRef?: boolean;
  rtl: boolean;
};

export function RunningText(props: RunningTextProps): SvgWord[] {
  const text = props.children?.[0] || "";
  const def = props.font;
  const leading = props.lineHeight || 0;
  const tracking = props.letterSpacing || 0;
  const size = props.size || 24;
  const fieldKey = props.fieldKey || "";
  const spaceTracking = props.spaceTracking || 0;
  const useRef = props.useRef;
  // split color to fix opacity issues with RGBAs
  const [color, opacity] = splitColor(props.color || "#999999");

  const { lines, scale } = layout(text, {
    align: "left",
    font: { def, leading, max: size, min: size, tracking, spaceTracking },
    container: { height: Number.POSITIVE_INFINITY, width: Number.POSITIVE_INFINITY, linesMax: 1 }
  });

  const words: SvgWord[] = [];

  lines.forEach((line) => {
    for (let wordsChunk of iterateWordsWithSpace(line.words)) {
      words.push(renderWords(wordsChunk, line, scale, fieldKey, color, opacity, leading));
    }
  });

  return props.rtl ? words.reverse() : words;
}
function renderWords(words: Word[], l: Line, scale: number, fieldKey: string, color: string, opacity: string, leading: number): SvgWord {
  const glyphs: string[] = [];
  const output: string[] = [];
  let isFirst = l.words[0] === words[0];
  const dy = (l.dy + l.yMax) * scale;
  const dx = 0;
  const lh = ((l.height + leading) * scale).toFixed(2);

  const ww = (sum(words.map((w) => w.width)) * scale).toFixed();
  const text = words.map((w) => w.text).join("");

  // c
  for (let [glyph, gdx] of glyphIterator(words)) {
    glyphs.push(glyphToSVG(glyph, gdx));
  }

  output.push(
    // actual text,
    `<g aria-hidden="true" transform="translate(${dx}, ${dy}) scale(${scale})">${glyphs.join("")}
      </g>`,
    /* add a rect for hit tests */ `<rect x="${dx}" y="${dy - l.yMax * scale}" fill="#a10" opacity="0.0" width="${ww}" height="${lh}" />`,
    `<text x="${dx}" textLength="${ww}" lengthAdjust="spacingAndGlyphs" height="${lh}" fill="transparent" opacity="1" font-family="arial" font-weight="bold" font-size="${lh}" y="${dy}">${text
      .replace(/&/gi, "&amp;")
      .replace(/</gi, "&lt;")
      .replace(/>/gi, "&gt;")}</text>`
  );

  const svg = `<svg viewbox="0 0 ${ww} ${lh}" width="${ww}" height="${lh}" xmlns="http://www.w3.org/2000/svg" class="inline">
  <g fill="${color}" ${fieldKey ? `data-field-key="${fieldKey}"` : ""} 
  ${opacity !== "1.00" ? `opacity="${opacity}"` : ""} class="svg-text-group"
  >${output.join("")}</g></svg>`.trim();

  return {
    svg,
    text,
    width: ww,
    height: lh
  };
}

function sum(input: number[]) {
  return input.reduce((prev, curr) => prev + curr, 0);
}

function* iterateWordsWithSpace(words: Word[]): Iterable<Word[]> {
  let current: Word[] = [];

  for (let w of words) {
    if (!current.length || isSpace(w)) {
      current.push(w);
      continue;
    }

    yield current;
    current = [w];
  }

  if (current.length) {
    yield current;
  }
}
