import { readable } from "svelte/store";
import type { Readable } from "svelte/store";

export function lastMutatedStore(el: Element, debounceMS: number = 50): Readable<Date> {
  let _nextUpdate = 0;
  return readable(new Date(), (set) => {
    // we use a mutation observer to observe the changes
    const observer = new MutationObserver(() => {
      // we don't care about the change nature, just that there was a change

      // clear pending change:
      window.clearTimeout(_nextUpdate);
      // queue a change notification
      const t = new Date();
      _nextUpdate = window.setTimeout(() => set(t), debounceMS);
    });

    // start observing
    observer.observe(el, { subtree: true, childList: true, characterData: true, attributes: true });

    return () => {
      // clean up
      observer.disconnect();
      clearTimeout(_nextUpdate);
    };
  });
}

/**
 * Convert a long string to middle ellipsis, useful for shortening urls
 */
export function midEllipsis(input: string, fromStart: number = 30, fromEnd: number = 20): string {
  if (!input || input.length < fromStart + 3 + fromEnd) {
    return input;
  }

  return input.slice(0, fromStart) + "..." + input.slice(input.length - fromEnd);
}
