import { writable, Writable } from "svelte/store";
import { getContext, setContext } from "svelte";
import uniqBy from "lodash.uniqby";

const CONTEXT_KEY = {};

interface StoreProps {
  youtubeApiKey: string;
  googlePlacesApiKey: string;
  customBackgrounds: CustomBackground[];
}

export type CustomBackground = {
  _id: string;
  user_id: string;
  uri: string;
  thumb: string;
  deleted: boolean;
};

interface EditorStore extends Writable<StoreProps> {}

export function createEditorStore(props: StoreProps) {
  const store = createStore(props);
  setContext(CONTEXT_KEY, store);
  return store;
}

export function getEditorStore() {
  let store = getContext(CONTEXT_KEY);
  if (!store) {
    throw new Error(`EditorStoreContext was not initialized, call createEditorStore first`);
  }

  return store as EditorStore;
}

function createStore(props: StoreProps): EditorStore {
  let { customBackgrounds, ...rest } = props;

  // Make the custom backgrounds unique
  customBackgrounds = uniqBy(customBackgrounds, (i) => i.uri);

  const store = writable<StoreProps>({ ...rest, customBackgrounds });

  return {
    subscribe: store.subscribe,
    set: store.set,
    update: store.update
  };
}
