<script lang="ts">
  import BlockEditor from "./BlockEditor.svelte";
  import type { schema } from "@editor/schema";
  import CTAField from "./form/CTAField.svelte";
  import TextField from "./form/TextField.svelte";
  import PhotoField from "./form/PhotoField.svelte";
  import CollapsibleSection from "@ui/CollapsibleSection.svelte";

  import cloneDeep from "lodash.clonedeep";

  export let block: schema.ImageBlock;
  let draft: schema.ImageBlock = cloneDeep(block);
  export let focusField: string = "content";
  export let handoffId: string | undefined = undefined;

  const ResizeInformation = {
    min: { w: 5, h: 2 },
    max: { w: 1400, h: 1100 },
    default: { w: 700, h: 550 }
  };
</script>

<BlockEditor {draft} on:done on:cancel let:logged_out>
  <PhotoField
    bind:value={draft.photo}
    {handoffId}
    autofocus={focusField === "content"}
    on:logged_out={logged_out}
    mode="resize"
    ar={ResizeInformation}
    showErrorMessage>
    <img
      slot="preview"
      let:previewUrl={preview}
      src={preview}
      class="block object-contain mx-auto bg-gray-100 rounded"
      style="max-height:550px;max-width:700px;" />
  </PhotoField>
  {#if draft.photo}
    <div class="mt-3">
      <TextField label="Alt Text" bind:value={draft.photo.alt_text} placeholder="Enter an alt text" />
      <CollapsibleSection title="Make this photo linkable" captionClasses="mt-2" icon="link" startOpen={!!block.photo?.cta} autofocus>
        <div class="mt-3">
          <CTAField
            label="Target url"
            bind:value={draft.photo.cta}
            placeholder="Enter a URL for the title target (like https://www.google.com)" />
        </div>
      </CollapsibleSection>
    </div>
  {/if}
</BlockEditor>
