import type { Glyph } from "../../fonts/types";
import type { Word } from "../text/process";

import { getNewsletterStores } from "../../../newsletterStores";

export type { FontDef } from "../../fonts/types";

export type Size = { w: number; h: number };
export type Position = { x: number; y: number };

// Todo - name it better, this is useful for things like border radius etc
export type Padding = number | [number] | [number, number] | [number, number, number] | [number, number, number, number];

export type Component = {
  // Render output to svg
  toSVG(available: Size, p: Position): string;
  // calculate size
  size(available: Size): Size;
  minSize?(available: Size): Size;
};

export interface TextComponent extends Component {
  linesInfo(available: Size, p: Position): LineInfo[];
}

export type LineInfo = {
  p: Position;
  size: Size;
};

export function toChildren<T extends Component = Component>(c: T[] | T | undefined): T[] {
  return [c].flat().filter((c) => c) as T[];
}

export function normalizePadding(p?: Padding): [number, number, number, number] {
  if (typeof p === "undefined") {
    return [0, 0, 0, 0];
  }

  if (typeof p === "number") {
    return [p, p, p, p];
  }

  switch (p.length) {
    case 1:
      return [p[0], p[0], p[0], p[0]];
    case 2:
      return [p[0], p[1], p[0], p[1]];
    case 3:
      return [p[0], p[1], p[2], 0];
    case 4:
      return p;
  }
}

export function padSize(p: Padding | undefined, size: Size): Size {
  p = normalizePadding(p);

  return {
    w: size.w - (p[1] + p[3]),
    h: size.h - (p[0] + p[2])
  };
}

export function padPosition(p: Padding | undefined, pos: Position): Position {
  p = normalizePadding(p);

  return {
    x: pos.x + p[3],
    y: pos.y + p[0]
  };
}

type SizeStrategy = {
  type: "layers" | "stack";
  spacing?: number;
};

export function childrenSize(available: Size, children: Component[], strategy: SizeStrategy) {
  let w = 0,
    h = 0;

  const spacing = strategy.spacing || 0;

  switch (strategy.type) {
    // Layers:
    // - All children get the same available size
    // - children size is the max on width and height of all children
    case "layers":
      children.forEach((c) => {
        const s = c.size(available);
        w = Math.max(w, s.w);
        h = Math.max(h, s.h);

        return { w, h };
      });
    // Stack
    // - First child get all the available size
    // - Next child get available size minus the size taken by the first, etc.
    // - Width is max on all children
    // TODO: support vertical direction
    case "stack":
      children.forEach((c, idx) => {
        // handle the spacing
        idx && (h += spacing);

        const sizeF = c.minSize || c.size;
        const s = sizeF({ w: available.w, h: Math.max(available.h - h, 0) });
        w = Math.max(w, s.w);
        h += s.h;
      });

      return { w, h };
  }
}

export function h(func: Function, attributes: {}, ...children: []): Component {
  return func({ children, ...attributes });
}

export function splitColor(hexColor: string): [string, string] {
  if (hexColor[0] !== "#") {
    return [hexColor, "1.00"];
  }
  const color = hexColor.slice(0, 7); // "#a10a10"
  const opacityByte = Number("0x" + (hexColor.slice(7, 9) || "FF"));

  const opacity = invlerp(0, 255, opacityByte);

  return [color, opacity.toFixed(2)];
}

const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
const invlerp = (a: number, b: number, v: number) => clamp((v - a) / (b - a));
const clamp = (v: number, min = 0, max = 1) => Math.min(max, Math.max(min, v));

// Render a glyph to SVG, handle emojis
export function glyphToSVG(glyph: Glyph, dx: number): string {
  const { ctx } = getNewsletterStores();
  // spacial case for space
  if (glyph.code === 32) {
    return "";
  }
  if (glyph.path.startsWith("emoji:")) {
    return `<image href="https://cdn.smore.com/emoji/240/${glyph.path.substring(6)}.png" x="${dx.toFixed(2)}" y="${-glyph.font
      .baseline}" width="${glyph.w}" height="${glyph.w}" />`;
  }

  if (ctx.mode === "render-email") {
    return `<path d="${glyph.path}" transform="translate(${dx},0)" />`;
  }

  if (!glyph.font.pathsUrl) {
    return "";
  }

  // svg use doesn't support non relative paths - so we proxy the fonts from the cdn, see app_handling.conf
  return `<use href="/_fr/f/${glyph.font.pathsUrl}#g-${glyph.code}" x="${dx.toFixed(2)}"/>`;
}

// Iterate words, return an iterator of glyph, number for the x offset of the glyph
// this is done PER LINE
export function* glyphIterator(words: Word[], dx: number = 0): Iterable<[Glyph, number]> {
  for (let w of words) {
    for (let r of w.runs) {
      for (let i = 0; i < r.glyphs.length; i++) {
        const g = r.glyphs[i];
        const o = r.offsets[i];
        yield [g, dx + o];
      }
      dx += r.width;
    }
  }
}

export function lsb(word: Word): number {
  return word.runs[0].glyphs[0].lsb;
}
