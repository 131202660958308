<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import FieldLabel from "./FieldLabel.svelte";
  import type { schema } from "@editor/schema";
  import { setUploadById, uploadById } from "./uploads";

  import { field } from "./store";
  import { requiredTest } from "./validations";
  import FileTarget from "dragshow/FileTarget.svelte";
  import { upload } from "sbelt/net";
  import type { Upload } from "sbelt/net";
  import { blobToDataURL } from "sbelt/image";
  import Spinner from "@ui/Spinner.svelte";
  import { editPhotoModal } from "@editor/pintura";
  import type { PinturaEditorOptions } from "@editor/pintura";
  import { createEventDispatcher } from "svelte";
  import { ServerError } from "@ui/http";
  import { reportUserEvent, USER_EVENTS } from "@ui/reportEvents";
  import { getNewsletterStores } from "../../newsletterStores";

  const PHOTO_SIZE_LIMIT = 8 * 1024 * 1024; // 8MB
  const LOGROCKET_FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB

  type PhotoUploadResult = { result: { full: string; thumb: string } };
  type AspectRatio = { min: { w: number; h: number }; max: { w: number; h: number }; default: { w: number; h: number } };

  export let value: schema.Photo | undefined | null;
  export let label: string | undefined = undefined;
  export let required: boolean = false;
  export let autofocus: boolean = false;
  export let error: string | undefined = undefined;
  export let placeholder: string = "";
  export let disabled: boolean = false;
  export let isUploading: boolean = false;
  export let accept: string = "image/*";
  export let uploadEndpoint: string = "/app/embed/image_post";
  // an optional id for performing handoffs
  export let handoffId: string | undefined = undefined;
  export let ar: AspectRatio | undefined = undefined;
  export let mode: "resize" | "crop" = "resize";
  export let showErrorMessage: boolean;
  export let errorMessagePosition: "onTop" | "above" = "onTop";

  let errorCodeToMessage: { [code: string]: string } = {
    format_not_supported: "We only support JPEG, PNG and GIFs at this time.",
    image_too_large: "Image has to be smaller than 8MB.",
    file_not_jpg: "File doesn't seem to be a JPG file.",
    image_resolution_too_high: "Image resolution has to be smaller than 17MP."
  };

  const dispatch = createEventDispatcher();

  const { id: newsletterId } = getNewsletterStores();

  let ft: FileTarget;
  let errorMessage: string | undefined;
  let photoFieldContainerEl: HTMLElement;

  /**
   * Ignores LogRocket recording to prevent the "Payload too large (> 10MB). Event: lr.browser.NodeChangeEvent" error.
   * @see https://docs.logrocket.com/docs/privacy
   */
  function ignoreLogRocket(ignore: boolean) {
    if (ignore) {
      photoFieldContainerEl.setAttribute("data-private", "true");
    } else {
      photoFieldContainerEl.removeAttribute("data-private");
    }
  }

  // fix an issue with empty photos which are {id:"1"} and such, treat them as empty photos
  function clearEmpty(v: schema.Photo | undefined | null | { id: string }) {
    // @ts-ignore
    if (v?.id && !v.legacy_full_url) {
      return undefined;
    }
    return v;
  }

  const { id, input, setWait } = field<schema.Photo | undefined>(clearEmpty(value) || undefined, (c) => ([value, error] = c), {
    validate: [required && requiredTest()]
  });

  // update from outside
  $: $input = value || undefined;

  if (autofocus) {
    onMount(() => !$input && ft.select());
  }

  let uploadPreview: string | undefined;
  async function doUpload(file: Blob, replaceOriginal: boolean = false) {
    if (!file) {
      return;
    }
    if (file.size > PHOTO_SIZE_LIMIT) {
      errorMessage = errorCodeToMessage["image_too_large"];
      dispatch("upload_end", { errorMessage });

      // We had an error, return
      return;
    }

    if (file.size > LOGROCKET_FILE_SIZE_LIMIT) {
      ignoreLogRocket(true);
    }

    errorMessage = undefined;
    // if we already have an upload, deal with it
    const body = new FormData();

    body.append(
      "thumb_info",
      JSON.stringify({
        mode,
        width: ar?.max.w ?? 600,
        height: ar?.max.h ?? 600,
        min_ratio: ar ? [ar.min.w, ar.min.h] : undefined,
        max_ratio: ar ? [ar.max.w, ar.max.h] : undefined,
        max_scale: 1.5,
        skip_upscale: true
      })
    );
    body.append("file", file);

    uploadPreview = previewUrl = undefined;
    [activeUpload, uploadPreview] = await Promise.all([upload(uploadEndpoint, body), blobToDataURL(file)]);
    handoffId && setUploadById(handoffId, { upload: activeUpload, preview: uploadPreview });
    processUpload(activeUpload, uploadPreview, replaceOriginal);

    ignoreLogRocket(false);
  }

  let isDestroyed = false;

  onDestroy(() => (isDestroyed = true));

  async function processUpload(upload: Upload<PhotoUploadResult>, preview: string, replaceOriginal = false) {
    [activeUpload, uploadPreview] = [upload, preview];
    isUploading = true;
    setWait("Uploading photo");
    dispatch("upload_start");

    let result: any;
    try {
      result = await activeUpload.done();
    } catch (e: any) {
      const err = e as ServerError;
      activeUpload = uploadPreview = previewUrl = undefined;
      // alert(e.code || "Oh no, something went wrong... try again");
      setWait(false);
      isUploading = false;
      // clear it
      handoffId && setUploadById(handoffId, undefined);

      if (err.code === "login_needed") {
        dispatch("logged_out");
      } else {
        switch (err.code) {
          case "resolution_too_high":
            errorMessage = `Image resolution can't be higher than ${err.extraData?.max_width}x${err.extraData?.max_height} pixels`;
            break;

          case "no_minimal_limit":
            errorMessage = `Image must be larger than ${err.extraData?.minWidth}x${err.extraData?.minHeight} pixels`;
            break;

          default:
            errorMessage = errorCodeToMessage[err.code] || ServerError.GENERIC_ERROR;
        }
      }
      dispatch("upload_end", { errorMessage });

      // We had an error, return
      return;
    }
    activeUpload = undefined;
    if (!isDestroyed && result) {
      // if we use clear, we don't keep the original input
      $input = {
        ...$input,
        ...result,
        ...(replaceOriginal ? { original_url: result.full } : {}),
        // this is hack here to also support the upload of a custom background
        legacy_full_url: result.full || result.uri,
        legacy_thumb_url: result.thumb,
        id
      };

      dispatch("upload_done", $input);
    } else {
      dispatch("upload_end");
    }
    setWait(false);
    isUploading = false;
    // clear it
    handoffId && setUploadById(handoffId, undefined);
    uploadPreview = undefined;
  }

  async function files(e: any) {
    const file = e.detail[0];

    doUpload(file, /*clear*/ true);
  }

  // TODO: try and do a nice handoff between our current preview and the new one
  $: previewUrl = $input?.legacy_thumb_url;
  $: preview = uploadPreview || previewUrl;
  // todo - get it from the global upload store
  let activeUpload: Upload<PhotoUploadResult> | undefined = undefined;

  const handoff = uploadById<PhotoUploadResult>(handoffId);
  if (handoff) {
    processUpload(handoff.upload, handoff.preview!);
  }

  export async function edit() {
    if (!$input) {
      return;
    }

    reportUserEvent(USER_EVENTS.NewsletterEditorEditPhotoModalOpen, {
      newsletterId,
      handoffId,
      photoId: $input.id,
      url: $input.legacy_full_url
    });

    if (!$input.original_url) {
      $input.original_url = $input?.legacy_full_url;
    }

    const savedState = JSON.parse($input.image_metadata || "{}");

    const options: Partial<PinturaEditorOptions> = {};
    if (ar) {
      const defaultAR = ar.default.w / ar.default.h;
      options.imageCropAspectRatio = defaultAR;
      options.cropSelectPresetOptions = [
        [defaultAR, "Header"],
        [undefined, "Custom"]
      ];
    }

    const result = await editPhotoModal($input.original_url!, savedState, { ...options });

    if (!result) {
      return;
    }

    const { file, state } = result;
    await doUpload(file);
    $input.image_metadata = JSON.stringify(state);
  }

  // Handle clear + dispatch an event
  function clear() {
    $input = undefined;
    previewUrl = undefined;
    dispatch("clear");
  }
</script>

<FieldLabel {label} {id} {required} {error} />
<FileTarget
  bind:this={ft}
  on:files={files}
  let:isOver
  let:activeDrag
  let:isRelevantDrag
  {accept}
  multiple={false}
  canClick={!value && !activeUpload}>
  <div class="relative overflow-hidden rounded group photo-field-container" bind:this={photoFieldContainerEl}>
    {#if errorMessage}
      <slot name="error" {errorMessage}>
        {#if showErrorMessage}
          <div
            class="w-full py-1 text-sm text-center text-white bg-red-600 bg-opacity-75"
            class:absolute={errorMessagePosition === "onTop"}>
            {errorMessage}
          </div>
        {/if}
      </slot>
    {/if}
    {#if preview}
      <slot name="preview" previewUrl={preview} {isOver} {isRelevantDrag} {activeDrag} {activeUpload}>
        {#key preview}
          <img src={preview} class="block object-contain w-full h-full bg-gray-100 rounded max-w-none" />
        {/key}
      </slot>
    {:else}
      <slot name="empty" {isOver} {isRelevantDrag} {activeDrag} {errorMessage}>
        <div
          class="flex flex-col items-center justify-center py-6 transition-all bg-gray-300 hover:bg-green-300 hover:text-green-700"
          class:bg-green-600={isOver}
          class:text-white={isOver}
          class:text-nxgray-400={!isOver}>
          <div class="text-4xl transition-all material-icons">add_a_photo</div>
          {#if isRelevantDrag}
            <p class="font-medium transition-all text-14">Drop here</p>
            <p class="transition-all text-13">(to start your upload)</p>
          {:else}
            <p class="font-medium transition-all text-14">Add a Picture</p>
            <p class="transition-all text-13">(or drag one in)</p>
          {/if}
        </div>

        {#if isRelevantDrag}
          <div
            transition:fade|local={{ duration: 150 }}
            class="absolute transition-all border-2 border-dashed rounded text-13 inset-2"
            class:border-white={isOver}
            class:border-nxgray-400={!isOver} />
        {/if}
      </slot>
    {/if}

    {#if activeUpload}
      <div
        transition:fade|local={{ duration: 150 }}
        class="absolute inset-0 transition-all backgdrop-filter backdrop-grayscale"
        style="width:{100 - $activeUpload.progress}%" />
      <div
        transition:fade|local={{ duration: 150 }}
        class="absolute inset-y-0 left-0 transition-all bg-green-700 bg-opacity-50"
        style="width:{$activeUpload.progress}%" />
      <div class="absolute inset-0 flex items-center justify-center" transition:fade|local={{ duration: 150 }}>
        <Spinner size={40} color="#fff" />
      </div>
    {/if}

    {#if value && !activeUpload && !disabled}
      <div
        class="absolute inset-x-0 bottom-0 flex items-stretch justify-center transition-all transform bg-black bg-opacity-25 rounded-b tools">
        {#if value.original_url}
          <button
            class="flex items-center justify-center flex-grow px-2 py-1 text-xs text-white bg-black rounded-bl cursor-pointer bg-opacity-80 hover:bg-green-600"
            type="button"
            on:click|preventDefault|stopPropagation={edit}><span class="mr-1 leading-none material-icons text-12">crop</span>Crop</button>
        {/if}

        <button
          type="button"
          class="flex items-center justify-center px-2 py-1 text-xs text-white bg-black rounded-br cursor-pointer bg-opacity-80 hover:bg-red-600"
          on:click|preventDefault|stopPropagation={clear}><span class="material-icons text-14">close</span></button>
      </div>
    {/if}

    <!-- SHow this if we have a photo already and we're dragging  -->
    <slot name="overlay">
      {#if isRelevantDrag && value}
        <div
          class="absolute inset-0 transition-all"
          class:bg-black={!isOver}
          class:bg-green-600={isOver}
          class:bg-opacity-100={isOver}
          class:bg-opacity-50={!isOver} />
        <div
          class="absolute flex items-center justify-center text-center text-white transition-all bg-opacity-50 border-2 border-dashed rounded text-13 inset-2"
          class:border-white={isOver}>
          Drop here
        </div>
      {/if}
    </slot>
  </div>
</FileTarget>

<style>
  .tools {
    opacity: 0;
    transform: translateY(100%);
  }

  div.group:hover .tools {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 250ms;
  }

  .photo-field-container {
    background: var(--background, "transparent");
  }
</style>
