<script lang="ts">
  import { getNewsletterStores } from "../newsletterStores";
  import BlockEditor from "./BlockEditor.svelte";
  import type { schema } from "@editor/schema";
  import cloneDeep from "lodash.clonedeep";
  import FileField from "./form/FileField.svelte";
  import type { FileUpload } from "./form/FileField.svelte";

  const { id } = getNewsletterStores();

  export let focusField: string = "file";

  export let block: schema.EmbedFileBlock;
  let draft: schema.EmbedFileBlock = cloneDeep(block);

  const _SUPPORTED_FORMATS = {
    txt: "text/plain",
    rtf: "application/rtf",
    pdf: "application/pdf",
    // Word
    doc: "application/msword",
    dot: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    docm: "application/vnd.ms-word.document.macroEnabled.12",
    dotm: "application/vnd.ms-word.template.macroEnabled.12",
    // Excel
    xls: "application/vnd.ms-excel",
    xlt: "application/vnd.ms-excel",
    xla: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
    xltm: "application/vnd.ms-excel.template.macroEnabled.12",
    xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
    xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    // PowerPoint
    ppt: "application/vnd.ms-powerpoint",
    pot: "application/vnd.ms-powerpoint",
    pps: "application/vnd.ms-powerpoint",
    ppa: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
    ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
    pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
    potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
    ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
    // Access
    mdb: "application/vnd.ms-access",
    // OneNote
    onetoc: "application/onenote",
    // Visio
    vsd: "application/vnd.visio",
    vsdx: "application/vnd.visio2013",
    // CSV
    csv: "text/csv"
  };

  let supportExtensions = Object.keys(_SUPPORTED_FORMATS).map((extension) => "." + extension);
  const supportMimeTypes = Object.values(_SUPPORTED_FORMATS);

  const supportedExtensionAndMimeTypes = supportExtensions.concat(supportMimeTypes);

  let accept: string | undefined = supportedExtensionAndMimeTypes.toString();

  let file: FileUpload | undefined = draft.access_url
    ? {
        access_url: draft.access_url,
        file_name: draft.file_name,
        file_size_in_bytes: draft.file_size_in_bytes,
        file_type: draft.file_type,
        file_description: draft.file_description
      }
    : undefined;

  $: if (file) {
    draft.access_url = file.access_url;
    draft.file_name = file.file_name;
    draft.file_size_in_bytes = file.file_size_in_bytes;
    draft.file_type = file.file_type;
    draft.file_description = file.file_description;
  }
</script>

<BlockEditor {draft} on:done on:cancel>
  <FileField
    bind:value={file}
    uploadEndpoint="/app/attachments/embed_attachment_json?newsletter_id={id}"
    autofocus={focusField === "file"}
    required
    handoffId="attachment-{block._id}"
    {accept}
    on:logged_out />
</BlockEditor>
