<script lang="ts" context="module">
  import type { schema } from "@editor/schema";
  import { fade } from "svelte/transition";
  import { getNewsletterStores } from "../newsletterStores";
  import type { DesignStoreContent } from "../newsletterStores";
  import Background from "../rendering/Background.svelte";
  import { loadGlyphsForText, headers } from "../svgkit";
  import Block from "./Block.svelte";
  import { isEditor } from "./utils";
  import type { HeaderProps } from "../svgkit/rendering/headers/types";
  import Spinner from "@ui/Spinner.svelte";
  import { isRTL } from "sbelt/text";

  export const DEFAULT_AND_MIN_HEIGHT = 280;
  export const MAX_HEIGHT = 350;

  function headerHeight(b: schema.HeaderBlock, feelId: string) {
    if (b && b.custom) {
      return Math.min(Math.max(b.custom?.h || DEFAULT_AND_MIN_HEIGHT, DEFAULT_AND_MIN_HEIGHT), MAX_HEIGHT);
    }

    switch (feelId) {
      case "classic-legacy":
      case "handwritten":
      case "minimal":
      case "vintage":
      case "stars":
      case "pumpkin":
      case "summertime":
      case "modern":
      default:
        return DEFAULT_AND_MIN_HEIGHT;
    }
  }

  export function headerForDesign(b: schema.HeaderBlock, d: DesignStoreContent) {
    const { fontPack, color, feel } = d;
    const { title, subtitle, spacing } = fontPack.header;

    const rtl = isRTL(b.title);

    const props: HeaderProps = {
      spacing,
      height: headerHeight(b, d.feel.id),
      title: {
        color: color.header.title,
        stripe: color["text.title"].stripe,
        font: title.customFont,
        text: b.title,
        limits: { min: title.minSize, max: title.maxSize },
        letterSpacing: title.letterSpacing,
        lineHeight: title.lineHeight
      },
      sub: {
        color: color.header.subtitle,
        font: subtitle.customFont,
        text: b.subtitle || (isEditor() ? "Click to add subtitle..." : ""),
        limits: { min: subtitle.minSize, max: subtitle.maxSize },
        letterSpacing: subtitle.letterSpacing,
        lineHeight: subtitle.lineHeight
      },
      shadow: color.header.bg,
      rtl
    };

    switch (feel.id) {
      case "classic-legacy":
        return headers.classic(props);
      case "minimal":
        return headers.minimal(props);
      case "vintage":
        return headers.vintage(props);
      case "handwritten":
        return headers.handwritten(props);
      case "heartfelt":
        return headers.heartfelt(props);
      case "blossom":
        return headers.blossom(props);
      case "summertime":
        return headers.summertime(props);
      case "pumpkin":
        return headers.pumpkin(props);
      case "stars":
        return headers.stars(props);
      case "modern":
      default:
        return headers.modern(props);
    }
  }
</script>

<script lang="ts">
  const { design } = getNewsletterStores();

  export let block: schema.HeaderBlock;

  // We don't use 'async' function here, because the "#await" directive in
  // svelte will render the "result" part immedietly if encountered a non promised value
  // which we rely on for SSR, and for quick transition between fonts (without the transition effects)
  // "async" function automatically wrap *all* result types in promise, thus making it problematic
  // also - in SSR rendering mode, svelte will render *only* the loading state
  // (for the #await directive) if encoutering a promise
  function loadFontsForHeader(b: schema.HeaderBlock, d: DesignStoreContent) {
    const { title, subtitle } = d.fontPack.header;

    return loadGlyphsForText(`${b.title} ${b.subtitle}`, title.customFont, subtitle.customFont);
  }

  // Toggle rendering of text
  $: renderText = !block.custom || block.custom.showText;
</script>

<Block {block} on:click size="clear" preventDelete let:isEditing showEmpty>
  <svelte:fragment slot="background">
    <div class="absolute inset-0 hidden bg-black ada-block" />

    {#if !block.custom}
      {#key $design.background.id}
        {#if $design.color.header.bg}
          <div
            transition:fade|local={{ duration: 75 }}
            class="absolute inset-0 overflow-hidden transition-all duration-75 rounded-t ada-hidden"
            style="background-color: {$design.color.header.bg}" />
        {/if}

        <Background background={$design.background} location="header" />

        {#if $design.color.header.tint}
          <div
            transition:fade|local={{ duration: 75 }}
            class="absolute inset-0 overflow-hidden transition-all duration-75 rounded-t ada-hidden"
            style="background: {$design.color.header.tint}" />
        {/if}
      {/key}
    {/if}
  </svelte:fragment>

  {#if isEditor()}
    <!-- Overlay -->
    <div class="absolute inset-0 rounded-t pointer-events-none block-overlay" />
  {/if}

  <!-- Render title / subtitle for accessibility and translation -->

  <div
    class="absolute inset-0 flex flex-col items-center justify-center p-6 text-center opacity-0 pointer-events-none translate-pointer-events-auto translate-opacity-100 translate-rtl">
    <h1
      class="px-4 py-3 mb-2 text-2xl bg-black rounded-md ada-text-white md:text-6xl bg-opacity-60"
      style="color: {$design.color.header.title}">
      {block.title}
    </h1>
    {#if block.subtitle}
      <h2
        class="px-4 py-1 text-base bg-black rounded-md ada-text-white md:text-2xl bg-opacity-60"
        style="color: {$design.color.header.subtitle}">
        {block.subtitle}
      </h2>
    {/if}
  </div>

  {#if block.custom}
    <!-- custom background logic here -->
    <div class="flex items-center justify-center w-full">
      <img
        src={block.custom.url}
        alt="{block.title} {block.subtitle}"
        style="max-height:{MAX_HEIGHT}px;min-height:{DEFAULT_AND_MIN_HEIGHT}px;"
        class="inset-0 object-contain rounded-t pointer-events-none" />
    </div>
  {/if}

  <!-- Render SVG header -->
  <div
    class="inset-0 transition-all duration-150 ease-in-out"
    class:invisible={!renderText}
    class:absolute={block.custom}
    style="height: {isEditor() ? headerHeight(block, $design.feel.id) + 'px' : 'auto'}">
    {#await loadFontsForHeader(block, $design)}
      <div class="absolute inset-0 top-0 flex items-center justify-center" transition:fade|local={{ duration: 150 }}>
        <div class="p-5 text-white bg-white bg-opacity-25 rounded-full text-14"><Spinner size={35} color="rgba(255,255,255,0.4)" /></div>
      </div>
    {:then r}
      <div
        class="translate-invisible ada-text-white"
        class:absolute={isEditor()}
        class:inset-0={isEditor()}
        transition:fade|local={{ duration: 150 }}>
        {@html headerForDesign(block, $design)}
      </div>
    {/await}
  </div>

  {#if isEditing}
    <div class="absolute inset-0 bg-black bg-opacity-25 rounded-t" transition:fade={{ duration: 150 }} />
  {/if}
</Block>

<style>
  .block-overlay {
    background: #dcf4f1;
    opacity: 0.2;
  }

  .svg-container svg {
    max-width: 100%;
  }
</style>
