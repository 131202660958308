<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./Block.svelte";
  import { getNewsletterStores } from "../newsletterStores";
  import VideoBlockWeb from "./VideoBlockWeb.svelte";

  export let block: schema.VideoBlock;
  const showActualPlayerPreview = true;
  const { design } = getNewsletterStores();
</script>

<Block
  {block}
  on:click
  on:delete
  on:duplicate
  let:layout
  calcSize={(layout) => (layout === "lg" || layout === "-" ? "full" : "box")}
  let:isEmpty
  showEmpty>
  {#if isEmpty}
    <div class="flex h-64 items-center justify-center rounded-lg text-gray-600">
      <div class="flex flex-col items-center text-center">
        <div class="">
          <span class="material-icons-round notranslate text-6xl text-gray-500">play_arrow</span>
        </div>
        <div>
          <h1 class="text-15">Click to add a video</h1>
          <p class="mt-1 text-13">We support YouTube, Vimeo and Viddler</p>
        </div>
      </div>
    </div>
  {:else if layout === "side-l"}
    <div class="flex items-start justify-start">
      <div
        class="relative h-32 w-1/3 rounded bg-cover bg-center bg-no-repeat"
        class:bg-gray-200={!block.thumbnail_url}
        style="background-image: url({block.thumbnail_url || ''})">
        <div class="absolute flex h-full w-full items-center justify-center">
          <div class="material-icons-round notranslate text-6xl text-white">play_arrow</div>
        </div>
      </div>
      <div class="ml-4 flex h-32 flex-col justify-between">
        <div class="text-18 font-bold" style="color:{$design.background.dark ? '#fff' : $design.color['text.title'].text}">
          {block.title || ""}
        </div>
        <div class="text-15" style="color:{$design.background.dark ? '#fff' : $design.color['text.title'].text}">{block.target}.com</div>
      </div>
    </div>
  {:else if showActualPlayerPreview}
    <div class="relative my-2 overflow-hidden transition-all">
      <VideoBlockWeb bind:block />
      <!-- Add transparent overlay to prevent clicks on the player -->
      <div class="absolute inset-0 bg-transparent" />
    </div>
  {:else}
    <div class="relative">
      <div class="absolute flex h-full w-full items-center justify-center">
        <div class="material-icons-round notranslate text-9xl text-white">play_arrow</div>
      </div>
      <div class="video-block bg-cover bg-center bg-no-repeat" style="background-image: url({block.thumbnail_url || ''})" />
      <div
        class="absolute bottom-0 left-0 right-0 flex w-full items-end justify-between bg-gradient-to-t from-black to-transparent p-2 pt-10 text-14 text-white">
        <div class="">{block.title || ""}</div>
        <div class="opacity-75">{block.target}.com</div>
      </div>
    </div>
  {/if}
</Block>

<style>
  .video-block {
    height: 350px;
  }
</style>
