<script lang="ts">
  import BlockEditor from "./BlockEditor.svelte";
  import type { schema } from "@editor/schema";

  import CTAField from "./form/CTAField.svelte";
  import TextField from "./form/TextField.svelte";
  import PhotoField from "./form/PhotoField.svelte";
  import CollapsibleSection from "@ui/CollapsibleSection.svelte";
  import cloneDeep from "lodash.clonedeep";
  import { ResizeInformation } from "./LogoBlock.svelte";

  export let block: schema.LogoBlock;
  export let focusField: string = "content";

  let draft: schema.LogoBlock = cloneDeep(block);
</script>

<BlockEditor {draft} on:done on:cancel>
  <PhotoField
    bind:value={draft.picture}
    handoffId="logo-photo"
    required
    label="Logo picture"
    --background="#eee"
    on:logged_out
    mode="resize"
    ar={ResizeInformation}
    showErrorMessage>
    <img
      class="object-contain"
      style="max-height: 120px;max-width:400px;margin:5px auto"
      src={previewUrl}
      alt={"Logo"}
      let:previewUrl
      slot="preview" />
  </PhotoField>
  {#if draft.picture}
    <div class="mt-3">
      <TextField label="Alt Text" bind:value={draft.picture.alt_text} placeholder="Enter an alt text" />
      <CollapsibleSection title="Make the logo linkable" captionClasses="mt-2" icon="link" startOpen={!!block.picture?.cta} autofocus>
        <div class="mt-3">
          <CTAField
            label="Target url"
            bind:value={draft.picture.cta}
            placeholder="Enter a URL for the title target (like https://www.google.com)" />
        </div>
      </CollapsibleSection>
    </div>
  {/if}
</BlockEditor>

<style>
  .yes-picture,
  :global(.yes-picture .photo-item-editor .image-container),
  :global(.yes-picture img) {
    max-width: 140px;
    max-height: 67px;
  }
</style>
