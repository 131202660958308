<script lang="ts">
  import { field } from "./store";
  import FieldLabel from "./FieldLabel.svelte";
  import { requiredTest } from "./validations";
  import { createRadioGroupStore } from "./groupStore";

  export let label: string;
  export let value: any;
  export let required: boolean = false;
  export let error: string | undefined = undefined;

  const { id, input } = field<any>(value, (c) => ([value, error] = c), {
    validate: [required && requiredTest()]
  });

  const store = createRadioGroupStore(input);
</script>

<FieldLabel {id} {label} {required}>
  <svelte:fragment slot="after-label">
    <slot name="after-label" />
  </svelte:fragment>
  <svelte:fragment slot="after-required">
    <slot name="after-required" />
  </svelte:fragment>
  <slot />
</FieldLabel>
