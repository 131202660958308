import type { Newsletter, ItemsBlock, BaseBlock, Block } from "../../defs/version_1";
import { migrateBlocks } from "./utils";

type OldColumnsBlock = Omit<ItemsBlock, "_l"> & {
  _l: "list" | "columns";
};

export function migrate_columns_layout_change(n: Newsletter) {
  migrateBlocks<OldColumnsBlock, ItemsBlock>(n.content, "items", (ob) => {
    if (["list", "3-columns", "2-columns"].includes(ob._l)) {
      return { ...ob, _l: ob._l as ItemsBlock["_l"] };
    }

    const len = ob.items?.length || ob._defaults?.items.length || 0;

    return {
      ...ob,
      _l: len === 3 ? "3-columns" : "2-columns"
    };
  });
}
