<script lang="ts">
  import Portal from "sbelt/ui/Portal.svelte";
  import { clickOutside } from "@ui/clickOutside";

  import { computePosition } from "@floating-ui/dom";

  export let open: boolean = false;
  export let target: HTMLElement
  let el: HTMLElement;
  let p = {x:0,y:0};

  $: position(open);

  async function position(isOpen: boolean) {
    if (!isOpen || !target) {
      return;
    }

    p = await computePosition(target, el, {
      placement: "bottom-end",
      strategy: "absolute"
    });
  }

  function tryClose(e:any){
    if(!open) return;
    if(e.target === target){
      return;
    }
    open = false;
  }

</script>



<Portal active={open}>
  <div class="absolute z-50" bind:this={el} class:hidden={!open} style="left:{p.x}px;top:{p.y}px" use:clickOutside={tryClose}>
    <slot/>
  </div>
</Portal>
