<script lang="ts">
  import { onMount } from "svelte";
  import TimePicker, { dateTo24HTimeFormat } from "../event/TimePicker.svelte";
  import Field from "./Field.svelte";
  const MIN_DATE_STR = "1900-01-01";
  const MIN_DATE = new Date(MIN_DATE_STR);
  const MAX_DATE_STR = "2050-01-01";
  const MAX_DATE = new Date(MAX_DATE_STR);

  import { field } from "./store";
  import { requiredTest } from "./validations";
  import { inputDate, niceDate } from "@ui/time";

  export let value: Date = new Date();
  export let label: string | undefined;
  export let required: boolean = false;
  export let autocomplete: string = "off";
  export let autofocus: boolean = false;
  export let error: string | undefined = undefined;
  export let disabled: boolean = false;
  export let minStartDate: Date = MIN_DATE;
  // date format
  export let format: string = "";

  let el: HTMLInputElement;
  let isFocused: boolean = false;
  let isDateValid: boolean = true;
  const { id, input } = field<Date>(value, (c) => ([value, error] = c), {
    validate: [required && requiredTest(), myDateTest()]
  });
  // update from outside
  $: $input = value;

  function myDateTest() {
    return (v: Date) => {
      return isDateValid
        ? v.valueOf() <= MAX_DATE.valueOf()
          ? v.valueOf() >= minStartDate.valueOf()
            ? undefined
            : `No earlier than ${niceDate(minStartDate)}`
          : `No later than ${niceDate(MAX_DATE_STR)}`
        : "Required";
    };
  }

  if (autofocus) {
    onMount(() => el && el.focus());
  }

  // 24h HH:MM string for time
  let time: string = dateTo24HTimeFormat(value);
  // A date
  let date: string = value ? inputDate(value) : dateTo24HTimeFormat(new Date());

  function isValidDate(timestr: string): boolean {
    return !isNaN(Date.parse(timestr));
  }

  $: {
    isDateValid = isValidDate(`${date} ${time}`);
    if (isDateValid) {
      value = new Date(`${date} ${time}`);
    } else {
      if (el.value === "") {
        value = new Date(0);
        date = "";
        el.blur();
        window.requestAnimationFrame(() => {
          el.focus();
        });
      }
    }
  }
</script>

<Field {label} {id} {required} {error} {value} size="md" {isFocused}>
  <input
    type="date"
    class="mr-1 -ml-1 bg-white outline-none text-13"
    {id}
    min={minStartDate.toISOString().split("T")[0]}
    max={MAX_DATE_STR}
    bind:value={date}
    style="width:100px"
    bind:this={el}
    on:focus={() => (isFocused = true)}
    on:blur={() => (isFocused = false)} />

  <div class="pl-1 ml-1 border-l text-13">
    <TimePicker on:blur={() => (isFocused = false)} on:focus={() => (isFocused = true)} bind:value={time} />
  </div>
</Field>

<style>
  input[type="date"] {
    -webkit-align-items: center;
    display: -webkit-inline-flex;
    flex-direction: row-reverse;
    overflow: hidden;
    padding: 0;
    -webkit-padding-start: 1px;
  }

  input::-webkit-datetime-edit {
    -webkit-flex: 1;
    -webkit-user-modify: read-only !important;
    display: inline-block;
    min-width: 0;
    overflow: hidden;
  }

  input::-webkit-datetime-edit-fields-wrapper {
    -webkit-user-modify: read-only !important;
    display: inline-block;
    padding: 1px 0;
    white-space: pre;
  }

  input::-webkit-calendar-picker-indicator {
    margin: 0;
    opacity: 0.5;
    margin-right: 4px;
  }
</style>
