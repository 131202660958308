import { waitForLoad } from "./promise";

export type ImageSource = HTMLImageElement | HTMLVideoElement | HTMLCanvasElement | ImageBitmap;

type Options = {
  scale?: number;
  w?: number;
  h?: number;
  type?: "image/png" | "image/jpg";
  quality?: number;
};

export async function preload(src: string): Promise<unknown> {
  const i = new Image();
  i.src = src;
  return waitForLoad(i);
}

export function scaleImage(src: ImageSource, o: Options): Promise<Blob | null> {
  if (!o.h && !o.w && o.scale) {
    throw new Error("Scale, width or height must be provided");
  }

  const w = o.w ?? src.width;
  const h = o.h ?? src.height;

  const el = document.createElement("canvas");
  const dir = w < src.width || h < src.height ? "min" : "max";
  const stretch = o.h && o.w;
  const ratio = o.scale ? o.scale : Math[dir](w / src.width || 1, h / src.height || 1);
  const targetW = (el.width = stretch ? w : src.width * ratio);
  const targetH = (el.height = stretch ? h : src.height * ratio);
  const ctx = el.getContext("2d");
  ctx!.drawImage(src, 0, 0, targetW, targetH);

  return new Promise((resolve) => {
    el.toBlob(resolve, o.type || "image/jpg", o.quality || 0.8);
  });
}

export async function blobToDataURL(b: Blob): Promise<string> {
  const reader = new FileReader();
  reader.readAsDataURL(b);
  await waitForLoad(reader);
  return reader.result as string;
}

export async function blobToImage(b: Blob): Promise<HTMLImageElement> {
  const img = new Image();
  img.src = await blobToDataURL(b);
  await waitForLoad(img);
  return img;
}

export function dominantColor(src: ImageSource): string {
  const el = document.createElement("canvas");
  el.width = el.height = 1;
  const ctx = el.getContext("2d");
  ctx?.drawImage(src, 0, 0, 1, 1);
  const { data } = ctx!.getImageData(0, 0, 1, 1);

  return `#${(data[0] << 16) | (data[1] << 8) | data[2]}`;
}
