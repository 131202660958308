<script lang="ts">
  import type { schema } from "@editor/schema";
  import { isRTL } from "sbelt/text";
  import { getNewsletterStores } from "../newsletterStores";

  import Block from "./Block.svelte";
  import { withDefaults } from "./utils";
  import { LinkContent, SVGRunningText } from "./utils";
  const { design } = getNewsletterStores();

  export let block: schema.TitleBlock;

  $: [blockWidthDefaults, isDefault] = withDefaults(block, { title: "Click to add a title" });

  $: fp = $design.fontPack["text.title"];
  $: color = block.title ? $design?.color["text.title"].text : "#aaaaaa";
  $: rtl = isRTL(blockWidthDefaults.title);
  $: align = rtl && blockWidthDefaults.align == "left" ? "right" : block.align;

  function defaultSize(layout: string) {
    switch (layout) {
      case "sm":
        return 20;
      case "md":
        return 28;
      case "lg":
        return 36;
    }
  }
</script>

<Block {block} on:click on:delete on:duplicate let:layout layouts={["sm", "md", "lg"]} let:isEmpty showEmpty>
  {@const size = fp[`${layout}Size`] || defaultSize(layout)}
  <LinkContent cta={blockWidthDefaults.cta} linkText={blockWidthDefaults.title} blockId={blockWidthDefaults._id} class="block">
    <h2>
      <SVGRunningText
        font={fp.customFont}
        text={blockWidthDefaults.title}
        {color}
        {align}
        {rtl}
        {size}
        letterSpacing={fp.letterSpacing}
        spaceTracking={fp.spaceTracking}
        lineHeight={fp.lineHeight} />
    </h2>
  </LinkContent>
</Block>

<style>
  .top-line {
    height: 6px;
    width: 40px;
  }
</style>
