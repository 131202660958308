<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./Block.svelte";
  import { getNewsletterStores } from "../newsletterStores";
  import { isEditor, LinkContent, photoToCTA, fancyPicClassIfNoCTA } from "./utils";

  const { ctx } = getNewsletterStores();

  export let block: schema.ImageBlock;
</script>

<Block bind:block on:click on:delete on:duplicate size="full" let:isEmpty showEmpty layouts={["lg"]}>
  {#if isEmpty}
    {#if block._defaults}
      <div class="">
        {#if block._defaults.photo}<img src={block._defaults.photo.legacy_thumb_url} class="w-full h-full" alt="big photo" />{/if}
      </div>
    {:else}
      <div class="p-2">
        <div class="flex items-center justify-center h-64 text-gray-600 bg-gray-100 border-2 border-gray-400 border-dashed rounded-lg">
          <div class="flex flex-col items-center text-center">
            <div>
              <span class="text-6xl text-gray-500 material-icons-round notranslate">add_a_photo</span>
            </div>
            <div>
              <h1 class="text-15">Click to add a photo</h1>
              <p class="mt-1 text-13">We support JPEG, PNG and GIFs</p>
            </div>
          </div>
        </div>
      </div>
    {/if}
  {:else if block.photo}
    <div class="relative w-full group">
      <LinkContent
        showEmpty
        cta={block.photo.cta ? photoToCTA(block.photo) : undefined}
        type="photo"
        data-title={block.photo.title}
        class="relative block w-full h-full skip-tracking  {fancyPicClassIfNoCTA(block.photo)}"
        data-img-full={block.photo.legacy_full_url}
        linkText={block.photo.alt_text || block.photo.title}>
        <img
          src={block.photo.legacy_thumb_url}
          alt={block.photo.alt_text}
          title={block.photo.alt_text}
          class="object-contain w-full max-w-full max-h-full bg-transparent" />
        {#if !isEditor() && block.photo.cta?.url && block.photo.cta?.url.length}
          <div
            role="img"
            aria-label={block.photo.alt_text}
            class="absolute inset-0 opacity-0 group-hover:bg-gray-100 group-hover:bg-opacity-60 group-hover:opacity-100" />
          <div class="absolute top-0 right-0 opacity-0 group-hover:opacity-100">
            <div class="flex items-center">
              {#if block.photo.cta?.url}
                <LinkContent
                  showEmpty
                  cta={block.photo.cta ? photoToCTA(block.photo) : undefined}
                  type="photo"
                  data-title={block.photo.title}
                  class="relative flex p-1 mr-2 rounded bg-opacity-80 bg-nxgray-500 skip-tracking"
                  data-img-full={block.photo.legacy_full_url}
                  linkText={block.photo.alt_text || block.photo.title}
                  title="Open link">
                  <span aria-hidden="true" class="text-white material-icons notranslate text-18"> open_in_new </span>
                </LinkContent>
              {/if}
              <a class="p-1 rounded bg-opacity-80 fancy-pic bg-nxgray-500 skip-tracking" href={block.photo.legacy_full_url} title="Expand"
                ><span aria-hidden="true" class="text-white material-icons notranslate text-18"> zoom_out_map </span>
              </a>
            </div>
          </div>
        {/if}
      </LinkContent>
    </div>
  {/if}
</Block>

<style>
  img {
    max-height: 550px;
    object-fit: contain;
  }
  .fancy-pic {
    position: relative !important;
    display: flex !important;
  }
</style>
