<script lang="ts">
  import clonedeep from "lodash.clonedeep";
  import type { AutoSaveHelper } from "./autosave";
  import { noopAutosave } from "./autosave";
  import { createEventDispatcher, onDestroy } from "svelte";
  import debounce from "lodash.debounce";
  import { keyDown } from "@ui/keyDown";
  import { createFormContext } from "./store";
  import isEqual from "lodash.isequal";
  import Tooltip from "@ui/Tooltip.svelte";
  // the content
  export let data: {} = {};
  const original = clonedeep(data);

  export let submitLabel: string = "Done";
  export let submittingLabel: string = "Saving...";

  // an optional validation function on the draft
  export let validate: (draft: any) => string[] | undefined | void = () => [];

  // Override submit action to implement an async submit system (like submitting data to and endpoint etc)
  export let submitAction: (data: any) => Promise<any> = async (d) => d;

  // Function to invoke just before submitting the form. Can be used to notify the user they are about to take over the world.
  export let beforeSubmit: (data: any) => Promise<any> = async (d) => d;

  // Override for special error handling
  export let errorAction: (data: any, e: any) => void = () => {};

  // an optional dirty test for the form
  export let testDirty: (origin: any, data: any) => boolean = (src, draft) => !isEqual(src, draft);

  // use an autosave helper to save the data
  export let autosave: AutoSaveHelper<any> = noopAutosave;

  // create a debounced version of our autosave
  const debouncedSave = debounce(autosave.save, autosave.wait, { maxWait: 30 * 1000 });

  const dispatch = createEventDispatcher();
  const store = createFormContext();

  // test if dirty has changed
  $: isDirty = testDirty(original, data);
  $: errors = (validate(data) || [])
    .concat($store.errors)
    .flat()
    .filter((e) => e);
  $: waiting = $store.waiting; // calculate wating from fields
  $: canSubmit = !waiting.length && !errors.length;
  // save with autosave
  $: debouncedSave(data);

  let isSubmitting: boolean = false;
  // submitting the form
  async function submit() {
    // if we're already submitting / can't submit, cancel
    if (isSubmitting || !canSubmit) {
      return;
    }

    // if nothing has changed, submit is basically cancel
    if (!isDirty) {
      return cancel(undefined, "no_changes");
    }

    // cancel pending autosaves
    debouncedSave.cancel();

    // set is submitting
    isSubmitting = true;

    try {
      if (beforeSubmit) {
        await beforeSubmit(data);
      }

      // wait for our result from the submit action
      const result = await submitAction(data);

      dispatch("done", result);
      // clear autosave data
      autosave.clear(data);
    } catch (e: any) {
      errorAction(data, e);
      dispatch("error", { e, data });
    }

    isSubmitting = false;
  }

  function cancel(e: any, reason: string = "user") {
    if (isSubmitting) {
      return;
    }
    // cancel pending autosaves
    debouncedSave.cancel();
    autosave.clear(data);

    // Since we can get here from Submit as well (in case there were no changes),
    // we need to ensure `e` is not undefined
    if (e) {
      (e as KeyboardEvent).preventDefault();
    }
    dispatch("cancel", reason);
  }

  // cancel any autosaves
  onDestroy(debouncedSave.cancel);

  function expandError(error: string) {
    switch (error) {
      case "Required":
        return "Some fields are required";
      case "too long":
      case "doesn't match":
      case "not a valid url":
        return "Some fields have invalid inputs";
      default:
        return error;
    }
  }
</script>

<form
  class="relative flex flex-col items-start justify-between h-full rounded bg-gray-50"
  on:submit|stopPropagation|preventDefault={submit}
  use:keyDown={[
    { key: "Escape", handler: cancel },
    { key: "Enter", handler: submit, mod: "cmd" }
  ]}>
  <div class="w-full h-full">
    <slot name="overlay" {isSubmitting}>
      {#if isSubmitting}
        <div class="absolute inset-0 bg-transparent" />
      {/if}
    </slot>

    <!-- form content here-->
    <div class="w-full p-2">
      <slot {isSubmitting} {data} {original} {isDirty} {errors} {waiting} {canSubmit} />
    </div>
  </div>

  <!-- footer content -->
  <slot name="footer" {cancel} {submit} {errors} {waiting} {isSubmitting} {canSubmit}>
    <div
      class="flex items-center justify-between w-full px-2 py-2 pl-3 border border-b-0 border-l-0 border-r-0 border-gray-200 rounded-b bg-gray-50 border-px align-center">
      <span
        on:click|stopPropagation={cancel}
        class="transition-colors cursor-pointer text-13 text-nxgray-400 clickable scale-in hover:text-red-600"
        class:pointer-events-none={isSubmitting}>
        Cancel
      </span>

      <button
        type="submit"
        class="px-3 py-1 font-medium text-white transition-colors bg-green-600 rounded shadow text-14"
        on:click|stopPropagation={submit}
        class:clickable={canSubmit}
        class:hover:bg-green-500={canSubmit}
        class:bg-nxgray-200={isSubmitting || !canSubmit}
        class:cursor-not-allowed={isSubmitting || !canSubmit}
        class:text-gray-400={isSubmitting || !canSubmit}
        class:shadow-none={isSubmitting || !canSubmit}>
        {isSubmitting ? submittingLabel : submitLabel}
        {#if !canSubmit && errors}
          <Tooltip delay={50}>
            <h4 class="font-bold text-left text-white text-12">Can't Submit</h4>
            {#each errors as error}
              <div class="flex items-center mt-1 text-white text-12">
                <i class="mr-2 text-orange-600 material-icons md-18">warning</i>
                <span>{expandError(error)}</span>
              </div>
            {/each}
          </Tooltip>
        {/if}
      </button>
    </div>
  </slot>
</form>
