<script lang="ts">
  import { onMount, tick } from "svelte";
  import Field from "./Field.svelte";
  import { insertTextAtCursor as insertText } from "sbelt/dom";
  import CharLimit from "./CharLimit.svelte";

  import { field } from "./store";
  import { lengthTest, patternTest, requiredTest } from "./validations";
  import { isRTL } from "sbelt/text";

  export let value: string = "";
  export let label: string | undefined;
  export let required: boolean = false;
  export let icon: string | undefined = undefined;
  export let autocomplete: string = "off";
  export let autofocus: boolean = false;
  export let error: string | undefined = undefined;
  export let placeholder: string = "";
  export let disabled: boolean = false;
  export let limit: number | undefined = undefined;
  export let pattern: string | undefined = undefined;
  export let size: "sm" | "md" | "lg" = "md";
  export let align: "center" | "left" | "right" = "left";

  let el: HTMLInputElement;
  let isFocused: boolean = false;
  const { id, input } = field<string>(value, (c) => ([value, error] = c), {
    validate: [required && requiredTest(), lengthTest(limit), patternTest(pattern)]
  });
  // update from outside
  $: $input = value;

  if (autofocus) {
    onMount(async () => {
      if (!el) return;
      // wait for dom to update
      await tick();
      isFocused = true;
      el.select();
    });
  }

  export function insertTextAtCursor(text: string) {
    insertText(el, text);
  }

  export function validate() {
    const v = $input;
    $input = "";
    $input = v;
  }

  $: rtl = isRTL(value);
</script>

<Field {label} {id} {required} {error} {value} {icon} {size} {isFocused} {rtl}>
  <slot name="beforeInput" {rtl} {isFocused} />

  <input
    type="text"
    bind:value={$input}
    bind:this={el}
    on:input={() => (isFocused = true)}
    on:focus={() => (isFocused = true)}
    on:blur={() => (isFocused = false)}
    on:paste
    {required}
    {autocomplete}
    {placeholder}
    {id}
    {disabled}
    {autofocus}
    dir={rtl ? "rtl" : "ltr"}
    class="block flex-grow {size} outline-none text-rt"
    class:text-12={size === "sm"}
    class:text-13={size === "md"}
    class:text-16={size === "lg"}
    class:text-center={align === "center"}
    class:small-input={size === "sm"}
    class:leading-none={size === "lg"} />

  <CharLimit {value} {limit} --opacity={isFocused ? 1 : 0} />

  <slot name="afterInput" {rtl} />
</Field>
