<script lang="ts">
  import Sortable from "@editor/common/Sortable/index.svelte";
  import { schema } from "@editor/schema";
  import FieldLabel from "../form/FieldLabel.svelte";
  import AnswerEditor from "./AnswerEditor.svelte";
  import { toOrdinal } from "@editor/ui/stringUtils";
  import { id as generateId } from "../utils";
  import { field, getFormContext } from "../form/store";
  import debounce from "lodash.debounce";
  import { onMount, tick } from "svelte";

  export let label: string = "Answers";
  export let required: boolean = true;
  export let autofocus: boolean = false;
  export let answers: schema.PollAnswer[];
  export let error: string | undefined = undefined;

  let answerIdToFocus: string | undefined;

  const formContext = getFormContext();
  const debouncedUpdateErrors = debounce(updateErrors, 200);
  const { id: answersFieldId, input } = field<schema.PollAnswer[]>(answers, (c) => ([answers, error] = c), {
    validate: [required && ((v: schema.PollAnswer[]) => validate(v))]
  });

  $: required && debouncedUpdateErrors($input);
  $: answers = $input;
  $: notDeletedAnswers = $input.filter((answer) => !answer.deleted);

  function validate(v: schema.PollAnswer[]): undefined | string {
    const answersToValidate = v.filter((answer) => !answer.deleted && answer.text.trim().length > 0);
    if (required && answersToValidate.length < 2) {
      return "At least two answers are required";
    }
  }

  function updateErrors(pollAnswers: schema.PollAnswer[]) {
    error = undefined;
    error = validate(pollAnswers);
    formContext?.setError(answersFieldId, error);
  }

  function findAnswerIdToFocus() {
    answerIdToFocus =
      (notDeletedAnswers.length &&
        (notDeletedAnswers.find((a) => a.text.length === 0) || notDeletedAnswers[notDeletedAnswers.length - 1]).id) ||
      undefined;
  }

  $: focusAnswerInput(answerIdToFocus);

  onMount(findAnswerIdToFocus);

  async function addAnswer() {
    const answerId = generateId("answer");
    $input = [...$input, { text: "", id: answerId }];
    await tick();
    answerIdToFocus = answerId;
  }

  function focusAnswerInput(idToFocus: string | undefined) {
    if (autofocus && idToFocus) {
      const answerInput = document.querySelector<HTMLInputElement>(`#${idToFocus}  input[type=text]`);
      if (answerInput) {
        window.requestAnimationFrame(() => {
          answerInput.focus();
          answerIdToFocus = undefined;
        });
      }
    }
  }

  const sortableOptions = {
    group: "poll-answers",
    draggable: ".draggable-answer",
    filter: ".answer-no-move",
    forceFallback: true,
    touchStartThreshold: 15,
    fallbackTolerance: 15,

    onStart: (ev: any) => {
      const { oldIndex } = ev;
      const id = $input[oldIndex].id;
      document.body.classList.add("dragging");
    },

    onEnd: (ev: any) => {
      const { newIndex: dropIndex, oldIndex: dragIndex } = ev;
      const unsortedAnswers = [...$input];

      const id = notDeletedAnswers[dragIndex].id;

      const afterId = dropIndex ? (notDeletedAnswers[dropIndex] || notDeletedAnswers[notDeletedAnswers.length - 1]).id : undefined;
      if (dropIndex !== dragIndex) {
        const oldIndex = unsortedAnswers.findIndex((ans) => ans.id === id);
        const newIndex = afterId ? unsortedAnswers.findIndex((ans) => ans.id === afterId) : 0;
        if (oldIndex > -1) {
          const [answer] = unsortedAnswers.splice(oldIndex, 1);
          unsortedAnswers.splice(newIndex, 0, answer);
          $input = [...unsortedAnswers];
        }
      }

      document.body.classList.remove("dragging");
    }
  };
</script>

<FieldLabel {label} required {error}>
  <span slot="after-required" class="ml-1 text-nxgray-400 text-12">(minimum 2)</span>
  <div class="p-1 -m-1 border border-transparent rounded answers-wrapper block-no-move" class:error={required && error}>
    <Sortable options={sortableOptions} list={notDeletedAnswers}>
      {#each notDeletedAnswers as answer, index (answer.id)}
        <div class="draggable-answer" id={answer.id}>
          <AnswerEditor bind:answer {index} canDelete={notDeletedAnswers.length > 2} />
        </div>
      {/each}
    </Sortable>
  </div>
</FieldLabel>
<button
  type="button"
  on:click={addAnswer}
  class="flex items-center justify-start mx-px w-full p-1.5 mt-2 border border-gray-200 rounded text-nxgray-300 clickable hover:text-nxgray-500 focus:outline-none hover:shadow outline-none">
  <span class="text-13">{`Add a ${toOrdinal(notDeletedAnswers.length + 1)} answer`}</span>
</button>

<style>
  .draggable-answer + .draggable-answer {
    margin-top: theme("margin.2");
  }
</style>
