<script lang="ts">
  import type { schema } from "@editor/schema";
  import { getNewsletterStores } from "../newsletterStores";
  import Block from "./Block.svelte";
  import { newBlock } from "./blocksConfig";

  export let block: schema.PlaceholderBlock;
  const { design, content, api } = getNewsletterStores();

  function switchBlockType(toType: schema.BlockType, layout: "lg" | "columns") {
    const b = { ...newBlock(block._id, toType, layout), _o: block._o };

    // override block
    content.setBlock(block._id, b, /* don't unlock */ false);
  }
</script>

<Block {block} on:click on:delete on:duplicate let:isEmpty showEmpty size="full" preventClicks={false}>
  {#if block._l === "insert-photos"}
    <div class="flex p-2 wrap">
      <div
        class="flex flex-col items-center justify-center flex-grow p-2 m-2 bg-white rounded shadow clickable hover:bg-green-600 text-nxgray-600 hover:text-white"
        on:click={() => switchBlockType("items", "columns")}>
        <i class="text-4xl text-current material-icons">view_column</i>
        <h4 class="text-sm font-bold text-current">Columns</h4>
      </div>
      <div
        class="flex flex-col items-center justify-center flex-grow p-2 m-2 bg-white rounded shadow clickable hover:bg-green-600 text-nxgray-600 hover:text-white"
        on:click={() => switchBlockType("image.single", "lg")}>
        <i class="text-4xl text-current material-icons">photo</i>
        <h4 class="text-sm font-bold text-current">Big Photo</h4>
      </div>
    </div>
  {/if}
</Block>

<style>
  .wrap {
    background: #b3e2d0;
  }
</style>
