<script lang="ts" context="module">
  import type { schema } from "@editor/schema";
  import { getBlockId, isEditor, trackingAndAttributesFromCTA } from "../../utils";
  import { isEmpty as isEmptyTest } from "../../blocksConfig";
  import { getNewsletterStores } from "../../../newsletterStores";
  import { Base64PlaySVGImageData } from "./utils";

  const VIMEO_REGEX = /^https?:\/\/(?:www\.)?vimeo\.com\/([0-9]+)([\/0-9a-zA-Z]*)$/i;
</script>

<script lang="ts">
  export let block: schema.VideoBlock;

  let hash: string | undefined = undefined;

  $: isEmpty = isEmptyTest(block);

  // In some cases (such in bug #6198) people use unlisted video urls.
  // To overcome the error presented in those cases, we extract the hash parameter from their URL and add it to the player
  // Read more: https://vimeo.zendesk.com/hc/en-us/articles/4409305565069-Embedded-player-displays-This-video-does-not-exist-message
  // Link to smore github issue: https://github.com/smore-inc/smore/issues/6198
  const vimeoRegexGroups = block.url?.match(VIMEO_REGEX);
  if (vimeoRegexGroups && vimeoRegexGroups.length === 3) {
    hash = vimeoRegexGroups[2];
    hash = hash.slice(1, hash.length);
  }
  const videoUrl = `https://player.vimeo.com/video/${block.movie}${hash ? "?h=" + hash : ""}`;

  const cta = block.movie
    ? {
        url: videoUrl,
        id: `v-${block._id}`
      }
    : undefined;

  const { ctx } = getNewsletterStores();
  const blockId = getBlockId();
  const [target, _ignore] = trackingAndAttributesFromCTA(ctx, cta, { type: "video", text: block.title, blockId });

  const srcDoc = `<style>
                *{padding:0;margin:0;overflow:hidden;}
                html,body{height:100%;}
                img{position:absolute;width:100%;top:0;bottom:0;margin:auto;}
                span{height:1.5em;color:white;}
                .caption{white-space:nowrap;color:white;z-index:1;padding:0.25rem 0.5rem;background:linear-gradient(#000000A0, #00000050);display:block;font: 400 16px/1.5 Roboto;color: #fff;text-decoration:none;}
              </style>
              <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700" rel="stylesheet" class="skip-tracking">
              <a rel="nofollow noopener noreferrer" class="skip-tracking" href="${target}">
                <span class="caption" style="text-overflow:ellipsis;position:absolute;top:0;left:0;right:0;">${block.title}</span>
                <span class="caption" style="position:absolute;bottom:0;right:0;font-size:0.75rem;flex-shrink:0;border-top-left-radius:0.25rem;">${block.target}</span>
                <img style="height:360px;object-fit:contain" src="${block.thumbnail_url}" alt="${block.title}" />
                <img src="${Base64PlaySVGImageData}" width="700" alt="Click to play: ${block.title}" style="display:block !important; max-width:700px;width:100%;height:240px;" />
              </a>`;
</script>

<div class="content no-drag no-screenshot widget-video-vimeo-new">
  <div class="framed-image-container no-drag" class:p-2={isEmpty}>
    <div class="video-box">
      {#if isEmpty}
        <div class="absolute inset-0 flex items-center justify-center text-gray-600 bg-gray-100 border-2 border-dashed rounded-lg">
          Youtube video
        </div>
      {:else}
        <iframe
          title={block.title}
          loading="lazy"
          data-title={block.title}
          srcdoc={`${srcDoc}`}
          src={videoUrl}
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          height="350"
          width="100%" />
      {/if}
    </div>
  </div>
</div>

{#if !isEditor()}
  <div class="content only-screenshot only-drag widget-video-vimeo-new">
    <div class="framed-image-container">
      <div class="video-box">
        <div class="video-thumbnail" style={`background: url('${block.thumbnail_url}')  center / cover no-repeat`}>
          <div class="vimeo-overlay" />
          {#if block.title}
            <div class="video-description">{block.title}</div>
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .widget-video-vimeo-new .framed-image-container {
    position: relative;
    height: 350px;
  }

  .widget-video-vimeo-new iframe,
  .widget-video-vimeo-new .video-box {
    height: auto;
    display: block;
    width: 100%;
    height: 100%;
  }

  .widget-video-vimeo-new.only-screenshot .video-box {
    overflow: hidden;
    background: black;
  }
  .widget-video-vimeo-new.only-screenshot img {
    width: 100%;
  }
  .widget-video-vimeo-new.only-screenshot .video-thumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: contain;
  }

  .widget-video-vimeo-new.only-screenshot .video-description {
    width: 100%;
    height: 33px;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    font-family: Verdana, arial, sans-serif;
    font-size: 16px;
    padding: 10px 0 0 12px;
  }

  .widget-video-vimeo-new.only-screenshot .vimeo-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(/images/emails/vimeo_overlay.png) no-repeat center center;
    top: 0;
    left: 0;
  }
</style>
