<script lang="ts">
  import { RunningText, loadGlyphsForText } from "../../svgkit";
  import type { FontDef } from "../../svgkit/fonts/types";
  import { getNewsletterStores } from "../../newsletterStores";

  export let font: FontDef;
  export let color: string = "#000000";
  export let size: number = 24;
  export let text: string = "";
  export let align: "left" | "right" | "center" = "left";
  export let rtl: boolean;
  export let lineHeight: number = 0;
  export let letterSpacing: number = 0;
  export let spaceTracking: number = 0;

  function isSpace(w: string) {
    return !w.replace(/\s/giu, "").trim();
  }

  const { design, ctx } = getNewsletterStores();
</script>

<div {...$$restProps} style="font-size:{size}px;text-align:{align};line-height:1;" dir={rtl ? "rtl" : "ltr"}>
  {#each RunningText( { font, size, children: [text], color, lineHeight, letterSpacing, spaceTracking, rtl } ) as { svg, text, width, height }}<img
      src={ctx.svg(svg)}
      alt={text}
      {width}
      {height}
      style="display:inline" />{/each}
</div>
