/** @jsx h */

import { Stage, Flex, Text, h } from "../comps";
import type { HeaderProps } from "./types";
import { directifyPadding } from "./utils";

export function handwritten(props: HeaderProps) {
  const { sub, title, spacing, height, rtl } = props;

  return (
    <Stage width={700} height={height || 280}>
      <Flex padding={directifyPadding([58, 48, 58, 48], rtl)} justify="center" align="center" spacing={0 + (spacing || 0)}>
        <Text
          font={title.font}
          color={title.color}
          maxLines={2}
          limits={title.limits}
          align="center"
          fieldKey="title"
          letterSpacing={title.letterSpacing}
          lineHeight={title.lineHeight}
        >
          {title.text}
        </Text>
        {sub.text && (
          <Text
            color={sub.color}
            font={sub.font}
            maxLines={2}
            limits={sub.limits}
            align="center"
            letterSpacing={sub.letterSpacing}
            lineHeight={sub.lineHeight}
            fieldKey="subtitle"
          >
            {sub.text}
          </Text>
        )}
      </Flex>
    </Stage>
  ).toSVG();
}
