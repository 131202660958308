<script lang="ts">
  export let align: "center" | "left" | "right" = "left";
  export let rtl: boolean = false;

  function toggleAlign() {
    align = align === "center" ? "left" : "center";
  }

  $: alignIcon = `format_align_${rtl && align === "left" ? "right" : align}`;
</script>

<button
  type="button"
  class="block p-1 text-gray-600 border rounded material-icons text-14 hover:bg-gray-100 sm-clickable"
  on:mousedown|preventDefault|stopPropagation={toggleAlign}>{alignIcon}</button>
