<script lang="ts" context="module">
  type MailingList = {
    id: string;
    name: string;
  };
</script>

<script lang="ts">
  import type { schema } from "pages/editor/schema";
  import { field } from "./store";
  import { onMount } from "svelte";
  import { getJson } from "@ui/http";
  import { fly } from "svelte/transition";

  export let draft: schema.SignatureBlock;
  export let error: string | undefined = undefined;

  let mailingLists: MailingList[];
  let mailingListSelect: HTMLSelectElement;

  const { id: subscribeButtonId, input: subscribeButton } = field<boolean | undefined>(
    draft.subscribeButton,
    (c) => ([draft.subscribeButton, error] = c),
    {
      validate: [validate()]
    }
  );
  const { id: subscribeListId, input: subscribeList } = field<string | undefined>(
    draft.subscribeList,
    (c) => ([draft.subscribeList, error] = c)
  );

  $subscribeButton = draft.subscribeButton;
  $subscribeList = draft.subscribeList;

  onMount(async () => {
    const mailingListResponse = await getJson<{ lists: MailingList[] }>("/app/lists/get_lists_json");
    mailingLists = [...mailingListResponse.lists];
  });

  function mailingListSelectionChanged() {
    $subscribeList = mailingListSelect.selectedOptions[0].value;
    // This is a workaround to refresh the validate() method
    $subscribeButton = false;
    $subscribeButton = true;
  }

  function validate() {
    return () => {
      if ($subscribeButton && !$subscribeList) {
        return "List is required";
      }
    };
  }
</script>

<div class="flex items-center justify-between">
  <label class="flex items-center flex-shrink-0 mt-2 ml-1 cursor-pointer text-nxgray-600 text-12">
    <input id={subscribeButtonId} type="checkbox" bind:checked={$subscribeButton} class="mr-1.5" />Subscribe button
  </label>
  {#if error}
    <span class="text-xs text-red-600" transition:fly|local={{ duration: 150, y: 10 }}>{error}</span>
  {/if}
</div>
<span class="text-xs text-nxgray-400 sm:ml-6">Adds a "Subscribe" button so visitors can subscribe</span>

{#if draft.subscribeButton && mailingLists}
  <select class="w-full px-2 py-1 mt-2 text-xs border rounded" on:input={mailingListSelectionChanged} bind:this={mailingListSelect}>
    <option value="-1" disabled selected={$subscribeList ? false : true}>Select a list</option>
    {#each mailingLists as mailingList}
      <option value={mailingList.id} selected={$subscribeList === mailingList.id}>{mailingList.name}</option>
    {/each}
  </select>
{/if}
