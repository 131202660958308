<script lang="ts" context="module">
  import Facebook from "./facebook.svelte";
  import Instagram from "./instagram.svelte";
  import Pinterest from "./pinterest.svelte";
  import Tiktok from "./tiktok.svelte";
  import Twitter from "./twitter.svelte";
  import Youtube from "./youtube.svelte";

  const SOCIAL_ICONS: Record<SocialType, any> = {
    facebook: Facebook,
    instagram: Instagram,
    pinterest: Pinterest,
    tiktok: Tiktok,
    twitter: Twitter,
    youtube: Youtube
  };
</script>

<script lang="ts">
  import LinkContent from "../../utils/LinkContent.svelte";
  import { handleURL } from "./utils";
  import { getRenderContext } from "../../../newsletterStores";

  import type { SocialType } from "./utils";

  const ctx = getRenderContext();

  export let handle: string | undefined = "";
  export let type: SocialType;
  export let color: string = "#fff";
</script>

{#if handle}
  <LinkContent cta={{ id: `footer.${type}`, url: handleURL(handle, type) }} linkText="{type} button" type="signature">
    <div class="ada-text-white" style="color:{color};width:15px; height:14px" title="Signature {type}">
      <svelte:component this={SOCIAL_ICONS[type]} />
    </div>
  </LinkContent>
{/if}
