import type { schema } from "@editor/schema";
import { currentUserId, currentDeviceId } from "@ui/currentUser";

export function canLock(item: schema.Lockable | undefined): item is schema.Lockable {
  return (!!item && !item._li) || !!isLocking(item);
}

export function lockInfo(kind: "preemptive" | "edit" | "insert"): schema.LockInfo {
  return {
    byId: currentUserId!,
    deviceId: currentDeviceId!,
    kind: kind,
    since: +new Date()
  };
}

export function isEditing(item: schema.Lockable) {
  return item._li && item._li?.byId === currentUserId && item._li?.deviceId == currentDeviceId && item._li?.kind === "edit";
}

export function isLocking(item: schema.Lockable | undefined) {
  return item && item._li && item._li?.byId === currentUserId && item._li?.deviceId == currentDeviceId;
}

export function isLockedBySomeoneElse(item: schema.Lockable | undefined) {
  return item && item._li && !isLocking(item);
}