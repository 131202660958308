<script lang="ts">
  import type { schema } from "@editor/schema";
  import BlockEditor from "./BlockEditor.svelte";

  import EmbedField from "./form/EmbedField.svelte";

  import cloneDeep from "lodash.clonedeep";

  export let focusField: string = "embed";

  export let block: schema.EmbedLinkBlock;
  let draft: schema.EmbedLinkBlock = cloneDeep(block);

  let b = cloneDeep(block);

  $: if (b) {
    draft = { ...b, _id: draft._id, _lu: draft._lu };
  }
</script>

<BlockEditor {draft} on:done on:cancel>
  <EmbedField label="Url" required autofocus type="form" bind:value={b} previewPlaceholder="Enter/Paste a Google forms URL to embed it." />
</BlockEditor>
