<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./EmailBlock.svelte";
  import { backgroundAssets, getNewsletterStores } from "../../newsletterStores";
  export let block: schema.VideoBlock;
  import { LinkContent } from "../utils";

  const { design, content, ctx } = getNewsletterStores();

  const playSVG = `<svg viewBox="0 0 640 360" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M396.5 189.026L289.25 250.947C281.917 255.181 272.75 249.889 272.75 241.421L272.75 117.579C272.75 109.111 281.917 103.819 289.25 108.053L396.5 169.974C403.833 174.208 403.833 184.792 396.5 189.026Z" fill="white" fill-opacity="0.8" stroke="#707070" stroke-width="6"/></svg>`;

  const thumbnailImage = ctx.svg(playSVG, {
    background: {
      url: block.thumbnail_url,
      color: "#000001"
    }
  });

  const cta = block.url
    ? {
        url: block.url!,
        id: `v-${block._id}`
      }
    : undefined;
</script>

<Block
  {block}
  let:layout
  size={block._l === "side-l" ? "box" : "full"}
  bgsize={block._l === "side-l" ? "cover" : "contain"}
  bgcolor={block._l === "side-l" ? "" : "#000"}>
  <LinkContent {cta} type="video" linkText={block.title} style="cursor:pointer !important">
    {#if layout === "side-l"}
      <table height={layout === "side-l" ? 128 : 350} width="100%">
        {#if layout === "side-l"}
          <tr>
            <td
              valign="center"
              align="center"
              height="100%"
              width="33%"
              style="background-size:contain;background-repeat:no-repeat;background-position:center;"
              background={block.thumbnail_url}>
              <span style="font-size:32px; color:#fff;text-decoration:none;">&#9658;</span>
            </td>
            <td width="10" />
            <td valign="top" align="left" height="100%">
              <table width="100%" height="100%">
                <tr
                  ><td
                    height="100%"
                    valign="top"
                    style="font-weight: 700;font-size: 1.125rem;color:{$design.background.dark
                      ? '#fff'
                      : $design.color['text.title'].text}">
                    {block.title || ""}</td
                  ></tr>
                <tr
                  ><td style="font-size: 0.9375rem;color:{$design.background.dark ? '#fff' : $design.color['global.text']}">
                    {block.target}.com
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        {:else}
          <tr>
            <td valign="top" align="left" height="20" style="background:linear-gradient(#000000A0, #00000050);">
              <h6 style="margin:10px;font: bold 16px/1.5 sans-serif;color: #fff;text-decoration:none">{block.title}</h6>
            </td>
          </tr>
          <tr>
            <td align="center"><span style="font-size:128px; color:#fff;text-decoration:none;">&#9658;</span><br /><br /><br /></td>
          </tr>
        {/if}
      </table>
    {:else}
      <img
        src={thumbnailImage}
        width="700"
        alt="Click to play: {block.title}"
        style="display:block !important; max-width:700px;width:100%" />
    {/if}
  </LinkContent>
</Block>
