import { postJson } from "@ui/http";
import { migrateBlockToCurrent } from "@editor/schema/migrations";
import { coerceUri } from "./validations";

export async function embedBlockFromUrl(url: string) {
  // make sure we have http/s etc.
  url = coerceUri(url);

  // get the results from our server
  // IMPORTANT: this returns a block in the schema.version_0 format, so we have to convert it
  // to version 1
  const result = await postJson<any>(`/app/embed/embed_link_json`, {
    url
  });

  return migrateBlockToCurrent(result);
}
