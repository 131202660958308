import { Readable, writable } from "svelte/store";
import { ServerError } from "@ui/http";
import { on } from "./dom";

export type UploadOptions = {};

type UploadData<TResult = any> = {
  status: "pending" | "uploading" | "processing" | "done" | "error" | "retrying" | "aborted";
  progress: number;
  error?: Error;
  result?: TResult;
};

export type Upload<TResult> = Readable<UploadData<TResult>> & {
  abort(): void;
  done(): Promise<TResult>;
  retry(): void;
};

export function upload<TResult = any>(url: string, body: XMLHttpRequestBodyInit, options?: UploadOptions): Upload<TResult> {
  const w = writable<UploadData>({ progress: 0, status: "pending" });

  function done(this: XMLHttpRequest) {
    if (this.status !== 200) {
      try {
        const json = JSON.parse(this.responseText);
        if (json.errorCode || json.errorMessage) {
          w.set({
            status: "error",
            progress: 0,
            error: new ServerError(json.errorCode, json.errorMessage || json.errorCode, json.extraData)
          });
          return;
        }
      } catch {
        // We have nothing to do here, just wanted to see if we got json as a response
      }
      w.set({ status: "error", progress: 0, error: new Error(this.responseText) });
    } else {
      const result = this.getResponseHeader("content-type")?.includes("application/json") ? JSON.parse(this.responseText) : this.response;
      w.set({ status: "done", progress: 100, result });
    }
  }

  function start() {
    const xhr = new XMLHttpRequest();

    const loadstart = () => w.set({ status: "uploading", progress: 0 });
    const progress = (e: ProgressEvent) => w.set({ status: "uploading", progress: Math.round(e.total / e.loaded) * 100 });
    const abort = () => w.set({ status: "aborted", progress: 0 });
    const error = () => w.set({ status: "error", progress: 0 });
    const uploadDone = () => w.set({ status: "processing", progress: 100 });
    const timeout = () => w.set({ status: "error", progress: 0 });

    on(xhr.upload, { loadstart, progress, abort, error, load: uploadDone, timeout });
    on(xhr, { load: done, error, abort });

    xhr.open("POST", url);
    xhr.send(body);

    return xhr;
  }

  let xhr = start();

  return {
    subscribe: w.subscribe,
    abort() {
      xhr.abort();
    },
    done() {
      return new Promise((resolve, reject) => {
        w.subscribe((v) => {
          if (v.status === "done") {
            return resolve(v.result);
          }
          if (v.status === "aborted" || v.status === "error") {
            return reject(v.error);
          }
        });
      });
    },
    retry() {
      start();
    }
  };
}

export type UploadQueue = Readable<{
  pending: number;
  completed: number;
  progress: number;
}> & {
  queue<TResult = any>(body: XMLHttpRequestBodyInit, options?: UploadOptions): Upload<TResult>;
  abort(): void;
  done(): Promise<void>;
};

export function uploadQueue(url: string, options?: UploadOptions): UploadQueue {
  // TODO
  return {} as UploadQueue;
}
