// Import the editor default configuration

import { css } from "sbelt/dom";
import { randId } from "sbelt/id";
import { waitForLoad } from "sbelt/promise";
import { createDefaultImageReader, getEditorDefaults, openEditor } from "./pintura";
import type { PinturaEditorOptions } from "./pintura";

import PinturaEditor from "./svelte-pintura/PinturaEditor.svelte";
import PinturaEditorModal from "./svelte-pintura/PinturaEditorModal.svelte";
import PinturaEditorOverlay from "./svelte-pintura/PinturaEditorOverlay.svelte";

export type { PinturaEditorOptions };
export { PinturaEditor, getEditorDefaults, PinturaEditorModal, PinturaEditorOverlay };

export async function editPhotoModal(
  url: string,
  state: any,
  options?: Partial<PinturaEditorOptions>
): Promise<{ file: Blob; state: any } | undefined> {
  url = url + `?x-amz-edit=${randId("pint")}`;
  const d = { ...getEditorDefaults(), ...options };
  const cleanup = css(`
  .pintura-editor {
    --editor-max-width: 80vw;
    --editor-max-height: 80vh;
    --editor-modal-border-radius: 4px;
    --editor-modal-overlay-opacity: 0.7;
  }
  `);

  return new Promise((resolve, reject) => {
    // @ts-ignore
    const e = openEditor({
      ...d,
      imageState: state,
      src: url,
      handleEvent(type, content) {
        switch (type) {
          case "process":
            const { dest, imageState } = content;
            cleanup();
            resolve({ file: dest, state: imageState });
            return;
          case "close":
            cleanup();
            resolve(undefined);
            return;
          case "loadabort":
            e.close();
            cleanup();
            return;
        }
      }
    });
  });
}
