<script lang="ts">
  import BlockEditor from "./BlockEditor.svelte";
  import type { schema } from "@editor/schema";

  import TextField from "./form/TextField.svelte";
  import CTAField from "./form/CTAField.svelte";
  import AlignButton from "./form/AlignButton.svelte";
  import CollapsibleSection from "@ui/CollapsibleSection.svelte";
  import cloneDeep from "lodash.clonedeep";
  import EmojiPickerButton from "./form/EmojiPickerButton.svelte";

  export let block: schema.TitleBlock;
  let draft: schema.TitleBlock = cloneDeep(block);

  let textField: TextField;
</script>

<BlockEditor {draft} on:done on:cancel>
  <TextField
    label="Title"
    required
    autofocus
    limit={80}
    bind:value={draft.title}
    placeholder={block._defaults?.title || "Enter a title"}
    align={draft.align}
    bind:this={textField}>
    <div slot="afterInput" class="flex items-center gap-1 pl-1" let:rtl>
      <AlignButton bind:align={draft.align} {rtl} />
      <EmojiPickerButton on:selected={(e) => textField.insertTextAtCursor(e.detail)} />
    </div>
  </TextField>
  <CollapsibleSection title="Make this title linkable" captionClasses="mt-2" icon="link" startOpen={!!block.cta} autofocus>
    <div class="mt-3">
      <CTAField label="Target url" bind:value={draft.cta} placeholder="Enter a URL for the title target (like https://www.google.com)" />
    </div>
  </CollapsibleSection>
</BlockEditor>
