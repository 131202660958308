<script lang="ts">
  import type { schema } from "@editor/schema";
  import { getNewsletterStores } from "../newsletterStores";
  import { isEmpty as isEmptyTest } from "./blocksConfig";
  import { coerceUri } from "./form/validations";
  import { ctaToHref, LinkContent } from "./utils";

  export let block: schema.LogoBlock;

  export let size: number = block.size || 70;
  export let layout: string = block._l;
  $: isEmpty = isEmptyTest(block);
  const { ctx } = getNewsletterStores();
</script>

{#if !isEmpty && block.picture && block.picture.legacy_thumb_url}
  <div
    class="flex items-center py-3"
    class:justify-start={layout === "side-l"}
    class:justify-end={layout === "side-r"}
    class:justify-center={layout === "-"}>
    <LinkContent cta={block.picture.cta} type="logo" linkText={block.picture.alt_text || "Newsletter Logo"} class="newsletter-logo">
      <img class="object-contain" style="max-width: 400px; max-height: {size}px;" src={block.picture.legacy_thumb_url} alt={"Logo"} />
    </LinkContent>
  </div>
{/if}
