<script lang="ts">
  import { onMount } from "svelte";
  import Field from "./Field.svelte";

  import CharLimit from "./CharLimit.svelte";

  import { field } from "./store";
  import { lengthTest, patternTest, requiredTest } from "./validations";

  export let value: string = "";
  export let label: string | undefined;
  export let required: boolean = false;
  export let icon: string | undefined = undefined;
  export let autocomplete: string = "off";
  export let autofocus: boolean = false;
  export let error: string | undefined = undefined;
  export let placeholder: string = "";
  export let disabled: boolean = false;
  export let limit: number | undefined = undefined;
  export let pattern: string | undefined = undefined;
  export let size: "sm" | "md" | "lg" = "md";
  export let minHeight: number = 40;
  export let maxHeight: number = 150;

  let el: HTMLTextAreaElement;

  let isFocused: boolean = false;
  const { id, input } = field<string>(value, (c) => ([value, error] = c), {
    validate: [required && requiredTest(), lengthTest(limit), patternTest(pattern)]
  });
  // update from outside
  $: $input = value;

  if (autofocus) {
    onMount(() => el && el.select());
  }

  // resize on mount
  onMount(resize);
  let height = minHeight;

  function resize() {
    if (!el) {
      return;
    }
    el.style.height = "auto";
    height = el.scrollHeight;
  }
</script>

<Field {label} {id} {required} {error} {value} {icon} {size} {isFocused} --max-height="{maxHeight}px" let:scrollTop>
  <textarea
    style="height: {height}px"
    bind:value={$input}
    bind:this={el}
    on:focus={() => (isFocused = true)}
    on:blur={() => (isFocused = false)}
    on:input={resize}
    {required}
    {autocomplete}
    {placeholder}
    {id}
    {disabled}
    {autofocus}
    class="block flex-grow mx-1 {size} outline-none resize-none	"
    class:text-12={size === "sm"}
    class:text-13={size === "md"}
    class:text-16={size === "lg"}
    class:small-input={size === "sm"}
    class:leading-none={size === "lg"} />

  {#if isFocused}
    <div class="absolute pointer-events-none top-1 right-1" style="top:{4 + scrollTop}px">
      <CharLimit {value} {limit} />
    </div>
  {/if}
</Field>
