<script>
  import { getContext, createEventDispatcher } from "svelte";
  import { contextKey } from "./lib/context.js";

  export let continueText;

  const dispatch = createEventDispatcher();

  const { config, component, isDateChosen } = getContext(contextKey);

  function finalise() {
    isDateChosen.set(true);
    dispatch("close");
  }

  function progress() {
    isDateChosen.set(false);
    if ($component === "date-view") {
      if (config.isTimePicker) {
        component.set("time-view");
      } else {
        finalise();
      }
    } else if ($component === "time-view") {
      finalise();
    }
  }
  function cancel() {
    dispatch("cancel");
  }
</script>

<div class="flex items-center justify-between px-4 py-2 border-t">
  <button
    type="button"
    class="cursor-pointer text-13 text-nxgray-400 clickable scale-in hover:text-red-600"
    on:click|preventDefault={cancel}>Cancel</button>
  <button
    type="button"
    class="flex px-3 py-1 font-medium text-white transition-colors bg-green-600 rounded shadow text-14"
    on:click|preventDefault={progress}>
    {continueText}
  </button>
</div>
