<script lang="ts">
  import type { schema } from "@editor/schema";
  import { isLockedBySomeoneElse } from "@editor/api";
  import { fade } from "svelte/transition";
  import { getNewsletterStores } from "../newsletterStores";
  import Avatar from "@ui/Avatar.svelte";
  import { getCurrentUser } from "@ui/currentUser";

  const { editors, api } = getNewsletterStores();

  export let id: string;
  export let item: schema.Lockable;
  export let rounded: boolean = false;

  const currentUser = getCurrentUser();

  let isTakingOver = false;

  $: editor = item._li?.byId ? $editors[item._li?.byId] : undefined;
  $: first = (editor?.name || "").split(/\s+/g)[0];
  $: isMeEditingInAnotherSession = editor && editor.id === $currentUser.id;

  async function takeOver() {
    if (!confirm("Are you sure you want to take over?")) {
      return;
    }
    isTakingOver = true;
    try {
      await api.takeOver(id, "user_request");
    } catch (e) {
      alert("Something went wrong :(");
    }
    isTakingOver = false;
  }
</script>

{#if isLockedBySomeoneElse(item) && api.isCollaborative}
  <div
    class:rounded-lg={rounded}
    class="absolute inset-0 z-50 transition-colors bg-white bg-opacity-50 lock-container hover:bg-black hover:bg-opacity-20 group backdrop-filter"
    transition:fade|local={{ duration: 150 }}>
    <!-- Small indication -->
    <div class="absolute transition-all top-3 right-3 group-hover:opacity-0">
      {#if editor}
        <Avatar user={editor} size="sm" />
      {/if}
    </div>

    <!-- Lock details -->
    <div class="absolute inset-0 flex items-center justify-center transition-all opacity-0 group-hover:opacity-100 lock-details-overlay">
      <div class="flex items-center px-2 py-2 bg-white rounded-md shadow lock-details">
        <!-- Photo -->
        {#if editor}
          <Avatar user={editor} size="sm" />

          <span class="ml-2 mr-1 text-gray-700 text-13">
            {#if isMeEditingInAnotherSession}
              <strong>You</strong> are currently editing this in another session
            {:else}
              <strong>{first}</strong> is currently editing this
            {/if}
          </span>
        {/if}

        <button
          class:pointer-events-none={isTakingOver}
          on:click={takeOver}
          class="self-stretch block p-2 px-3 ml-2 -m-2 transition-all bg-gray-100 text-nxgray-600 text-12 rounded-r-md hover:bg-red-600 hover:text-white">
          {isTakingOver ? "Taking over..." : "Take over"}
        </button>
      </div>
    </div>
  </div>
{/if}

<style>
  .lock-container {
    cursor: not-allowed;
  }

  .lock-container:hover {
    --tw-backdrop-blur: blur(4px);
  }
  
  :global(.force-show-lock-details) .lock-details-overlay {
    opacity: 1 !important;
  }
  
  :global(.force-show-lock-details) .lock-details {
    border: 1px solid gray;
  }

</style>
