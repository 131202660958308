<script lang="ts" context="module">
  import type { schema } from "@editor/schema";
  import { getNewsletterStores } from "../newsletterStores";
  import Block from "./Block.svelte";
  import { withDefaults, LinkContent } from "./utils";
</script>

<script lang="ts">
  export let block: schema.ButtonBlock;
  const { design } = getNewsletterStores();

  $: [b, isDefault] = withDefaults(block, { text: "Click to add text" });

  $: textColorFromStyle = (style: schema.BlockStyle) => {
    if (isDefault(b.text)) {
      return "#666";
    }
    if (!style) {
      style = "solid";
    }

    return style == "transparent" ? "#505060" : $design.color.button.text;
  };

  $: backgroundColorFromStyle = (style: schema.BlockStyle) => {
    if (isDefault(b.text)) {
      return "#ccc";
    }
    if (!style) {
      style = "solid";
    }

    return style == "transparent" ? "transparent" : $design.color.button.background;
  };

  $: borderFromStyle = (style: schema.BlockStyle) => {
    if (isDefault(b.text)) {
      return "";
    }
    if (!style) {
      style = "solid";
    }

    return style == "transparent" ? `2px solid ${$design.color.button.background}` : "unset";
  };

  $: borderRadius = (shape: schema.BlockShape) => {
    switch (shape) {
      case "rounded":
        return "71px";
      case "square":
      default:
        return "4px";
    }
  };

  $: width = (layout: schema.BlockLayout) => {
    switch (layout) {
      case "narrow":
        return "fit-content";
      case "wide":
      default:
        return "100%";
    }
  };

  $: marginX = (layout: schema.BlockLayout) => {
    switch (layout) {
      case "narrow":
        return "auto";
      case "wide":
      default:
        return "0px";
    }
  };
</script>

<Block
  {block}
  on:click
  on:delete
  on:duplicate
  let:isEmpty
  showEmpty
  layouts={["wide", "narrow"]}
  let:layout
  styles={["solid", "transparent"]}
  let:style
  shapes={["square", "rounded"]}
  let:shape>
  <div class="w-full text-center">
    <LinkContent
      role="button"
      cta={b.cta}
      showEmpty
      type="button"
      class="clickable ada-bg-black ada-text-white block w-full min-w-60 px-6 py-3 text-lg font-bold shadow-md"
      style="width: {width(layout)}; margin: 0 {marginX(layout)}; color:{textColorFromStyle(
        style
      )}; background-color: {backgroundColorFromStyle(style)}; border: {borderFromStyle(style)}; border-radius: {borderRadius(shape)};"
      data-focus-key="text">
      {b.text}
    </LinkContent>
    {#if b.details}
      <div
        data-focus-key="details"
        class:opacity-50={isDefault(b.details)}
        class:opacity-75={!isDefault(b.details)}
        class="ada-text-black mt-2 text-14 leading-snug"
        style="color:{$design.background.dark ? '#fff' : $design.color['global.text']}">
        {#each b.details.trim().split("\n") as l}{l}<br />{/each}
      </div>
    {/if}
  </div>
</Block>
