<script lang="ts">
  import "@ui/dateMixins";
  import type { schema } from "@editor/schema/index.js";
  import { end, fromDate, start, toStartOfDay } from "./durationUtils";
  import DatePicker from "@ui/RangePicker";
  import { CalendarStyle } from "@ui/RangePicker/calendar-style.js";

  const startOfToday = toStartOfDay(new Date());

  export let value: schema.DateRange = fromDate(startOfToday, 1439);
  let selected = [start(value), end(value) || start(value).addMinutes(1439)];

  const style = new CalendarStyle({
    dayHighlightedBackgroundColor: "#BCE6DE",
    highlightColor: "#00A4BC", // blue-600
    passiveHighlightColor: "#38DCEC", // blue-400
    timeConfirmButtonColor: "#24AC79", // green-600
    toolbarBorderColor: "#ccc" //
  });

  function dateRangeSelected(e: { detail: { from: Date; to: Date } }) {
    selected[0] = e.detail.from;
    selected[1] = e.detail.to;
    selected[0].setHours(0);
    selected[0].setMinutes(0);
    selected[0].setSeconds(0);
    selected[0].setMilliseconds(0);
    selected[1].setHours(23);
    selected[1].setMinutes(59);
    selected[1].setSeconds(59);
    selected[1].setMilliseconds(0);

    const diffInMinutes = Math.ceil((selected[1].getTime() - selected[0].getTime()) / (1000 * 60)) - 1;
    value = fromDate(selected[0], diffInMinutes);
  }

  function canUserSelectThisDate(dateToSelect: Date) {
    return dateToSelect.valueOf() >= startOfToday.valueOf();
  }
</script>

<DatePicker
  range={true}
  on:range-selected={dateRangeSelected}
  {selected}
  styling={style}
  start={start(value).valueOf() > startOfToday.valueOf() ? startOfToday : start(value)}
  continueText="Done"
  selectableCallback={canUserSelectThisDate}>
  <div class="flex items-center text-13">
    <div class="flex items-center justify-between px-2 py-1 bg-white border rounded shadow text-nxgray-600 h-9">
      <span class="mr-1 font-medium">{selected[0].toFormat("{MM} {DD}, {YYYY}")}</span>
      <span>until</span>
      <span class="ml-1 font-medium">{selected[1].toFormat("{MM} {DD}, {YYYY}")}</span>
    </div>
  </div>
</DatePicker>

<style>
  :global(.datepicker .contents-wrapper) {
    /* Ensure datepicker is above add content panel in the right */
    z-index: 100000000 !important;
  }
  :global(.datepicker .toolbar) {
    padding: 1rem 1.25rem !important;
  }
</style>
