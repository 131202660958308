import { getContext, setContext } from "svelte";
import type { Writable } from "svelte/store";

const CONTEXT_KEY = {};

export function createRadioGroupStore(defaultValue: Writable<any>): Writable<any> {
  setContext(CONTEXT_KEY, defaultValue);
  return defaultValue;
}

export function getRadioGroupStore(): Writable<any> {
  const store = getContext(CONTEXT_KEY) as Writable<any>;
  if (!store) throw Error("Group store doesn't exist");
  return store;
}
