<script lang="ts">
  import type { schema } from "@editor/schema";
  import Rich from "../utils/Rich.svelte";
  import Block from "./EmailBlock.svelte";
  import { getNewsletterStores } from "../../newsletterStores";
  import { LinkContent, photoToCTA } from "../utils";
  import IfOutlook from "./helpers/IfOutlook.svelte";
  import EndIfOutlook from "./helpers/EndIfOutlook.svelte";
  import { isRTL } from "sbelt/text";
  import { toText } from "@ui/rich";

  export let block: schema.ParagraphBlock;

  const isTitleRTL = isRTL(block.title);
  const isContentRTL = isRTL(toText(block.content));

  const { design, ctx } = getNewsletterStores();
  const hasPicture = block.picture && block.picture.legacy_thumb_url && block._l !== "-";

  let titleAlignment = block.align;
  if (isContentRTL && titleAlignment !== "center") {
    titleAlignment = block.align === "left" ? "right" : "left";
  }

  // const src = hasPicture ? ctx.img(
  //         [{op: "fetch", url: block.picture!.legacy_thumb_url},
  //         {op: "resize", width: 192*2},
  //         {op: "rounded", radius: 8},
  //         {op: "pad", left: block._l === "side-r" ? 24 : 0, right: block._l === "side-l" ? 24 : 0, background: "#fff"}
  //       ],
  //       "jpg"
  //       ) : ""
</script>

<!-- 
  We're using a crazy freaky technic here to get "inline" media queries AND support stupid outlook. 

  Why can't we just use regular, sane, simple and easy media queries you may ask yourself? 
  Well because: 
  - BB (and problably other MNS are ultra lame, and put our CSS styels IN THE FUCKING BODY of the email)
  - THEN gmail, being the little cute thing that it is, IGNORES any style tag that's not inside the header
  
  So, we had to innovate and be awesome, and this is what we got, which is damn impressive IMHO. 

  learn more here: 
  https://www.freecodecamp.org/news/the-fab-four-technique-to-create-responsive-emails-without-media-queries-baf11fdfa848/

  @shlomiatar, Nov 8th, 2021
  
 -->

<Block {block} let:layout>
  {#if layout === "side-l"}
    <IfOutlook />
    <table width="660" cellpadding="0" cellspacing="10" role="presentation" align="center" style="width:660px;">
      <tr>
        <EndIfOutlook />
        {#if hasPicture && block.picture}
          <IfOutlook />
          <td valign="top" align="left">
            <EndIfOutlook />
            <table
              align="left"
              cellspacing="0"
              cellpadding="0"
              border={0}
              width="202"
              style="margin-right:1rem !important;min-width:202px;max-width:100%;border-radius:4px;width: calc((580px - 100%)*1000);">
              <tr>
                <td valign="top" style="min-width:192px;max-width:100%;border-radius:4px;width: calc((580px - 100%)*1000);">
                  <LinkContent cta={photoToCTA(block.picture)} type="paragraph-image" linkText={block.picture.alt_text} class="m-if">
                    <img
                      src={block.picture.legacy_thumb_url}
                      alt={block.picture.alt_text}
                      width="192"
                      style="display:inline-block;min-width:192px;max-width:100%;border-radius: 4px;float:left;width: calc((580px - 100%)*1000);" />
                  </LinkContent>
                </td>
                <IfOutlook />
                <td width="16">&nbsp;</td>
                <EndIfOutlook />
              </tr>
              <IfOutlook />
              <tr><td height="4" /></tr>
              <EndIfOutlook />
            </table>
            <IfOutlook />
          </td>
        {/if}

        <IfOutlook />
        <td valign="top">
          <EndIfOutlook />
          <!-- Title -->
          {#if block.title}
            <h5
              dir={isTitleRTL ? "rtl" : "ltr"}
              style="font:bold 18px/1.2 sans-serif ;margin:0 0 10px 0 !important;color:{$design.background.dark
                ? '#fff'
                : $design.color['global.text']};display:block;text-align:{titleAlignment}">
              {block.title}
            </h5>
          {/if}

          {#if block.content}
            <Rich content={block.content} inline={{ fontSize: 15 }} context={{ type: "paragraph" }} />
            <div style="clear:both;" />
          {/if}
          <IfOutlook />
        </td>
        <EndIfOutlook />
        <IfOutlook />
      </tr>
    </table>
    <EndIfOutlook />
  {:else}
    {#if hasPicture && block.picture}
      <table
        align="right"
        cellspacing="0"
        cellpadding="0"
        border={0}
        width="202"
        style="margin-left:16px !important;min-width:202px;max-width:100%;border-radius:4px;width: calc((580px - 100%)*1000);">
        <tr>
          <IfOutlook />
          <td width="16" style="width:1rem;" />
          <EndIfOutlook />
          <td valign="top" align="left" style="min-width:192px;max-width:100%;border-radius:4px;width: calc((580px - 100%)*1000);">
            <LinkContent cta={photoToCTA(block.picture)} type="paragraph-image" linkText={block.picture.alt_text} class="m-if">
              <img
                src={block.picture.legacy_thumb_url}
                alt={block.picture.alt_text}
                width="192"
                style="display:inline-block;min-width:192px;max-width:100%;border-radius: 4px;float:{layout === 'side-r'
                  ? 'right'
                  : 'left'};width: calc((580px - 100%)*1000);" />
            </LinkContent>
          </td>
        </tr>
        <IfOutlook />
        <tr><td height="4" colspan="2" /></tr>
        <EndIfOutlook />
      </table>
    {/if}
    <!-- Title -->
    {#if block.title}
      <h3
        dir={isTitleRTL ? "rtl" : "ltr"}
        style="font-size:1.125rem;font-weight: 700;line-height: 1.25;font-family:sans-serif;margin:0 0 8px 0 !important;color:{$design
          .background.dark
          ? '#fff'
          : $design.color['global.text']};display:block;text-align:{titleAlignment}">
        {block.title}
      </h3>
    {/if}

    {#if block.content}
      <Rich content={block.content} inline={{ fontSize: 15 }} context={{ type: "paragraph" }} />
      <div style="clear:both;">
        <IfOutlook />
        &nbsp;
        <EndIfOutlook />
      </div>
    {/if}
  {/if}
</Block>
