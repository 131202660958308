<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./EmailBlock.svelte";
  import { getNewsletterStores } from "../../newsletterStores";
  import { LinkContent } from "../utils";
  import { isRTL } from "sbelt/text";
  import SvgRunningTextEmail from "./SVGRunningTextEmail.svelte";
  export let block: schema.TitleBlock;

  const { design, ctx } = getNewsletterStores();

  $: fontPack = $design!.fontPack["text.title"];
  $: color = $design!.color["text.title"].text;
  $: rtl = isRTL(block.title);
  $: align = rtl && block.align == "left" ? "right" : block.align;

  function defaultSize(layout: string) {
    switch (layout) {
      case "sm":
        return 20;
      case "md":
        return 28;
      case "lg":
        return 36;
    }
  }
</script>

<Block {block} let:layout>
  <LinkContent cta={block.cta} linkText={block.title}>
    <h3 style="margin:0 0 0 0">
      <SvgRunningTextEmail
        font={fontPack.customFont}
        text={block.title}
        {color}
        {align}
        {rtl}
        size={fontPack[`${layout}Size`] || defaultSize(layout)}
        letterSpacing={fontPack.letterSpacing}
        spaceTracking={fontPack.spaceTracking}
        lineHeight={fontPack.lineHeight} />
    </h3>
  </LinkContent>
</Block>
