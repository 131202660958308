<script lang="ts">
  import BlockEditor from "./BlockEditor.svelte";
  import type { schema } from "@editor/schema";
  import ItemEditor from "./items/ItemEditor.svelte";

  export let block: schema.ItemsBlock;
  export let focusField: string = "content-0";

  import cloneDeep from "lodash.clonedeep";
  import { toArray } from "./utils";
  let draft: schema.ItemsBlock = cloneDeep(block);

  function validate(newDraft: schema.ItemsBlock) {
    if (!toArray(newDraft.items || []).some((i) => i)) {
      return ["At least one image required"];
    }
  }
</script>

<div class="bg-gray-50">
  <BlockEditor {draft} on:done on:cancel {validate} let:logged_out>
    {#each [0, 1, 2] as item, idx}
      <div class="flex flex-col items-start justify-start w-full">
        <div class:pt-2={idx > 0} class:border-t={idx > 0} class="flex w-full pb-2">
          <ItemEditor
            on:logged_out={logged_out}
            bind:item={draft.items[idx]}
            handoffId="side-photo-{block._id}-{idx}"
            defaults={block._defaults?.items[idx]}
            {focusField}
            {idx} />
        </div>
      </div>
    {/each}
  </BlockEditor>
</div>
