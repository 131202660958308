<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./Block.svelte";
  import GoogleMap from "./utils/GoogleMap.svelte";
  import { getNewsletterStores } from "../newsletterStores";
  import { DateTimeTZUtils } from "./event/DateTimeTZUtils";
  import Rich from "./utils/Rich.svelte";
  import { isEditor, LinkContent } from "./utils";

  const { design, id } = getNewsletterStores();

  export let block: schema.EventBlock;

  $: view_block = block;

  $: color = $design!.color.event.text;
  $: background = $design!.color.event.background;
</script>

<Block {block} on:click on:delete on:duplicate let:isEmpty showEmpty size={"full"} layouts={["-"]}>
  <div class="transition-all">
    <div class="overflow-hidden transition-all ada-bg-black" style="background-color: {background}">
      <div class="flex justify-between w-full" style="color:{color}">
        <div class="h-full p-5 flex-grow-2">
          <div class:hidden={!view_block.name}>
            <div class="text-14">Event Information</div>
            <h1 class="font-bold text-16" style="color:{color}">{view_block.name}</h1>
          </div>
          <p class="my-1 text-15" style="color:{color}" class:hidden={!view_block.details}>
            {#if view_block.details}
              <Rich content={view_block.details} --link-color={color} context={{ type: "event" }} />
            {/if}
          </p>

          <div class="mb-2">
            <div class="text-14">When?</div>
            <h1 class="font-bold text-16 ada-text-white" style="color:{color}">
              {DateTimeTZUtils.format(view_block.date)}
            </h1>
          </div>

          <div class="mb-2" class:hidden={!view_block.location || view_block.onlineEvent}>
            <div class="text-14">Where?</div>
            <h1 class="font-bold text-16 ada-text-white" style="color:{color}">{view_block.location}</h1>
          </div>

          <!-- <div class="mt-5">
            <button class="flex items-center p-2 px-3 font-medium text-gray-700 bg-white rounded shadow clickable text-15">
              <span class="mr-2 material-icons">event</span>Add to calendar
            </button>
          </div> -->
        </div>

        {#if view_block.location && !view_block.hideMapFromEvent && !view_block.onlineEvent}
          <div class="relative flex-shrink-0 w-1/3">
            <div class="absolute bottom-0 right-0 z-20 m-5">
              <LinkContent
                type="event"
                linkText="Map: ${block.name}"
                cta={{
                  url: "https://maps.google.com/maps?daddr=" + encodeURIComponent(block.location || "") + "&hl=en",
                  id: `${block._id}-map`
                }}
                class="flex items-center flex-grow p-2 px-3 font-medium text-gray-700 truncate bg-white rounded shadow outline-none clickable text-15 focus:outline-none">
                <span class="mr-1 -ml-1 material-icons notranslate">location_on</span>
                Open maps
              </LinkContent>
            </div>
            <div class="z-0 h-full opacity-75">
              <GoogleMap address={view_block.location} width={300} height={600} staticMapUrl={view_block.staticMapUrl} />
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>

  {#if block.collectRSVP}
    <div class="p-2 border-b border-gray-200 event-rsvp-container bg-gray-50">
      {#if !isEditor()}
        <form class="flex flex-col items-center w-full new-event-rsvp-form sm:flex-row" data-event-id={block._id} data-page-id={id}>
          <div class="flex items-center w-full">
            <input
              name="first_name"
              class="w-full p-1 mr-1 border rounded text-13 text form-field first-name"
              type="text"
              required
              placeholder="First Name"
              data-required="true" />
            <input
              name="last_name"
              class="w-full p-1 mr-1 border rounded text-13 text form-field last-name"
              type="text"
              required
              placeholder="Last Name"
              data-required="true" />
          </div>
          <div class="flex w-full mt-2 sm:mt-0">
            <input
              name="email"
              class="flex-grow p-1 mr-1 border rounded text-13 text form-field email-address"
              type="email"
              required
              placeholder="Email Address"
              data-required="true" />
            <input type="submit" class="block p-1 px-2 font-bold text-white bg-green-600 rounded clickable text-13" value="Attend (RSVP)" />
          </div>
        </form>
      {/if}
      <div class="flex items-center event-author-info text-12" class:hidden={!isEditor()}>
        <i class="mr-1 text-green-600 material-icons notranslate text-15 -mt-0.5">check_box</i>
        <p>RSVPs are enabled for this event.</p>
      </div>
    </div>
  {/if}
</Block>

<style>
  :global(.author-show .new-event-rsvp-form) {
    display: none !important;
  }

  :global(.author-show .event-author-info) {
    display: flex !important;
  }
</style>
