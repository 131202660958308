<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let id = "";
  export let text = "";
  export let checked = false;
  export let disabled = false;
  export let size: "medium" | "small" = "medium";
  export let align: "center" | "start" = "center";

  const dispatch = createEventDispatcher();

  function onChange() {
    dispatch("switch", { checked });
  }
</script>

<label for={id} class="flex" class:items-center={align === "center"} class:items-start={align === "start"}>
  <div class="relative inline-block mr-2 align-middle bg-transparent cursor-pointer select-none">
    <input {id} name={id} type="checkbox" class="sr-only" {disabled} bind:checked on:change={() => onChange()} />
    <div
      class="w-12 h-6 rounded-full shadow-inner track"
      class:cursor-default={disabled}
      class:w-12={size === "medium"}
      class:h-6={size === "medium"}
      class:w-8={size === "small"}
      class:h-4={size === "small"} />
    <div
      class="absolute top-0 w-6 h-6 transition-all duration-300 ease-in-out transform bg-white border-2 rounded-full thumb"
      class:w-6={size === "medium"}
      class:h-6={size === "medium"}
      class:w-4={size === "small"}
      class:h-4={size === "small"}
      class:cursor-default={disabled} />
  </div>

  <slot />
</label>

<style>
  input[type="checkbox"]:not(:checked) ~ .track {
    background-color: var(--off-color, theme("backgroundColor.gray.600"));
  }

  input[type="checkbox"]:not(:checked) ~ .thumb {
    border-color: var(--off-color, theme("backgroundColor.gray.600"));
  }

  input[type="checkbox"]:checked ~ .thumb {
    @apply transform;
    @apply translate-x-full;
    border-color: var(--on-color, theme("borderColor.green.500"));
  }

  input[type="checkbox"]:checked ~ .track {
    @apply transform;
    @apply transition-colors;
    background-color: var(--on-color, theme("backgroundColor.green.500"));
  }

  input[type="checkbox"]:disabled ~ .track {
    background-color: var(--off-color, theme("backgroundColor.gray.500"));
  }

  input[type="checkbox"]:disabled ~ .thumb {
    background-color: var(--off-disabled-thumb-color, theme("backgroundColor.gray.100"));
    border-color: var(--off-color, theme("backgroundColor.gray.500"));
  }
</style>
