<script lang="ts" context="module">
  function format12H(hours: number, minutes: number) {
    const hLow = hours % 12;

    return `${hLow ? hLow : "12"}:${padZero(minutes)}${hours >= 12 ? "pm" : "am"}`;
  }

  function padZero(input: number, pad: string = "0") {
    return input < 10 ? pad + input : input;
  }

  function format24H(hours: number, minutes: number) {
    return `${padZero(hours)}:${padZero(minutes)}`;
  }

  export function dateTo24HTimeFormat(date: Date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    return format24H(hours, minutes);
  }
</script>

<script lang="ts">
  const INTERVALS: number = 4;
  const INTERVALS_IN_MINUTES: number = 60 / INTERVALS;
  // value is time in 23:50 format 24h (HH:MM)
  export let value: string = dateTo24HTimeFormat(new Date());

  // 24h/12h format for display
  export let format: "24h" | "12h" = "12h";

  export let isValueDisabled: ((value: string) => boolean) | undefined = undefined;

  export function refreshTimes() {
    format = "24h";
    format = "12h";
  }

  function calculateIsDisabled(value: string) {
    if (isValueDisabled) {
      return isValueDisabled(value);
    }
    return false;
  }

  // We have 24 hours, and 5m increments
  $: timeslots = Array.from(new Array(24 * INTERVALS)).map((_, idx) => {
    // we retrun the value in 24 (23:25) and the text in whatever format we have
    const h = Math.floor(idx / INTERVALS);
    const m = Math.floor(idx % INTERVALS) * INTERVALS_IN_MINUTES;
    const value = format24H(h, m);
    const disabled = calculateIsDisabled(value);
    const text = format === "12h" ? format12H(h, m) : format24H(h, m);

    return { value, text, disabled };
  });
</script>

<select bind:value on:change on:blur on:focus class="bg-white outline-none">
  {#each timeslots as { value, text, disabled }}
    <option {value} {disabled}>{text}</option>
  {/each}
</select>
