import { randId } from "sbelt/id";
import { getContext, onDestroy, setContext } from "svelte";
import { get, Readable, Writable, writable } from "svelte/store";
import isEqual from "lodash.isequal";

type FormStore = Readable<{
  errors: string[];
  waiting: string[];
}> & {
  // wait -> cancel wait
  setWait(id: string, msg: string | undefined | false | void): void;
  setError(id: string, msg: string | undefined | false | void): void;
};

const FORM_CONTEXT_KEY = {};

export function createFormContext(): FormStore {
  const store = formStore();
  setContext(FORM_CONTEXT_KEY, store);
  return store;
}

export function getFormContext(): FormStore | undefined {
  return getContext(FORM_CONTEXT_KEY) as FormStore;
}

function formStore(): FormStore {
  const w = writable<{ errors: string[]; waiting: string[] }>({ waiting: [], errors: [] });
  const waiting: Record<string, string> = {};
  const errors: Record<string, string> = {};

  function update() {
    w.set({
      errors: Object.values(errors).filter((i) => !!i),
      waiting: Object.values(waiting).filter((i) => !!i)
    });
  }

  function setWait(id: string, msg: string | undefined | false | void) {
    msg ? (waiting[id] = msg) : delete waiting[id];
    update();
    update();
  }

  function setError(id: string, msg: string | undefined | false | void) {
    msg ? (errors[id] = msg) : delete errors[id];
    update();
  }

  return {
    subscribe: w.subscribe,
    setWait,
    setError
  };
}

export type Validation<T> = (v: T) => string | undefined | void;
type FieldConfig<T> = {
  validate?: Validation<T> | false | Array<Validation<T> | false>;
  coerce?: (v: T) => T;
};

type InputStore<T> = Writable<T>;

export function field<T>(
  initialValue: T,
  updater: (c: [T, string | undefined]) => void,
  conf: FieldConfig<T> = { validate: (v: T) => {}, coerce: (v: T) => v }
) {
  const ctx = getFormContext();
  const id = randId("f");
  const isWaiting = writable(false);

  // clear pending / invalid issues when removing
  if (ctx) {
    onDestroy(() => {
      ctx.setError(id, false);
      ctx.setWait(id, false);
      isWaiting.set(false);
    });
  }

  return {
    id,
    setWait(msg: string | undefined | false) {
      ctx && ctx.setWait(id, msg);
      isWaiting.set(!!msg);
    },
    input: inputStore(initialValue, conf, ctx, id, updater),
    // return a readable store
    isWaiting: { subscribe: isWaiting.subscribe }
  };
}

function inputStore<T>(
  initialValue: T,
  { validate, coerce }: FieldConfig<T>,
  ctx: FormStore | undefined,
  id: string,
  updater: (c: [T, string | undefined]) => void
): InputStore<T> {
  const w = writable(initialValue);
  let lastValue: T = initialValue;

  // clear empty and convert to array
  validate = [validate!].flat().filter((v) => !!v);
  // run validation one time and save it in the context
  let error = (validate as Array<Validation<T>>).map((v) => v(initialValue)).filter((i) => !!i)[0] || undefined;
  ctx && ctx.setError(id, error);

  error = undefined;

  function set(val: T) {
    const value = coerce ? coerce(val) : val;
    if (isEqual(lastValue, value)) {
      return;
    }
    lastValue = value;
    error = (validate as Array<Validation<T>>).map((v) => v(value)).filter((i) => !!i)[0] || undefined;
    ctx && ctx.setError(id, error);

    w.set(value);
    updater([value, error]);
  }

  function update(updater: (val: T) => T) {
    set(updater(lastValue));
  }

  return {
    subscribe: w.subscribe,
    set,
    update
  };
}
