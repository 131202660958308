export type SocialType = "facebook" | "twitter" | "tiktok" | "youtube" | "pinterest" | "instagram";

export function coerceHandle(handle: string, type: SocialType) {
  if (type === "facebook" || type === "youtube") {
    // In order to support link to facebook pages in addition to users, we can't manipulate the handle
    return handle;
  }

  if (type === "tiktok") {
    const delimiter = "@";
    const l = handle.split(delimiter);
    return `@${l[l.length - 1]}`;
  }

  const delimiter = "@";
  const l = handle.split(delimiter);
  return l[l.length - 1];
}

export function handleURL(handle: string, type: SocialType) {
  handle = coerceHandle(handle, type);
  switch (type) {
    // allow us to have custom behavior in the future
    default:
      return `https://www.${type}.com/${handle}`;
  }
}

export function socialIcon(type: SocialType, color: string = "#fff") {
  return SOCIAL_ICONS[type].replace(/\#fff/gi, color);
}

const SOCIAL_ICONS: Record<SocialType, string> = {
  facebook: `  <svg class="w-4 h-4" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M14.804 7.043c0-3.89-3.134-7.043-7-7.043s-7 3.153-7 7.043c0 3.515 2.56 6.429 5.906 6.957V9.079H4.933V7.043H6.71V5.49c0-1.765 1.046-2.74 2.644-2.74.766 0 1.567.138 1.567.138v1.733h-.882c-.87 0-1.141.543-1.141 1.1v1.32h1.941l-.31 2.037H8.898V14c3.346-.528 5.906-3.442 5.906-6.957Z"
    fill="#fff" />
</svg>`,
  instagram: `  <svg class="w-4 h-4" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.394 1.26c1.87 0 2.092.009 2.828.042.683.03 1.053.145 1.299.24.325.126.56.28.804.523.246.246.396.478.522.804.096.246.21.618.24 1.299.034.738.042.96.042 2.828 0 1.87-.008 2.092-.041 2.828-.03.684-.145 1.053-.24 1.299-.127.325-.28.56-.523.804a2.153 2.153 0 0 1-.804.522c-.247.096-.618.21-1.3.241-.738.033-.96.041-2.827.041-1.871 0-2.093-.008-2.828-.041-.684-.03-1.053-.145-1.3-.24a2.165 2.165 0 0 1-.803-.523 2.153 2.153 0 0 1-.523-.804c-.095-.246-.21-.618-.24-1.3-.033-.738-.041-.96-.041-2.827 0-1.87.008-2.092.04-2.828.03-.684.146-1.053.241-1.3.126-.325.28-.56.523-.803.246-.246.478-.397.804-.523.246-.095.618-.21 1.299-.24.736-.033.957-.041 2.828-.041Zm0-1.26C5.493 0 5.255.008 4.508.041 3.764.074 3.253.194 2.81.366c-.462.181-.853.419-1.242.81a3.43 3.43 0 0 0-.81 1.239c-.171.446-.292.954-.325 1.698-.032.75-.04.988-.04 2.888 0 1.901.008 2.14.04 2.886.033.744.154 1.255.326 1.698.18.462.418.853.81 1.242.388.388.779.629 1.238.806.446.173.955.293 1.699.326.746.033.984.041 2.885.041 1.9 0 2.139-.008 2.885-.041.744-.033 1.256-.153 1.699-.325.46-.178.85-.419 1.239-.807.388-.389.629-.78.806-1.24.173-.445.293-.954.326-1.698.033-.746.04-.984.04-2.885 0-1.9-.007-2.139-.04-2.885-.033-.744-.153-1.256-.326-1.699a3.28 3.28 0 0 0-.8-1.244 3.423 3.423 0 0 0-1.24-.807c-.446-.172-.954-.292-1.698-.325C9.532.008 9.294 0 7.394 0Z"
    fill="#fff" />
  <path
    d="M7.394 3.405a3.597 3.597 0 0 0 0 7.193 3.597 3.597 0 0 0 0-7.193Zm0 5.93a2.333 2.333 0 1 1 0-4.667 2.333 2.333 0 0 1 0 4.666ZM11.972 3.263a.84.84 0 1 1-1.68 0 .84.84 0 0 1 1.68 0Z"
    fill="#fff" />
</svg>`,
  pinterest: `<svg class="w-4 h-4" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.627 0a7 7 0 0 0-2.81 13.41c-.02-.489-.003-1.075.122-1.607l.9-3.814s-.223-.447-.223-1.108c0-1.037.601-1.811 1.35-1.811.637 0 .944.478.944 1.05 0 .64-.408 1.598-.618 2.485-.176.742.372 1.348 1.105 1.348 1.326 0 2.219-1.703 2.219-3.722 0-1.534-1.033-2.682-2.913-2.682-2.123 0-3.446 1.584-3.446 3.352 0 .61.18 1.04.462 1.373.13.153.147.214.1.39-.034.129-.11.44-.142.562-.047.177-.19.241-.35.175-.979-.4-1.434-1.47-1.434-2.674 0-1.988 1.677-4.372 5.002-4.372 2.672 0 4.431 1.934 4.431 4.01 0 2.745-1.527 4.797-3.776 4.797-.756 0-1.467-.409-1.71-.873 0 0-.407 1.613-.493 1.924-.148.54-.439 1.08-.704 1.5A7 7 0 0 0 14.626 7a7 7 0 0 0-7-7Z"
    fill="#fff" />
</svg>`,
  tiktok: `  <svg class="w-4 h-4" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13.798 4.255A3.793 3.793 0 0 1 10.005.5h-2.45v6.692l-.003 3.664a2.22 2.22 0 0 1-3.36 1.901 2.212 2.212 0 0 1-1.082-1.869A2.22 2.22 0 0 1 6.03 8.75V6.268a4.692 4.692 0 0 0-.708-.053 4.697 4.697 0 0 0-3.529 1.578 4.606 4.606 0 0 0-1.159 2.772 4.59 4.59 0 0 0 1.367 3.574A4.694 4.694 0 0 0 5.322 15.5a4.721 4.721 0 0 0 3.321-1.361 4.583 4.583 0 0 0 1.376-3.262l-.011-5.475c.419.326.88.593 1.375.801a6.172 6.172 0 0 0 2.42.487V4.252c.004.003-.005.003-.005.003Z"
    fill="#fff" />
</svg>`,
  twitter: ` <svg class="w-4 h-4" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M5.077 11.5c5.128 0 7.933-4.232 7.933-7.903 0-.12 0-.24-.008-.359a5.66 5.66 0 0 0 1.39-1.437 5.572 5.572 0 0 1-1.6.437A2.789 2.789 0 0 0 14.016.7a5.599 5.599 0 0 1-1.77.674A2.794 2.794 0 0 0 8.897.83a2.77 2.77 0 0 0-1.402 3.08A7.939 7.939 0 0 1 1.75 1.007a2.77 2.77 0 0 0 .863 3.707 2.771 2.771 0 0 1-1.265-.347v.035c0 .641.223 1.263.63 1.759a2.79 2.79 0 0 0 1.606.964c-.41.111-.84.127-1.258.047.178.552.525 1.035.992 1.38.467.346 1.03.538 1.612.55a5.6 5.6 0 0 1-4.126 1.15 7.914 7.914 0 0 0 4.273 1.246"
    fill="#fff" />
</svg>`,
  youtube: `<svg class="w-4 h-4" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.169 1.338c.242.244.416.547.504.88C16 3.442 16 6 16 6s0 2.557-.327 3.783a1.962 1.962 0 0 1-1.38 1.389c-1.22.328-6.1.328-6.1.328s-4.879 0-6.1-.328a1.962 1.962 0 0 1-1.38-1.39C.387 8.558.387 6 .387 6s0-2.557.326-3.783A1.962 1.962 0 0 1 2.093.828C3.315.5 8.194.5 8.194.5s4.88 0 6.1.328c.332.09.634.267.876.51Zm-8.572 2.34v4.644L10.677 6l-4.08-2.321Z"
    fill="#fff" />
</svg>`
};
