import type { Upload } from "sbelt/net";

type UploadEntry<T> = { upload: Upload<T>; preview?: string };

const uploads: Record<string, UploadEntry<any>> = {};

export function uploadById<T>(id: string | undefined): UploadEntry<T> | undefined {
  return id ? uploads[id] : undefined;
}

export function setUploadById<T>(id: string, entry: undefined | UploadEntry<T>) {
  if (!entry) {
    delete uploads[id];
  } else {
    uploads[id] = entry;
  }
}
