<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./Block.svelte";
  import { getCurrentUser } from "@ui/currentUser";
  import { getNewsletterStores } from "../newsletterStores";
  import { isEditor } from "./utils";
  import { get } from "svelte/store";
  import { set_store_value as set } from "svelte/internal";
  import { id as getId } from "./utils";
  import { isEmpty as isEmptyTest } from "./blocksConfig";
  import { userStorage } from "@ui/localStorage";
  import Rich from "./utils/Rich.svelte";
  import SocialIcon from "./web/social-buttons/SocialIcon.svelte";

  function generateInitials(name: string): string {
    const parts = name.split(" ");
    if (parts.length < 2) return `${parts[0][0]}`;
    return `${parts[0][0]}${parts[1][0]}`;
  }

  export let block: schema.SignatureBlock;
  const { design, content, id } = getNewsletterStores();

  $: isEmpty = isEmptyTest(block);

  $: if (isEmpty && isEditor() && block._id) {
    createSignatureBasedOnCurrentUser();
  }

  function createSignatureBasedOnCurrentUser(): schema.SignatureBlock {
    const currentUser = get(getCurrentUser());
    const pastSignatureStorage = userStorage<Partial<schema.SignatureBlock> | undefined>(`past-signature`, {});
    const pastSignature = get(pastSignatureStorage);

    let result: schema.SignatureBlock;
    content.updateById<schema.SignatureBlock>(block._id, (footer) => {
      footer.title = currentUser.name;
      footer.tagline = `${(currentUser.name || "").split(" ")[0]} is using Smore to create beautiful newsletters`;
      footer.email = currentUser.email;
      footer.content = currentUser.bio;
      footer.picture = {
        id: getId(),
        legacy_full_url: currentUser.picture,
        legacy_thumb_url: currentUser.picture
      };

      // Reuse the last signature the current user has used (in this browser)
      if (pastSignature?.id === currentUser.id) {
        for (let k in pastSignature) {
          if (k === "_id") {
            continue;
          }
          // @ts-ignore
          footer[k] = pastSignature[k];
        }
      }

      result = { ...footer };
      set(pastSignatureStorage, null, { ...footer, id: currentUser.id });

      return footer;
    });
    return result!;
  }

  $: initials = generateInitials(block.title || "");

  $: color = $design!.color.signature.text;
  $: background = $design!.color.signature.background;

  $: hasSocial = block.pinterest || block.twitter || block.youtube || block.tiktok || block.instagram || block.facebook;
</script>

<Block {block} on:click on:delete size="clear" preventDelete layouts={["side-l", "center"]} let:layout>
  {#key `${color}-${background}`}
    <div
      class="p-5 transition-all rounded-lg rounded-t-none min-h-16 ada-bg-black print-no-bg-no-shadow"
      style="background-color: {background}; box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);">
      <div class="flex flex-col w-full gap-3" style="color:{color}" class:items-center={layout === "center"}>
        <div class="flex items-center flex-grow-0 gap-2 ada-text-white" class:flex-col={layout === "center"}>
          {#if block.picture && block.picture.legacy_thumb_url}
            <div class="relative flex items-center justify-center flex-shrink-0 w-10 h-10 overflow-hidden font-medium rounded-full text-md">
              <div
                class="absolute inset-0 flex items-center justify-center text-sm"
                class:bg-nxgray-600={!block.picture || !block.picture.legacy_thumb_url} />
              {#if block.picture && block.picture.legacy_thumb_url}
                <div
                  class="absolute inset-0 block bg-center bg-no-repeat bg-cover rounded-full"
                  style="background-image: url({block.picture.legacy_thumb_url})" />
              {:else}
                {initials}
              {/if}
            </div>
          {/if}
          <div class="text-md" class:text-center={layout === "center"}>
            <div class="font-bold leading-tight text-18 ada-text-white">{block.title}</div>
            <div class="text-13 ada-text-white">{block.tagline || ""}</div>
          </div>
        </div>
        <!-- social + contact -->
        {#if block.contactButton || block.subscribeButton || hasSocial}
          <div
            class="flex flex-col justify-between flex-shrink-0 w-full gap-3"
            class:flex-col-reverse={layout === "center"}
            class:sm:flex-row={layout !== "center"}
            class:items-center={layout === "center"}>
            {#if block.contactButton || block.subscribeButton}
              <div
                class="flex flex-col gap-3 sm:flex-row"
                class:items-center={layout === "center"}
                class:sm:items-center={layout !== "center"}
                class:items-start={layout !== "center"}>
                {#if block.contactButton}
                  <button id="contact_user" class="px-3.5 py-1 text-sm font-bold border border-current rounded-3xl clickable ada-text-white"
                    >Contact</button>
                {/if}
                {#if block.subscribeButton}
                  <button
                    id="subscribe_user"
                    data-list-id={block.subscribeList}
                    class="px-3.5 py-1 text-sm font-bold border border-current rounded-3xl clickable ada-text-white">Subscribe</button>
                {/if}
              </div>
            {/if}
            {#if hasSocial}
              <div
                class="flex items-center flex-shrink-0 gap-2"
                class:w-full={layout === "center"}
                class:justify-center={layout === "center"}>
                <SocialIcon type="pinterest" handle={block.pinterest} {color} />
                <SocialIcon type="tiktok" handle={block.tiktok} {color} />
                <SocialIcon type="facebook" handle={block.facebook} {color} />
                <SocialIcon type="twitter" handle={block.twitter} {color} />
                <SocialIcon type="instagram" handle={block.instagram} {color} />
                <SocialIcon type="youtube" handle={block.youtube} {color} />
              </div>
            {/if}
          </div>
        {/if}

        {#if block.content && block.content.length}
          <div class="text-13 sig-description ada-text-white" style="color:{color};">
            <Rich
              content={block.content || ""}
              inline={{ color: "inherit", linkColor: "inherit" }}
              context={{ type: "signature" }}
              forceTracking />
          </div>
        {/if}
      </div>
    </div>

    {#if isEditor()}
      <!-- Overlay -->
      <div class="absolute inset-0 rounded rounded-t pointer-events-none block-overlay" />
    {/if}
  {/key}
</Block>

<style>
  :global(.sig-description p a) {
    text-decoration: underline;
    /* color: #c9f4ec; */
  }

  .block-overlay {
    background: #dcf4f1;
    opacity: 0.2;
  }
</style>
