<script lang="ts">
  import BlockEditor from "./BlockEditor.svelte";
  import type { schema } from "@editor/schema";
  import TextField from "./form/TextField.svelte";
  import CustomHeaderField from "./fields/CustomHeaderField.svelte";
  import cloneDeep from "lodash.clonedeep";
  import ProBlocker from "@ui/ProBlocker.svelte";
  import EmojiPickerButton from "./form/EmojiPickerButton.svelte";

  export let block: schema.HeaderBlock;
  let draft: schema.HeaderBlock = cloneDeep(block);
  let titleTextField: TextField;
  let subTitleTextField: TextField;

  export let focusField: string | undefined = "title";

  // Video stuff
  const VIDEO_ID = "mr7b02e18k";
</script>

<svelte:head>
  <script src="https://fast.wistia.com/embed/medias/{VIDEO_ID}.jsonp" async></script>
  <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
</svelte:head>

<BlockEditor {draft} on:done on:cancel>
  <TextField
    bind:value={draft.title}
    bind:this={titleTextField}
    label="Title"
    required
    autofocus={focusField === "title"}
    limit={35}
    size="lg"
    placeholder={draft._defaults?.title || "Add a title"}>
    <div slot="afterInput" class="flex items-center gap-1 pl-1">
      <EmojiPickerButton on:selected={(e) => titleTextField.insertTextAtCursor(e.detail)} />
    </div>
  </TextField>
  <div class="mt-3">
    <TextField
      bind:value={draft.subtitle}
      bind:this={subTitleTextField}
      label="Subtitle"
      autofocus={focusField === "subtitle"}
      limit={60}
      size="sm"
      placeholder={draft._defaults?.subtitle || "Add a subtitle"}>
      <div slot="afterInput" class="flex items-center gap-1 pl-1">
        <EmojiPickerButton on:selected={(e) => subTitleTextField.insertTextAtCursor(e.detail)} />
      </div>
    </TextField>
  </div>

  <div class="w-full mt-2 z-100">
    <ProBlocker cap="custom_newsletter_headers" upgradeReason="header-editor">
      <CustomHeaderField bind:value={draft.custom} on:logged_out />
    </ProBlocker>
  </div>
</BlockEditor>
