import type { schema } from "@editor/schema";
import { blocksConfig } from "../blocksConfig";
import { getContext, setContext } from "svelte";
import { writable } from "svelte/store";
import type { Writable } from "svelte/store";

const DEFAULT_SPACING = 20;
const DEFAULT_SPACING_FUNC = (t: schema.Block, b: schema.Block) => DEFAULT_SPACING;

type BlockSpace = { before: number; after: number };

const SPACING_CONTEXT_KEY = {};

export function setSpacing(block: schema.Block, siblings: { before?: schema.Block; after?: schema.Block }) {
    const result: BlockSpace = {
        before: space(siblings.before, block).after,
        after: space(block, siblings.after).before
    };

    const store = getSpacing() ?? writable(result);

    store.set(result);

    setContext(SPACING_CONTEXT_KEY, store);

    return store;
}

export function getSpacing(): Writable<BlockSpace> {
    return getContext(SPACING_CONTEXT_KEY);
}

function space(a?: schema.Block, b?: schema.Block) {
    if (!a && b) {
        return { before: 0, after: DEFAULT_SPACING };
    }
    if (a && !b) {
        return { before: DEFAULT_SPACING, after: 0 };
    }
    if (!a && !b) {
        return { before: 0, after: 0 };
    }

    const mb = (blocksConfig[a!._t].mb || {})[b!._t] ?? ((a: any, b: any) => -1);
    const mt = (blocksConfig[b!._t].mt || {})[a!._t] ?? ((a: any, b: any) => -1);

    // @ts-ignore
    let s = Math.max(mb(a, b) ?? -1, mt(b, a) ?? -1);

    if (s === -1) {
        s = DEFAULT_SPACING * 2;
    }

    const before = Math.floor(s / 2);
    const after = s - before;
    return { before, after };
}
