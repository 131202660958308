import type { schema } from "@editor/schema";
import type { Category, DesignDef } from "./types";
import resources from "./resources.json";
import type { FontDef } from "../app/svgkit/fonts/types";
import { postJson } from "@ui/http";
import { derived, get, Readable, writable, Writable } from "svelte/store";
import { storage } from "@ui/localStorage";
import { dequal } from "dequal";

import assignInWith from "lodash.assigninwith";

import modernJSON from "./modern/modern.json";
import blossomJSON from "./blossom/blossom.json";
import classicJSON from "./classic-legacy/classic-legacy.json";
import minimalJSON from "./minimal/minimal.json";
import handwrittenJSON from "./handwritten/handwritten.json";
import heartfeltJSON from "./heartfelt/heartfelt.json";
import vintageJSON from "./vintage/vintage.json";
import starsJSON from "./stars/stars.json";
import pumpkinJSON from "./pumpkin/pumpkin.json";
import summertimeJSON from "./summertime/summertime.json";
import snowflakeJSON from "./snowflake/snowflake.json";

import { fromJSON, JSONAnyDesignAsset, JSONDesignDef } from "./json_designs";
import cloneDeep from "lodash.clonedeep";

// Represent a change pending to be saved to the disk.
type DesignChange = {
  feelId: string;
  assetId: string;
  description?: string;
  patch: object;
  active?: boolean;
};

type DesignAssetContent = {
  designs: DesignDef[];
  designsById: Record<schema.DesignFeelKey, DesignDef>;
  assetByDesignId: Record<string, Record<string, any>>;
  byId<T>(designId: string, id: string): T | undefined;
  changes: DesignChange[];
};

type DesignAssetsStore = Readable<DesignAssetContent> & {
  // Returns the change, isactive store and a revert function
  change<T>(designId: string, id: string): ChangeStore<T>;
  patch: (feelId: string, id: string, value: any, description?: string) => void;
  removePatch: (feelId: string, id: string) => void;
  commit: () => Promise<void>;
};

type ChangesDict = Record<string, Record<string, DesignChange>>;

type ChangeStore<T> = [Writable<T> & { revert(): () => void }, Writable<undefined | boolean>];

// @ts-ignore
let feels: Record<schema.DesignFeelKey, JSONDesignDef> = {
  // @ts-ignore
  "modern": modernJSON,
  // @ts-ignore
  "classic-legacy": classicJSON,
  // @ts-ignore
  "blossom": blossomJSON,
  // @ts-ignore
  "handwritten": handwrittenJSON,
  // @ts-ignore
  "heartfelt": heartfeltJSON,
  // @ts-ignore
  "minimal": minimalJSON,
  // @ts-ignore
  "vintage": vintageJSON,
  // @ts-ignore
  "summertime": summertimeJSON,
  // @ts-ignore
  "pumpkin": pumpkinJSON,
  //@ts-ignore,
  "stars": starsJSON,

  //@ts-ignore,
  "snowflake": snowflakeJSON
};

export let Designs: Record<schema.DesignFeelKey, DesignDef> = {};

/***
 * This is a special store we have so we could override the design *JSON* content.
 * It's designed to allow the design admin to make edits to the jsons and save them all
 *
 */
export const assets: DesignAssetsStore = (() => {
  const changes = storage<ChangesDict>("design_admin.changes", {});

  const designStore = derived([changes], ([$changes]) => {
    const designsById: Record<string, DesignDef> = {};
    const jsonDesigns: Record<string, JSONDesignDef> = {};

    const clonedFeels = cloneDeep(feels);

    for (let key in clonedFeels) {
      // we patch the assets with our changes
      const json = (jsonDesigns[key] = {
        ...clonedFeels[key as schema.DesignFeelKey],
        assets: patchWithChanges(clonedFeels[key as schema.DesignFeelKey].assets, $changes[key])
      });

      const bgs = resources[key as schema.DesignFeelKey].backgrounds;
      designsById[key as schema.DesignFeelKey] = fromJSON(json, { backgrounds: bgs, fonts: resources.fonts });
    }

    const designs = Object.values(designsById).sort((a, b) => Number(a.$order) - Number(b.$order));

    const allChanges = Object.values($changes).flatMap((feelChanges) => Object.values(feelChanges));

    const assetByDesignId = collectAssetsById(designs);

    return {
      designs,
      jsonDesigns,
      designsById,
      byId(designId: string, id: string) {
        return assetByDesignId[designId]?.[id];
      },
      assetByDesignId,
      changes: allChanges
    };
  });

  function change<T extends object>(designId: string, id: string) {
    // @ts-ignore
    const ogData = feels[designId].assets[id] as T;

    const change = (get(changes)[designId] || {})[id];

    const c = assignInWith({}, cloneDeep(ogData), cloneDeep(change?.patch || {}));

    const store = writable<T>(c as T);
    const isActive = writable<boolean | undefined>(change ? change.active !== false : undefined);

    isActive.subscribe((activeValue) => {
      if (activeValue !== undefined) {
        setActive(designId, id, activeValue);
      }
    });

    return [
      {
        subscribe: store.subscribe,
        set(newValue: T) {
          if (dequal(newValue, ogData)) {
            return;
          }

          // @ts-ignore
          patch(designId, id, newValue);
          isActive.set(true);
        },
        update(updater: (v: T) => T) {
          const d = get(store);
          updater(d);

          this.set(d);
        },
        revert() {
          const current = get(store);
          removePatch(designId, id);
          store.set(cloneDeep(ogData));
          isActive.set(undefined);

          // undo method
          return () => {
            // @ts-ignore
            patch(designId, id, current);
            isActive.set(true);
          };
        }
      },
      isActive
    ];
  }

  async function commit() {
    // send server all the patches

    await postJson(`/app/design_admin/update_assets`, get(changes));
    // when done, clear all changes
    changes.set({});
  }

  function patch(designId: string, id: string, value: JSONAnyDesignAsset, description?: string) {
    changes.update((current) => {
      const designChanges = current[designId] || (current[designId] = {});
      const patch = designChanges[id] || (designChanges[id] = { feelId: designId, assetId: id, patch: {} });
      patch.patch = value;

      return current;
    });
  }

  function setActive(designId: string, id: string, value: boolean) {
    changes.update((current) => {
      const designChanges = current[designId] || (current[designId] = {});
      const patch = designChanges[id];
      if (patch) {
        patch.active = value;
      }

      return current;
    });
  }

  function removePatch(feelId: string, id: string) {
    changes.update((current) => {
      const feelChanges = current[feelId] || (current[feelId] = {});
      delete feelChanges[id];
      return current;
    });
  }

  return {
    change,
    subscribe: designStore.subscribe,
    commit
  };
})();

let _assets: DesignAssetContent;
// store as global
assets.subscribe(($assets) => {
  _assets = $assets;
  Designs = $assets.designsById;
});

export function resourceLookup<T>(feelId: string, id: string): T | undefined {
  return _assets?.byId<T>(feelId, id);
}

function patchWithChanges(input: JSONDesignDef["assets"], changes: Record<string, DesignChange> | undefined) {
  if (!changes || !Object.values(changes).length) {
    return input;
  }

  const patches: Record<string, any> = {};

  for (let id in changes) {
    const c = changes[id];
    if (c.active !== false) {
      patches[id] = c.patch;
    }
  }

  return assignInWith({}, input, cloneDeep(patches)) as JSONDesignDef["assets"];
}

function collectAssetsById(designs: DesignDef[]) {
  const assetByDesignId: Record<string, Record<string, any>> = {};

  designs.forEach((d) => {
    const assets = (assetByDesignId[d.id] = {});

    for (let asset of assetIterator<any>([...d.backgrounds, ...d.colorPacks, ...d.fontPacks])) {
      // @ts-ignore
      assets[asset.id] = asset;
    }
  });

  return assetByDesignId;
}

function* assetIterator<T>(categories: Category<T>[]): Iterable<T> {
  for (let cat of categories) {
    for (let asset of cat.items) {
      yield asset;
    }
  }
}

// @ts-ignore
export const ALL_FONT_DEFS = Object.values(resources.fonts) as FontDef[];
