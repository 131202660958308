<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./EmailBlock.svelte";
  import { getNewsletterStores } from "../../newsletterStores";
  import { LinkContent } from "../utils";
  import IfNotOutlook from "./helpers/IfNotOutlook.svelte";
  import EndIfNotOutlook from "./helpers/EndIfNotOutlook.svelte";
  import IfOutlook from "./helpers/IfOutlook.svelte";
  import EndIfOutlook from "./helpers/EndIfOutlook.svelte";
  export let block: schema.EmbedFileBlock;
  const { ctx } = getNewsletterStores();

  let fileSize = 0;
  let fileSizeUnit = "KB";

  $: {
    fileSize = block.file_size_in_bytes / 1024;
    if (fileSize > 1024) {
      fileSize = fileSize / 1024;
      fileSizeUnit = "MB";
    }
  }

  const svg = `<svg viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29 18v-7.727L19.667 1H2.556C1.7 1 1 1.695 1 2.545v30.91C1 34.305 1.7 35 2.556 35h24.888C28.3 35 29 34.304 29 33.455V31" stroke="#CACACF" stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round"/>
    <path d="M19 2v9h9" stroke="#CACACF" stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round"/>
    <text lang="en" x="12" y="28" fill="#505060" style="font-weight:700;font-size: 10px;font-family: sans-serif;">${block.file_type.toUpperCase()}</text>
    </svg>`;

  const cta = { id: block._id, url: block.access_url };
</script>

<Block {block} let:layout>
  <IfNotOutlook />
  <LinkContent {cta} type="file" linkText={block.file_name}>
    <EndIfNotOutlook />
    <table
      width="100%"
      role="presentation"
      cellspacing="0"
      cellpadding="8"
      style="display:block;width:100%;max-width:700px;border:1px solid #ccc;border-radius:6px;box-shadow:0px 2px 5px rgba(0, 0, 0, 0.05);"
      bgcolor="#ffffff">
      <tr>
        <td width="100%">
          <table width="100%" role="presentation" cellspacing="0" cellpadding="4">
            <tr>
              <td valign="middle" align="center" style="display:inline-block" width="34" height="36">
                <img
                  src={ctx.svg(svg, { shared: true })}
                  width="34"
                  height="36"
                  alt={block.file_type}
                  role="presentation"
                  style="display:inline-block" />
              </td>
              <td style="padding-left:48px;" width="100%">
                <IfOutlook />
                <LinkContent {cta} type="file" linkText={block.file_name}>
                  <EndIfOutlook />
                  <span style="text-decoration:none;font:15px/1.5 sans-serif;font-weight:bold;">
                    {block.file_name}
                  </span>
                  <IfOutlook />
                </LinkContent>
                <EndIfOutlook />

                <div style="text-decoration:none;font:13px/1.5 sans-serif;opacity:75%;max-width:100%;">
                  {block.file_description || ""}
                </div>
              </td>
            </tr>
          </table></td>
        <td width="90" valign="middle" align="center" style="max-width: 100%;">
          <IfOutlook />
          <LinkContent {cta} type="file" linkText={block.file_name}>
            <EndIfOutlook />
            <div style="text-decoration:underline;font:14px/1.5 sans-serif;font-weight:bold;color:#1c6e98">Download</div>
            <div style="text-decoration:none;font:12px/1.5 sans-serif;color:#aaa">{fileSize.toFixed(1)} {fileSizeUnit}</div>
            <IfOutlook />
          </LinkContent>
          <EndIfOutlook />
        </td>
      </tr>
    </table>
    <IfNotOutlook />
  </LinkContent>
  <EndIfNotOutlook />
</Block>
