<script lang="ts">
  import type { schema } from "@editor/schema";

  import PhotoField from "../form/PhotoField.svelte";
  import TextField from "../form/TextField.svelte";
  import RichTextField from "../form/RichTextField.svelte";
  import CTAField from "../form/CTAField.svelte";
  import CollapsibleSection from "@ui/CollapsibleSection.svelte";

  import { toText } from "@ui/rich/richText";
  import AlignButton from "../form/AlignButton.svelte";
  import EmojiPickerButton from "../form/EmojiPickerButton.svelte";

  export let handoffId: string;
  export let idx: number;
  export let item: schema.ItemsBlockContent | undefined | null;
  export let defaults: Partial<schema.ItemsBlockContent> | undefined | null = {};
  export let focusField: string | undefined = undefined;

  const ResizeInformation = {
    min: { w: 200, h: 160 },
    max: { w: 600, h: 480 },
    default: { w: 200, h: 160 }
  };

  let title: string | undefined = item?.title;
  let description: schema.RichText | undefined = item?.description;
  let altText: schema.ItemsBlockContent["alt_text"] | undefined = item?.alt_text;
  let cta: schema.ItemsBlockContent["cta"] | undefined = item?.cta;

  let align: "left" | "center" | "right" = item?.align || "left";

  let textField: TextField;

  $: if (item) {
    item.title = title;
    item.description = description;
    item.cta = cta;
    item.alt_text = altText;
    item.align = align;
  }
</script>

<div class="flex-shrink-0 w-48 mr-2 max-h-32">
  <PhotoField bind:value={item} {handoffId} on:logged_out mode="resize" showErrorMessage ar={ResizeInformation}>
    <img
      slot="preview"
      let:previewUrl={preview}
      src={preview}
      class="block object-contain w-full h-32 bg-gray-100 rounded max-w-none"
      alt={altText} />
  </PhotoField>
</div>
<div class="flex-grow">
  <TextField
    label="Title"
    bind:value={title}
    bind:this={textField}
    {align}
    placeholder={defaults?.title || "Add a title"}
    autofocus={focusField === `title-${idx}`}>
    <div slot="afterInput" class="flex items-center gap-1 pl-1" let:rtl>
      <AlignButton bind:align {rtl} />
      <EmojiPickerButton on:selected={(e) => textField.insertTextAtCursor(e.detail)} />
    </div>
  </TextField>
  <div class="mt-3">
    <RichTextField
      label="Description"
      bind:value={description}
      placeholder={toText(defaults?.description) || "Add description"}
      autofocus={focusField === `content-${idx}`} />
  </div>

  <CollapsibleSection inline title="Add alt text" captionClasses="mt-2 mr-1" icon="title" startOpen={!!altText} autofocus>
    <div class="mt-3">
      <TextField label="Alt Text" bind:value={altText} placeholder="Enter an alt text" />
    </div>
  </CollapsibleSection>
  <CollapsibleSection inline title="Make the item linkable" captionClasses="mt-2 mr-1 " icon="link" startOpen={!!cta} autofocus>
    <div class="mt-3">
      <CTAField label="Target url" bind:value={cta} placeholder="Enter a URL for the title target (like https://www.google.com)" />
    </div>
  </CollapsibleSection>
</div>
