<script lang="ts">
  import type { schema } from "@editor/schema";
  import { getNewsletterStores } from "../../../newsletterStores";
  import { isEmpty as isEmptyTest } from "../../blocksConfig";
  import { getBlockId, trackingAndAttributesFromCTA } from "../../utils";
  import { Base64PlaySVGImageData } from "./utils";

  export let block: schema.VideoBlock;
  export let isEditor: boolean = false;

  $: isEmpty = isEmptyTest(block);

  const cta = block.movie
    ? {
        url: `https://www.viddler.com/embed/${block.movie || ""}/?f=1&player=full`,
        id: `v-${block._id}`
      }
    : undefined;

  const { ctx } = getNewsletterStores();
  const blockId = getBlockId();
  const [target, _ignore] = trackingAndAttributesFromCTA(ctx, cta, { type: "video", text: block.title, blockId });

  const srcDoc = `<style>
                *{padding:0;margin:0;overflow:hidden;}
                html,body{height:100%;}
                img{position:absolute;width:100%;top:0;bottom:0;margin:auto;}
                span{height:1.5em;color:white;}
                .caption{white-space:nowrap;color:white;z-index:1;padding:0.25rem 0.5rem;background:linear-gradient(#000000A0, #00000050);display:block;font: 400 16px/1.5 Roboto;color: #fff;text-decoration:none;}
              </style>
              <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700" rel="stylesheet" class="skip-tracking">
              <a rel="nofollow noopener noreferrer" class="skip-tracking" href="${target}">
                <span class="caption" style="text-overflow:ellipsis;position:absolute;top:0;left:0;right:0;">${block.title}</span>
                <span class="caption" style="position:absolute;bottom:0;right:0;font-size:0.75rem;flex-shrink:0;border-top-left-radius:0.25rem;">${block.target}</span>
                <img style="height:350px;object-fit:cover" src="${block.thumbnail_url}" alt="${block.title}" />
                <img src="${Base64PlaySVGImageData}" width="700" alt="Click to play: ${block.title}" style="display:block !important; max-width:700px;width:100%;height:240px;" />
              </a>`;
</script>

<div class="content widget-video-viddler-new">
  <div class="framed-image-container no-drag" class:p-2={isEmpty}>
    <div class="video-box">
      {#if isEditor}
        <div class="video-shim" />
      {/if}
      {#if isEmpty}
        <div class="absolute inset-0 flex items-center justify-center text-gray-600 bg-gray-100 border-2 border-dashed rounded-lg">
          Viddler video
        </div>
      {:else}
        <iframe
          title={block.title}
          class="player"
          loading="lazy"
          data-title={block.title}
          src={`https://www.viddler.com/embed/${block.movie || ""}/?f=1&player=full`}
          srcdoc={`${srcDoc}`}
          frameborder="0"
          allowfullscreen
          height="350"
          width="100%" />
      {/if}
    </div>
  </div>
</div>

<style>
  .widget-video-viddler-new .framed-image-container {
    position: relative;
    height: 350px;
  }

  .widget-video-viddler-new iframe,
  .widget-video-viddler-new .video-box {
    height: auto;
    display: block;
    width: 100%;
    height: 100%;
  }

  .widget-video-viddler-new .video-shim {
    height: 100%;
    width: 100%;
    z-index: 1000;
    background: red;
    opacity: 0;
    display: block;
    position: absolute;
  }
</style>
