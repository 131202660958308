<script lang="ts">
  import { onMount } from "svelte";
  import Field from "./Field.svelte";

  import { field } from "./store";
  import { requiredTest, urlTest, coerceUri } from "./validations";

  export let value: string = "";
  export let label: string | undefined;
  export let required: boolean = false;
  export let icon: string | undefined = undefined;
  export let autocomplete: string = "url";
  export let autofocus: boolean = false;
  export let error: string | undefined = undefined;
  export let placeholder: string = "";
  export let disabled: boolean = false;
  export let urlOnly: boolean = true;

  let el: HTMLInputElement;
  export let isFocused: boolean = false;

  const { id, input } = field<string>(value, (c) => ([value, error] = c), {
    validate: [required && requiredTest(), urlTest("not a valid url", urlOnly)]
  });
  // update from outside
  $: $input = value;

  if (autofocus) {
    onMount(focus);
  }

  export function focus() {
    el && el.select();
    isFocused = true;
  }
</script>

<Field {label} {id} {required} {error} {value} {icon} size="md" {isFocused}>
  {#if urlOnly}
    <input
      type="url"
      bind:value={$input}
      bind:this={el}
      on:focus={() => (isFocused = true)}
      on:blur={() => (isFocused = false)}
      on:input
      {required}
      {autocomplete}
      {placeholder}
      {id}
      {disabled}
      {autofocus}
      class="flex-grow block mx-1 outline-none text-13" />
  {:else}
    <input
      type="search"
      bind:value={$input}
      bind:this={el}
      on:focus={() => (isFocused = true)}
      on:blur={() => (isFocused = false)}
      on:input
      {required}
      {autocomplete}
      {placeholder}
      {id}
      {disabled}
      {autofocus}
      class="flex-grow block mx-1 outline-none text-13" />
  {/if}
</Field>
