import { writable, get } from "svelte/store";
import { on } from "sbelt/dom";
import type { Point } from "sbelt/math";

export type ActiveDrag =
  | undefined
  | ({
      // Page X of the drag
      x: number;
      // Page y of the drag
      y: number;
      // Current element we're over right now
      el?: Element | null;
    } & DragPayload);

export type DragPayload = {
  // drag payload options:
  kind: "file" | "url" | "custom";
  mime: string;
  items: DragItem[];
  // mutli items?
  multi: boolean;
};

export type CustomDragHandle = {
  update(p: Point): void;
  drop(): { accepted: boolean } & Point;
  cancel(): void;
};

export type DragItem = {
  mime: string;
  kind: DragPayload["kind"];
  value: File | any;
};

function store() {
  const w = writable<ActiveDrag>(undefined, (set) => {
    // on SSR we don't register events
    if (import.meta.env.SSR) {
      return () => {};
    }
    // handle drag
    // timeout hanlder
    let t: number;

    const off = on(document, {
      drop(e: DragEvent) {
        // disable the default drop
        e.preventDefault();
        set(undefined);
      },
      dragover(e: DragEvent) {
        e.preventDefault();
        set({ ...payloadFromEvent(e)!, ...pos({ x: e.pageX, y: e.pageY }) });
        clearTimeout(t);
        t = window.setTimeout(() => set(undefined), 100);
      }
    });
    return off;
  });

  function start(itemOrItems: DragItem | DragItem[], p: Point): CustomDragHandle {
    const pl = payload(itemOrItems);
    w.set({ ...pl, ...pos(p) });

    return {
      update(p: Point) {
        w.update((v) => ({ ...(v || pl), ...pos(p) }));
      },
      drop() {
        const v = get(w);

        if (!v) {
          return { accepted: false, x: 0, y: 0 };
        }

        const e = new CustomEvent("dragshow:drop", {
          bubbles: true,
          cancelable: true,
          detail: v
        });

        const r = !v.el?.dispatchEvent(e);
        w.set(undefined);
        return { accepted: r, x: v.x, y: v.y };
      },
      cancel() {
        w.set(undefined);
      }
    };
  }

  return {
    subscribe: w.subscribe,
    start
  };
}

function payload(items: DragItem | DragItem[]): DragPayload {
  items = Array.isArray(items) ? items : [items];

  return {
    items,
    kind: items[0].kind,
    mime: items[0].mime,
    multi: items.length > 1
  };
}

function pos(p: Point) {
  // element from point is using viewport coords
  const el = document.elementFromPoint(p.x - visualViewport.pageLeft, p.y - visualViewport.pageTop);
  return { ...p, el };
}

export const activeDrag = store();

function payloadFromEvent(e: DragEvent): DragPayload | undefined {
  if (!e.dataTransfer) {
    return;
  }

  const items: DragItem[] = Array.from(e.dataTransfer.items).map((i) => ({
    value: i.kind === "file" ? i.getAsFile() : undefined,
    kind: "file",
    mime: i.type
  }));

  return payload(items);
}

export function fromEvent(e: DragEvent): ActiveDrag | undefined {
  const p = payloadFromEvent(e);
  if (!p) {
    return;
  }
  return { ...p, ...pos({ x: e.pageX, y: e.pageY }) };
}

export type DragFilter = (p: DragPayload) => boolean;

export function isAcceptable(d: DragPayload | undefined, accepts: string | undefined): boolean {
  if (!d) return false;
  if (d && !accepts) return true;

  return new RegExp(accepts!.replace(/,/g, "|"), "i").test(d.mime);
}
