import BlockSeparatorTransparent from "./misc.separator.transparent.svg";
import BlockSeparatorDotted from "./misc.separator.dotted.svg";
import BlockSeparatorSolid from "./misc.separator.solid.svg";
import BlockButtonTransparent from "./button.transparent.svg";
import BlockButtonSolid from "./button.solid.svg";

export const IconsMap: { [key: string]: any } = {
  "misc.separator.transparent": BlockSeparatorTransparent,
  "misc.separator.dotted": BlockSeparatorDotted,
  "misc.separator.solid": BlockSeparatorSolid,
  "button.transparent": BlockButtonTransparent,
  "button.solid": BlockButtonSolid,
  "button.-": BlockButtonSolid
};
