<script lang="ts">
  import { getNewsletterStores } from "../../newsletterStores";
  import type { LinkTypes } from "../../newsletterStores";
  import type { schema } from "@editor/schema";
  import { getBlockId, trackingAndAttributesFromCTA } from "../utils";

  export let cta: schema.CallToAction | undefined = undefined;
  export let type: LinkTypes = "link";
  export let linkText: string = "";

  export let showEmpty: boolean = false;

  const { design, content, ctx } = getNewsletterStores();

  const blockId = getBlockId();

  $: [target, hrefAttrs] = trackingAndAttributesFromCTA(ctx, cta, { type, text: linkText, blockId });
</script>

{#if target || showEmpty}
  <!-- We skip the href here bedcause we pass it through hrefAttrs -->
  <!-- svelte-ignore a11y-missing-attribute-->
  <a target="_blank" class:skip-tracking={/* skip-classic-tracking */ true} {...$$restProps} {...hrefAttrs}>
    <slot href={target || "#"} />
  </a>
{:else}
  <slot />
{/if}
