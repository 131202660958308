<script lang="ts">
  import type { schema } from "@editor/schema";

  export let block: schema.VideoBlock;

</script>

<pre class="w-full bg-white">
No Web renderer for {block.target}
</pre>
