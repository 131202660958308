<script lang="ts">
  export let value: string | undefined = undefined;
  export let limit: number | undefined = undefined;

  $: left = limit ? limit - (value || "").length : 0;
</script>

<span
  class:invisible={!limit}
  class="flex-shrink-0 mx-1 text-xs leading-none pointer-events-none char-counter"
  class:text-gray-500={left > 9}
  class:text-orange-600={left <= 9 && left >= 0}
  class:text-red-600={left < 0}
  class:font-medium={left <= 9}>
  {left}
</span>

<style>
  span {
    opacity: var(--opacity);
  }
</style>
