<script lang="ts">
  export let active = false;

  let el: HTMLElement, parent: HTMLElement | null, sib: ChildNode | null;
  const body = document.body;

  function activate() {
    parent = el.parentElement;
    sib = el.previousSibling;
    body.appendChild(el);
  }

  function deactivate() {
    if (sib) {
      sib.after(el);
      sib = parent = null;
      return;
    }

    if (parent) {
      parent.prepend(el);
      sib = parent = null;
    }
  }

  $: if (el) {
    active ? activate() : deactivate();
  }

</script>

<div bind:this={el} style="display:contents">
  <slot />
</div>
