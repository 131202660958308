export type Position = string;

/***
 *
 *  const p = poser<{id:string, $pos: string}>("id", "$pos");
 *
 *  p.sort(designs.bg)
 *
 *  // move item id 1234 to be after item id 222
 *  p.move(designs.bg, "1234", "222")
 *
 *
 *
 */
export function poser<TOrderable extends object>(idKey: keyof TOrderable, posKey: keyof TOrderable) {
  // extract position from TOrderable
  function pos(t: TOrderable | undefined): Position | undefined {
    const p = t && t[posKey];

    return p ? (p as unknown as Position) : undefined;
  }

  function id(t: TOrderable): string {
    return t[idKey] as unknown as string;
  }

  function compare(a: TOrderable, b: TOrderable) {
    const [posA, posB] = [pos(a), pos(b)];

    if (!posA || (!posA && posB)) {
      return 1;
    }

    if (!posB || (!posB && posA)) {
      return -1;
    }

    if (!posA && !posB) {
      return 0;
    }

    if (posA < posB) {
      return -1;
    }

    if (posA === posB) {
      return 0;
    }

    return 1;
  }

  function sort(arr: TOrderable[]): TOrderable[] {
    return arr.sort(compare);
  }

  function calcNewPosition(arr: TOrderable[], itemId: string, insertAfterId?: string): Position {
    const idIdx = arr.findIndex((item) => id(item) === itemId);
    const insertAfterIdx = arr.findIndex((item) => id(item) === insertAfterId);

    // shortcircut - id is already after insertAfterId:
    if (insertAfterIdx > 0 && idIdx > 0 && idIdx - insertAfterIdx === 1) {
      return pos(arr[idIdx])!; // return the current position: ;
    }

    const lowerPos = arr[insertAfterIdx] ? pos(arr[insertAfterIdx]) : undefined;
    const upperPos = arr[insertAfterIdx + 1] ? pos(arr[insertAfterIdx + 1]) : undefined;

    return calcFractionalIndex(lowerPos, upperPos);
  }

  // perform the move + update the location of the new item
  function moveById(arr: TOrderable[], itemId: string, insertAfterId?: string): Position {
    const i = arr.find((item) => id(item) === itemId);

    // @ts-ignore
    const p = (i[posKey] = calcNewPosition(arr, itemId, insertAfterId));

    // sort the array
    sort(arr);
    return p;
  }

  // move given items to position after insertId,
  //
  function insert(arr: TOrderable[], items: TOrderable[], insertAfterId?: string): Position[] {
    // start by reversing it so we can push all the items in
    const toInsert = [...items].reverse();

    const r = toInsert
      .map((item) => {
        // do we have the item?
        if (!arr.find((i) => id(i) === id(item))) {
          arr.push(item);
        }

        return moveById(arr, id(item), insertAfterId);
      })
      .reverse();

    return r;
  }

  // // perform insert + getting the position for it
  // function insert(items: TOrderable[], item: TOrderable, insertAfterId?: string): Position {
  //   // @ts-ignore
  //   const p = (item[posKey] = calcNewPosition(items, id(item), insertAfterId));

  //   items.push(item);
  //   sort(items);
  //   return p;
  // }

  // take a SORTED array and update the positions for all the items, using the most minimal encoding.
  function normalize(arr: TOrderable[]): TOrderable[] {
    const i = iter();

    for (let item of arr) {
      // @ts-ignore
      item[posKey] = i.next();
    }

    return arr;
  }

  function iter() {
    let idx = 0;
    let lastPos: string = "";
    // a shitty stupid indexes, first 92 blocks get simple indexes, from there after we need to calculate that

    return {
      next() {
        lastPos = idx <= 92 ? String.fromCharCode(33 + idx++) : calcFractionalIndex(lastPos);
        return lastPos;
      }
    };
  }

  return {
    pos,
    compare,
    sort,
    calcNewPosition,
    normalize,
    iter,
    insert,
    moveById
  };
}

/****
 *
 * Calc fractional index
 *
 */

const MAX = 126;
const MIN = 32;

function calcFractionalIndex(lower?: string, upper?: string) {
  let result = "";

  // lower bound
  if (!lower) {
    lower = String.fromCharCode(MIN);
  }

  if (!upper) {
    upper = String.fromCharCode(MAX);
  }

  if (lower === upper) {
    throw new Error("Both indexes are equal!");
  }

  if (upper < lower) {
    throw new Error(`Upper bound '${upper}'(${upper.charCodeAt(0)}) is smaller than '${lower}' (${lower.charCodeAt(0)})`);
  }

  const maxLength = Math.max(lower.length, upper.length);
  for (let i = 0; i <= maxLength; i++) {
    const lowBound = Math.max(lower.charCodeAt(i) || MIN, MIN);
    const upperBound = Math.min(upper.charCodeAt(i) || MAX, MAX);
    const diff = upperBound - lowBound;

    // Same byte -> Add to the result and continue
    if (diff < 2) {
      // Disable early exit as it causes issues with fuzzyness
      // if (diff !== 0) {
      //   const upResult = result + String.fromCharCode(upperBound);
      //   if (upResult < upper && upResult > lower) {
      //     return upResult;
      //   }
      // }
      result += String.fromCharCode(lowBound);
      continue;
    }

    // Else, diff is bigger than 1:
    result += String.fromCharCode(lowBound + Math.floor(diff / 2));
    return result;
  }

  return result;
}
