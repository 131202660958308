<script lang="ts">
  import { cmdRun, cmdState, cmdEnabled, querySelectedLink, doUnlink, saveSelection, restoreSelection } from "./commands";
  import { keyDown } from "@ui/keyDown";
  import { renderHTML, domToRichText, trimHtml, autoLinkify, coerceUrl, toText } from "./richText";
  import type { RichText } from "./richText";
  import { createEventDispatcher, onMount } from "svelte";
  import { lastMutatedStore, midEllipsis } from "./utils";
  import { clickOutside } from "@ui/clickOutside";
  import isEqual from "lodash.isequal";
  import LinkEditor from "./LinkEditor.svelte";
  import EmojiPickerButton from "@editor/app/blocks/form/EmojiPickerButton.svelte";
  import { isRTL } from "sbelt/text";
  import { getCurrentUser } from "@ui/currentUser";

  export let showImage: boolean = false;
  // value that external users can bind to
  export let value: RichText = "";
  export let placeholder: string = "";
  export let isEmpty: boolean = !value || !value.length;
  export let hideJustifyButtons: boolean = false;

  let isRichTextRtl = false;

  $: isRichTextRtl = isRTL(toText(value));

  let initialHTMLValue = renderHTML(value);

  let lastValue: RichText = value;
  $: if (!isEqual(lastValue, value)) {
    initialHTMLValue = renderHTML(value); // store our initial value here, so we can render it only once
  }
  const dispatcher = createEventDispatcher();
  const currentUser = getCurrentUser();

  let contentDiv: HTMLDivElement;
  let active: boolean = false;
  let selectedLink: HTMLAnchorElement | undefined;
  let selectedLinkRect: DOMRect | undefined;
  let linkEditor: LinkEditor | undefined;
  let isFullscreen: boolean = false;

  export function focus() {
    contentDiv.focus();
  }

  function updateValue() {
    const target = contentDiv.cloneNode(true) as HTMLElement;
    trimHtml(target);
    if (!$currentUser.caps.disable_auto_linkify_in_rich_text_editor) {
      autoLinkify(target);
    }
    lastValue = value = domToRichText(target);
  }

  function getCmdState() {
    return {
      bold: cmdState("bold"),
      italic: cmdState("italic"),
      underline: cmdState("underline"),
      unlink: cmdState("unLink"),
      justifyLeft: cmdState("justifyLeft"),
      justifyRight: cmdState("justifyRight"),
      justifyCenter: cmdState("justifyCenter"),
      justifyFull: cmdState("justifyFull"),
      addImage: cmdState("addImage"),
      isInList: cmdState("insertunorderedList"),
      undo: cmdEnabled("undo"),
      redo: cmdEnabled("redo")
    };
  }

  let activeCmds = getCmdState();

  function queryCmds() {
    // check if empty on the next tick
    window.requestAnimationFrame(queryEmpty);

    activeCmds = getCmdState();
    selectedLink = querySelectedLink();
  }

  function queryEmpty() {
    isEmpty = !contentDiv || !contentDiv.textContent?.trim().length;
  }

  function cmd(name: string, saveSel: boolean = false) {
    return (ev: KeyboardEvent | MouseEvent) => {
      // const s = saveSelection();

      ev.stopImmediatePropagation();
      ev.preventDefault();

      cmdRun(name);
      contentDiv.focus();
      queryCmds();

      // saveSel && restoreSelection(s);
    };
  }

  function tabKeyHandler(e: KeyboardEvent) {
    // if we're not inside a list, do nothing
    if (!activeCmds.isInList) {
      cmd("insertunorderedlist")(e);
      return;
    }

    return cmd(e.shiftKey ? "outdent" : "indent")(e);
  }

  // Github issue #6535 (https://github.com/smore-inc/smore/issues/6535)
  // From some reason, I can't tell why, the cmd+u is not working as other formatting commands
  // So I've added this explicitlly
  function underlineHandler(e: KeyboardEvent) {
    // if we're inside a list, do nothing
    return cmd("underline")(e);
  }

  const BTN_CLS =
    "flex items-center m-px justify-center border-transparent w-6 h-6 rounded border hover:border-gray-200 active:bg-blue-400";

  // force no-css styling
  try {
    document.execCommand("styleWithCSS", false, "false");
  } catch (e) {
    try {
      document.execCommand("useCSS", false, "false");
    } catch (e) {}
  }

  function onToolbarClicked(e: MouseEvent) {
    e.stopImmediatePropagation();
    e.preventDefault();
    contentDiv.focus();
  }

  function addImageClicked() {
    dispatcher("upload-image");
  }

  function updateSelectedLinkRect() {
    selectedLinkRect = selectedLink?.getBoundingClientRect();
    // make sure that if we're scrolling OUT of the bounding box, we hide our rect
    if (selectedLinkRect) {
      const b = contentDiv.getBoundingClientRect();

      if (b.bottom < selectedLinkRect.bottom || b.top > selectedLinkRect.bottom + 20) {
        selectedLinkRect = undefined;
      }
    }
  }

  // update our rect if there's a link
  $: if (selectedLink) {
    updateSelectedLinkRect();
  }

  function editSelectedLink() {
    if (!selectedLink) {
      return;
    }
    const l = selectedLink;

    linkEditor!.openForEdit(l.href, "update", (r) => {
      if (!r) {
        return;
      }

      l.href = r;

      // update our tool
      selectedLink = undefined;
      selectedLink = l;
    });
  }

  function insertEmoji(e: any) {
    const selectedEmoji = e.detail;

    if (!selectedEmoji) {
      return;
    }

    contentDiv.focus();

    isEmpty = false;

    document.execCommand("insertText", false, selectedEmoji);

    // collapse selection to end
    const sel = window.getSelection();
    sel?.collapseToEnd();
  }

  function insertLink(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();

    // if we're inside a selected link, just edit it
    if (selectedLink) {
      editSelectedLink();
      return;
    }

    const selection = saveSelection();

    linkEditor!.openForEdit("", "insert", (url) => {
      if (!url) {
        return;
      }

      contentDiv.focus();

      restoreSelection(selection);

      if (selection?.isCollapsed) {
        // handle the case of no selection
        document.execCommand("insertHTML", false, `<a href="${url}">${url}</a>`);
      } else {
        document.execCommand("createLink", false, url);
      }

      // collapse selection to end
      const sel = window.getSelection();
      sel?.collapseToEnd();
    });
  }

  // toggle the isFullscreen class on body to prevent scrollbars
  $: document.body.parentElement!.classList.toggle("isFullscreen", isFullscreen);

  onMount(() => {
    // subscribe to mutation changes, debounce 200ms
    const unsubscribe = lastMutatedStore(contentDiv, 200).subscribe(updateValue);

    // update selected link position on scroll
    contentDiv.addEventListener("scroll", updateSelectedLinkRect, { passive: true });
    window.addEventListener("scroll", updateSelectedLinkRect, { passive: true });

    return () => {
      unsubscribe();
      window.removeEventListener("scroll", updateSelectedLinkRect);
      contentDiv.removeEventListener("scroll", updateSelectedLinkRect);
    };
  });

  function handlePaste(event: ClipboardEvent) {
    const content = (event.clipboardData || window.clipboardData).getData("text/html").replace(/data-id="((?!").)*"/gi, "");
    if (!content) {
      return;
    }

    event.preventDefault();
    const selectionRange = window.getSelection()?.getRangeAt(0);
    selectionRange?.deleteContents();
    document.execCommand("insertHTML", false, content);
  }
</script>

<!-- disable scrolling when in full screen -->

<div
  class="relative p-0 border border-gray-200 rounded fj-container text-14 form-input"
  class:isFullscreen
  use:keyDown={[
    { key: "tab", handler: tabKeyHandler },
    { key: "k", handler: insertLink, mod: "cmd" }
  ]}
  use:clickOutside={() => (selectedLink = undefined)}>
  <LinkEditor bind:this={linkEditor} />

  <!-- Selected link bar -->
  {#if selectedLink && selectedLinkRect}
    <div
      class="fixed flex px-1.5 py-0.5 text-sm bg-white border rounded shadow items-center mt-1 z-10"
      style="top: {selectedLinkRect.bottom}px; left: {selectedLinkRect.left}px">
      <span
        >Links to: <a href={coerceUrl(selectedLink.href)} target="_blank" class="text-blue-600 underline"
          >{midEllipsis(selectedLink.href)}</a>
      </span>
      <i class="ml-0.5 leading-none text-gray-600 material-icons md-18">open_in_new</i>

      <div class="ml-1 mr-2 text-gray-300">|</div>
      <button type="button" class="flex items-center justify-center clickable hover:text-blue-600" on:click={editSelectedLink}
        >Change</button>
      <div class="mx-2 text-gray-300">|</div>
      <button
        type="button"
        class="flex items-center justify-center clickable hover:text-red-500"
        on:mousedown|preventDefault|stopPropagation={() => {
          /* do nothing here, but we want to prevent mouse down to not steal the selection */
        }}
        on:click|preventDefault|stopPropagation={() => {
          doUnlink();
          selectedLink = undefined;
        }}>Remove</button>
    </div>
  {/if}
  <!-- Toolbar -->
  <div class="flex items-center p-1 border-b rounded-t bg-gray-50 fj-toolbar text-nxgray-700" on:mousedown={onToolbarClicked}>
    <!-- bold -->
    <button class={BTN_CLS} class:bg-teal-300={activeCmds.bold} on:click={cmd("bold")}
      ><i class="material-icons md-18">format_bold</i></button>
    <!-- italic -->
    <button class={BTN_CLS} class:bg-teal-300={activeCmds.italic} on:click={cmd("italic")}
      ><i class="material-icons md-18">format_italic</i></button>
    <!-- underline -->
    <button class={BTN_CLS} class:bg-teal-300={activeCmds.underline} on:click={cmd("underline")}>
      <i class="material-icons md-18">format_underline</i>
    </button>

    <!-- Link -->
    <button class={BTN_CLS} on:click={insertLink}>
      <i class="material-icons md-18">link</i>
    </button>

    <div class="w-px h-4 mx-2 bg-gray-200" />

    <!-- ul -->
    <button class={BTN_CLS} on:click={cmd("insertunorderedList", true)}><i class="material-icons md-18">format_list_bulleted</i></button>
    <!-- ol -->
    <button class={BTN_CLS} on:click={cmd("insertorderedList", true)}><i class="material-icons md-18">format_list_numbered</i></button>

    <div class="w-px h-4 mx-2 bg-gray-200" />
    {#if !hideJustifyButtons}
      <!-- left -->
      <button class={BTN_CLS} class:bg-teal-300={activeCmds.justifyLeft} on:click={cmd("justifyLeft")}>
        <i class="material-icons md-18">format_align_left</i>
      </button>
      <!-- center -->
      <button class={BTN_CLS} class:bg-teal-300={activeCmds.justifyCenter} on:click={cmd("justifyCenter")}>
        <i class="material-icons md-18">format_align_center</i>
      </button>
      <!-- right -->
      <button class={BTN_CLS} class:bg-teal-300={activeCmds.justifyRight} on:click={cmd("justifyRight")}>
        <i class="material-icons md-18">format_align_right</i>
      </button>
      <!-- justify-full -->
      <button class={BTN_CLS} class:bg-blue-400={activeCmds.justifyFull} on:click={cmd("justifyFull")}>
        <i class="material-icons md-18">format_align_justify</i>
      </button>
      <div class="w-px h-4 mx-2 bg-gray-200" />
    {/if}

    <!-- Emoji Picker -->
    <EmojiPickerButton inlineStyle={true} on:selected={insertEmoji} />

    {#if showImage}
      <div class="w-px h-4 mx-2 bg-gray-200" />

      <!-- addImage -->
      <button class={BTN_CLS} class:bg-teal-300={activeCmds.addImage} on:click|stopPropagation|preventDefault={addImageClicked}>
        <i class="material-icons md-18">add_a_photo</i>
      </button>
    {/if}

    <!-- spacing -->
    <div class="flex-grow" />

    <!-- Fullscreen mode -->
    <button class={BTN_CLS} class:bg-teal-300={isFullscreen} on:click|stopPropagation|preventDefault={() => (isFullscreen = !isFullscreen)}>
      <i class="material-icons md-18">fullscreen</i>
    </button>

    <!-- Undo / redo -->
    <button class={BTN_CLS} class:opacity-50={!activeCmds.undo} class:pointer-events-none={!activeCmds.undo} on:click={cmd("undo")}>
      <i class="material-icons md-18">undo</i>
    </button>
    <!-- justify-full -->
    <button class={BTN_CLS} class:opacity-50={!activeCmds.redo} class:pointer-events-none={!activeCmds.redo} on:click={cmd("redo")}>
      <i class="material-icons md-18">redo</i>
    </button>
  </div>
  <span class="absolute pl-px m-1 text-gray-300 pointer-events-none empty-placeholder" class:hidden={!isEmpty}>
    {placeholder}
  </span>
  <!-- Following attributes are in order to disable Grammarly chrome extension which breaks our rich text formating
    see issue #6461
    
    data-gramm="false"
    data-gramm_editor="false"
    data-enable-grammarly="false"
  -->
  <div
    use:keyDown={[{ key: "u", handler: underlineHandler, mod: "cmd" }]}
    data-gramm="false"
    data-gramm_editor="false"
    data-enable-grammarly="false"
    class="fj-content"
    align={isRichTextRtl ? "right" : "left"}
    contenteditable={true}
    dir={isRichTextRtl ? "rtl" : "ltr"}
    data-placeholder={placeholder}
    bind:this={contentDiv}
    on:focus={() => (active = true)}
    on:blur={() => (active = false)}
    on:click={() => !active && contentDiv.focus()}
    on:keydown={queryCmds}
    on:mouseup={queryCmds}
    on:keyup={queryCmds}
    on:paste={handlePaste}>
    {@html initialHTMLValue}
  </div>
  <slot />
</div>

<style>
  :global(html.isFullscreen) {
    overflow-y: hidden;
  }

  /* Full screen mode yay */
  .fj-container.isFullscreen {
    position: fixed;
    background: #fff;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 100;
  }

  .fj-container.isFullscreen .fj-content {
    padding-right: calc(50% - 300px);
    padding-left: calc(50% - 300px);
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: none !important;
    flex-grow: 1;
    overflow-y: scroll;
  }

  .fj-container.isFullscreen .empty-placeholder {
    padding-right: calc(50% - 300px);
    padding-left: calc(50% - 300px);
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 35px 0;
  }

  .fj-container.isFullscreen .fj-toolbar {
    padding-right: calc(50% - 300px);
    padding-left: calc(50% - 300px);
  }

  .fj-content {
    padding: 0.25rem;
    min-height: 60px;
    max-height: 350px;
    overflow-y: auto;
    outline: none;
    cursor: text;
  }

  .form-input {
    --border-focus: 0 0 0 4px #dcf4f1;
  }

  .form-input:focus-within {
    outline: none;
    box-shadow: var(--border-focus);
    border-color: theme("borderColor.teal.400");
  }

  .form-input.error {
    border-color: #ad282585;
  }
  .form-input.error:focus-within {
    border-color: #ad2825;
    box-shadow: 0 0 0 4px #f9e7e7;
  }

  .form-input::selection {
    background-color: var(--selection-color);
  }

  .form-input::-moz-selection {
    background-color: var(--selection-color);
  }

  .fj-content :global(b),
  .fj-content :global(strong) {
    font-weight: bold;
  }

  .fj-content :global(i),
  .fj-content :global(em) {
    font-style: italic;
  }

  .fj-content :global(ul),
  .fj-content :global(ol) {
    padding-left: 30px;
  }

  .fj-content :global(ul li) {
    list-style: disc;
  }

  .fj-content :global(ol li) {
    list-style: decimal;
  }

  .fj-content :global(a) {
    text-decoration: underline;
    color: #1c6e98;
    cursor: text;
  }

  .fj-content :global(p[align="left"]) {
    text-align: left !important;
  }

  .fj-content :global(p[align="right"]) {
    text-align: right !important;
  }

  .fj-content :global(p[align="center"]) {
    text-align: center !important;
  }

  .fj-content :global(p[align="justify"]) {
    text-align: justify !important;
  }
</style>
