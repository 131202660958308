<!-- 

    A helper that would open the file select when clicked. 

    Will dispatch a "files" event 

    has an "open" function that can be called from the outisde
 -->
<script lang="ts">
  import { randId } from "../id";
  import { createEventDispatcher } from "svelte";

  export let disabled = false;
  export let accept: string | undefined = undefined;
  export let multiple = false;
  export let files: File[] | undefined = undefined;

  let el: HTMLInputElement | undefined = undefined;
  const id = randId("fs");
  const dispatch = createEventDispatcher();

  function change() {
    if (!el) return;
    files = Array.from(el.files || []);
    files.length && dispatch("files", files);
  }

  export function open() {
    el && el.click();
  }
</script>

<input type="file" {accept} {multiple} {disabled} {id} style="display:none" on:change={change} bind:this={el} />

<label for={id} class:disabled class="block-no-move">
  <slot />
</label>

<style>
  label {
    display: contents;
    cursor: pointer;
  }
  .disabled {
    cursor: default;
  }
</style>
