<script lang="ts">
  import { getNewsletterStores } from "../../../newsletterStores";

  const { ctx } = getNewsletterStores();
  const useOutlookComments = ctx.dialect !== "non_standard_mns";
</script>

{#if useOutlookComments}
  {@html "<![endif]-->"}
{/if}
