<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./EmailBlock.svelte";
  import { getNewsletterStores } from "../../newsletterStores";
  import { coerceUri, LinkContent } from "../utils";
  import { unescapeHtml } from "@ui/textUtils";

  export let block: schema.EmbedLinkBlock;
  const { design, ctx } = getNewsletterStores();

  const cta = { url: coerceUri(block.url), id: `link-${block._id}` };
  const unescapedTitle = unescapeHtml(block.title || "");
  const unescapedDescription = unescapeHtml(block.description || "");
</script>

<Block {block} let:layout>
  {#if layout === "lg"}
    <table
      role="presentation"
      cellspacing="0"
      cellpadding="0"
      border="0"
      width="700"
      style="margin:0 !important;width:100%; max-width:700px">
      <tr>
        <td style="display:block;border:1px solid #ccc;border-radius:4px;vertical-align: middle;">
          <table role="presentation" cellspacing="0" cellpadding="0" border="0" style="margin:0 !important;width:100%;min-height:240px;">
            <tr>
              <td align="center">
                <LinkContent {cta} type="link" linkText={unescapedTitle}>
                  <img src={block.thumbnail_url} alt={unescapedTitle} style="width:100%;display:block;" width="590" />
                </LinkContent>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <LinkContent {cta} type="link" linkText={unescapedTitle}>
            <h3
              style="font-family:sans-serif;font-weight:bold;font-size:16px;line-height:1.25;color:{$design.background.dark
                ? '#fff'
                : $design.color['text.title'].text};margin:16px 0 0 0 !important;">
              {unescapedTitle}
            </h3>
            <p
              style="font-family:sans-serif;font-size:15px;color:{$design.background.dark
                ? '#fff'
                : $design.color['text.title'].text};margin-top:4px !important;">
              {unescapedDescription}
            </p>
          </LinkContent>
          {#if block.host}
            <table role="none" cellspacing="0" cellpadding="0" border="0" style="margin:0 !important;">
              <tr>
                <td width="18" valign="top">
                  <img src="https://cdn.smore.com/images/outgoing_link.png" width="18" height="18" alt="Outgoing link icon" />
                </td>
                <td width="4" />
                <td valign="top">
                  <LinkContent {cta} type="link" linkText={unescapedTitle}>
                    <span
                      style="font-family:sans-serif;font-size:13px;color:{$design.background.dark
                        ? '#fff'
                        : $design.color['text.title'].text}">
                      {block.host.replace("www.", "")}
                    </span>
                  </LinkContent>
                </td>
              </tr>
            </table>
          {/if}
        </td>
      </tr>
    </table>
  {:else}
    <table role="presentation" cellspacing="0" cellpadding="0" border={0} style="margin:0 !important;padding:0 !important;" align="left">
      <tr>
        {#if block.thumbnail_url}
          <td valign="top" width="192" height="130" style="max-height:130px;width:192px;">
            <div style="border:1px solid #ccc;border-radius:4px;">
              <LinkContent {cta} type="link" linkText={unescapedTitle} style="display:block;">
                <img src={block.thumbnail_url} alt={unescapedTitle} style="width:100%;display:block;" width="200" />
              </LinkContent>
            </div>
          </td>
        {/if}
        <td valign="top" style="vertical-align:top;padding-left:16px;">
          <table role="presentation" cellspacing="0" cellpadding="0" border={0} style="margin:0 !important;padding:0 !important;">
            <tr>
              <td valign="top" style="vertical-align:top;padding:0;margin:0;">
                <LinkContent {cta} type="link" linkText={unescapedTitle} style="display:block;">
                  <p
                    style="font-weight:bold;font-size:16px;color:{$design.background.dark
                      ? '#fff'
                      : $design.color['text.title'].text};margin:0 !important;padding:0 !important;font-family:sans-serif;">
                    {unescapedTitle}
                  </p>
                  <p
                    style="font-family:sans-serif;font-size:15px;color:{$design.background.dark
                      ? '#fff'
                      : $design.color['text.title'].text};margin:0 !important;padding:4px 0 16px 0 !important">
                    {unescapedDescription}
                  </p>
                </LinkContent>
                {#if block.host}
                  <table role="none" cellspacing="0" cellpadding="0" border="0" style="margin:0 !important;">
                    <tr>
                      <td width="18" valign="top">
                        <img src="https://cdn.smore.com/images/outgoing_link.png" width="18" height="18" alt="Outgoing link icon" />
                      </td><td width="4" />
                      <td valign="top">
                        <LinkContent {cta} type="link" linkText={unescapedTitle}>
                          <span
                            style="font-family:sans-serif;font-size:13px;color:{$design.background.dark
                              ? '#fff'
                              : $design.color['text.title'].text}">
                            {block.host.replace("www.", "")}
                          </span>
                        </LinkContent>
                      </td></tr>
                  </table>
                {/if}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  {/if}
</Block>
