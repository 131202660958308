<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { IconsMap } from "./icons";
  import type { schema } from "@editor/schema";
  import { fly } from "svelte/transition";

  export let isEditing: boolean = false;
  export let block: schema.Block;
  export let layouts: schema.BlockLayout[];
  export let previewLayout: undefined | schema.BlockLayout;

  let isOpen = false;
  const dispatch = createEventDispatcher();

  function getVariationIconName(layout: schema.BlockLayout) {
    return IconsMap[`${block._t}.${layout}`];
  }

  function cancel(e: MouseEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    dispatch("cancel");
    previewLayout = undefined;
    isOpen = false;
  }

  const preview = {
    commit(newLayout: schema.BlockLayout) {
      isOpen = false;
      dispatch("layout_changed", { newLayout });
    },

    clear() {
      previewLayout = undefined;
    },

    set(newLayout: schema.BlockLayout) {
      previewLayout = newLayout;
    }
  };

  function open() {
    isOpen = true;
    dispatch("open");
  }

  function getCaption() {
    switch (block._t) {
      case "logo":
        return "Size & Layout";
      case "text.title":
      case "misc.separator":
        return "Size";
      case "button":
        return "Size";

      default:
        return "Layout";
    }
  }
</script>

{#if isOpen}
  <button class="fixed inset-0 z-20 cursor-default bg-transparent" on:click|capture|preventDefault|stopPropagation={cancel} />
{/if}
<button class="edit-item relative flex items-center justify-center self-center" on:click|preventDefault|stopPropagation={open}>
  {#if isOpen}
    <!-- This wrapper is used to allow the user move the mouse around and near the buttons without hiding them -->
    <div
      style="z-index: 205"
      class="layout-selector absolute -m-3 -mt-3 flex cursor-default flex-col bg-opacity-20"
      in:fly={{ duration: 150, y: -4, opacity: 0.9 }}
      out:fly={{ duration: 50, y: -4, opacity: 0.9 }}>
      <div class="flex rounded bg-black bg-opacity-90 px-1 py-2 shadow">
        <slot {preview} />
        <div class="flex">
          {#each layouts as variation}
            <div
              class="clickable scale-in group h-10 w-12 px-1"
              on:click|preventDefault|stopPropagation={() => preview.commit(variation)}
              on:mouseenter={() => preview.set(variation)}
              on:mouseleave={preview.clear}>
              <div
                class:bg-gray-100={variation === block._l}
                class:bg-nxgray-600={variation !== block._l}
                class:group-hover:bg-nxgray-500={variation !== block._l}
                class="flex h-full w-full items-center justify-center rounded bg-center bg-no-repeat transition-all"
                style="background-image: url({getVariationIconName(variation)})" />
            </div>
          {/each}
        </div>
      </div>
      <i class="material-icons md-24 absolute bottom-0 left-1/2 mx-auto -mb-3.5 -ml-3 text-nxgray-700">arrow_drop_down</i>
    </div>
  {/if}
  <div
    class:bg-green-700={isOpen}
    class:bg-brand-gray-80={!isOpen}
    class="clickable scale-in mr-1 flex h-6 cursor-pointer items-center justify-center self-center rounded px-2 text-sm text-white transition-colors hover:bg-green-700 hover:text-white">
    {getCaption()}
  </div>
</button>

<style>
  .layout-selector {
    top: -3rem;
  }
</style>
