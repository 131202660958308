<script context="module" lang="ts">
  import type {schema} from "@editor/schema";
  import Tooltip from "@ui/Tooltip.svelte"

  export type UserLike = {
    id:string,
    name: string,
    avatar?:string;
    joined?: number;
    online?: boolean;
    lastSeen?: number; // date
    devices?: Record<string, schema.EditingDevice>;
  }

  function calcInitials(name: string){
    if(!name){
      return "?"
    }
    
    const parts = name.split(/\s+/g);
    
    // take the last name if we have one
    return `${parts[0][0]}${parts.length > 1 ? parts[parts.length-1][0] : ""}`
  }

</script>


<script lang="ts">

  export let user: UserLike
  export let size: "sm" | "md" | "lg" = "md";

  $: initials = calcInitials(user.name)

</script>


<div class="relative bg-orange-600 rounded-full avatar-{size} avatar" >
  <div class="absolute inset-0 flex items-center justify-center overflow-hidden text-center text-white opacity-100 text-12">
    {initials}
  </div>
  {#if user.avatar}
    <img class="absolute inset-0 z-20 overflow-hidden rounded-full" alt={user.name} src={user.avatar} />
  {/if}

  <span class="absolute bottom-0 right-0 z-30 block w-1.5 h-1.5  rounded-full ring-2 ring-white" class:bg-green-700={user.online} class:bg-gray-400={!user.online}></span>

  {#if $$slots.tooltip  }
  <Tooltip delay={50}>
    <slot name="tooltip" {user} {size} />
  </Tooltip>
  {/if}
</div>

<style>
  .avatar{
    width: var(--avatar-size, 32px);
    height: var(--avatar-size, 32px);
  }

  .avatar-sm{
    --avatar-size: 24px;
  }
  
  .avatar-lg{
    --avatar-size: 48px;
  }

</style>