/** @jsx h */

import { Stage, Flex, Layer, Text, Component, h, textShadow } from "../comps";
import type { HeaderProps } from "./types";
import { directifyPadding } from "./utils";

export function heartfelt(props: HeaderProps) {
  const { sub, title, spacing, height, shadow, rtl } = props;

  return (
    <Stage width={700} height={height || 280}>
      <Flex
        padding={directifyPadding([160, 160, 10, 10], rtl)}
        justify="end"
        align={rtl ? "end" : "start"}
        spacing={0 + (spacing || 0)}
        transform={textShadow([8], shadow || "#39081AE5")}
      >
        <Text
          rtl={rtl}
          font={title.font}
          color={title.color}
          maxLines={2}
          limits={title.limits}
          align={rtl ? "right" : "left"}
          fieldKey="title"
          letterSpacing={title.letterSpacing}
          lineHeight={title.lineHeight}
        >
          {title.text}
        </Text>
        {sub.text && (
          <Text
            rtl={rtl}
            color={sub.color}
            font={sub.font}
            maxLines={2}
            limits={sub.limits}
            align={rtl ? "right" : "left"}
            letterSpacing={sub.letterSpacing}
            lineHeight={sub.lineHeight}
            fieldKey="subtitle"
          >
            {sub.text}
          </Text>
        )}
      </Flex>
    </Stage>
  ).toSVG();
}
