import type { Validation } from "./store";

export function requiredTest(msg: string = "Required"): Validation<any> {
  return (v) => {
    if (v === undefined || v === null || (typeof v === "string" && v.trim() === "") || (Array.isArray(v) && !v.length)) {
      return msg;
    }
  };
}

export function lengthTest(limit: number | undefined, msg: string = "too long") {
  return (v: string) => {
    if (v && limit && v.length > limit) {
      return msg;
    }
  };
}

export function patternTest(pattern: string | undefined, msg: string = "doesn't match") {
  const r = pattern ? new RegExp(pattern) : undefined;
  return (v: string) => {
    if (v && r && !r.test(v)) {
      return msg;
    }
  };
}

// TODO: OHAD SHOULD TEST THAT SHIT - parameters + it thinks smore.com. is valid so fix that too
const IS_URL_REGXP =
  /^(?:(?:(?:https?|ftps?):\/\/))?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

// TODO: should support subject + content for this
const IS_MAILTO_REGEXP =
  /^(mailto:)?(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const TEL_URL_REGEXP: RegExp = /^(tel:)?(\+[0-9]{1,3})?([-])?([0-9\-]{7,})$/i;

export function urlTest(msg = "not a valid url", urlOnly: boolean = false) {
  return (v: string) => {
    if (!v) {
      return;
    }
    v = v.trim();

    if (urlOnly && !IS_URL_REGXP.test(v)) {
      return msg;
    }

    if (!IS_URL_REGXP.test(v) && !IS_MAILTO_REGEXP.test(v) && !TEL_URL_REGEXP.test(v)) {
      return msg;
    }
  };
}

export function coerceUri(uri: string | undefined) {
  if (!uri) {
    return "";
  }
  uri = uri.trim();

  var r = /^https?:\/\//i;
  if (r.test(uri)) return uri;

  if (IS_MAILTO_REGEXP.test(uri)) {
    if (!uri.startsWith("mailto:")) {
      uri = "mailto:" + uri;
    }
    return uri;
  }

  if (TEL_URL_REGEXP.test(uri)) {
    if (!uri.startsWith("tel:")) {
      uri = "tel:" + uri;
    }
    return uri;
  }

  return "http://" + uri;
}
