<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./EmailBlock.svelte";
  import { getNewsletterStores } from "../../newsletterStores";
  import { LinkContent, photoToCTA } from "../utils";

  export let block: schema.ImageBlock;
  const { ctx } = getNewsletterStores();
</script>

<Block {block} let:layout size="full">
  {#if block.photo}
    <LinkContent cta={photoToCTA(block.photo)} type="photo" style="display:block;">
      <img
        width="700"
        src={block.photo.legacy_thumb_url}
        alt={block.photo.alt_text}
        style="display:block;overflow:hidden;max-width:100%;max-height:550px;background-color:transparent;object-fit:contain;" />
    </LinkContent>
  {/if}
</Block>
