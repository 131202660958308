<script lang="ts">
  import BlockEditor from "./BlockEditor.svelte";
  import type { schema } from "@editor/schema";
  import TextField from "./form/TextField.svelte";
  import RichTextField from "./form/RichTextField.svelte";
  import DateTimeTZField from "./form/DateTimeTZField.svelte";
  import LocationField from "./form/LocationField.svelte";
  import CollapsibleSection from "@ui/CollapsibleSection.svelte";
  import GoogleMap from "./utils/GoogleMap.svelte";
  import cloneDeep from "lodash.clonedeep";
  import { getEditorStore } from "../editorStore";

  const editorStore = getEditorStore();

  export let block: schema.EventBlock;
  export let focusField: string = "name";

  let draft: schema.EventBlock = cloneDeep(block);

  async function beforeSubmit(data: schema.EventBlock) {
    if (data.location !== block.location) {
      data.staticMapUrl = undefined;
    }
    return data;
  }
</script>

<BlockEditor {draft} on:done on:cancel {beforeSubmit}>
  <div class="flex justify-between">
    <div class="flex-grow">
      <TextField label="Name" limit={100} autofocus required bind:value={draft.name} placeholder="Enter your event name" />
    </div>
    <div class="flex w-48 ml-3">
      <DateTimeTZField label="When?" required bind:value={draft.date} />
    </div>
  </div>

  <div class="flex mt-2">
    <div class="flex-grow">
      <LocationField
        label="Where?"
        placeholder="Enter event's location"
        bind:value={draft.location}
        googlePlacesApiKey={$editorStore.googlePlacesApiKey} />

      <label class="flex items-center mt-2 ml-1 text-gray-600 cursor-pointer text-12">
        <input type="checkbox" bind:checked={draft.hideMapFromEvent} class="mr-2" />
        Don't show map
      </label>

      <label class="flex items-center mt-2 ml-1 text-gray-600 cursor-pointer text-12">
        <input type="checkbox" bind:checked={draft.collectRSVP} class="mr-2" />
        Collect RSVPs to this event
      </label>

      <label class="flex items-center mt-2 ml-1 text-gray-600 cursor-pointer text-12">
        <input type="checkbox" bind:checked={draft.notifyRSVP} class="mr-2" />
        Email me when a new guest RSVPs
      </label>
    </div>
    {#if !draft.hideMapFromEvent}
      <div
        class="relative w-48 ml-3 overflow-hidden text-sm font-normal border rounded shadow-inner text-brand-gray-70"
        style="height:84px;margin-top:22px">
        <GoogleMap address={draft.location} />
      </div>
    {/if}
  </div>

  <CollapsibleSection title="Add event agenda & details" captionClasses="mt-2" icon="info" startOpen={!!block.details} autofocus>
    <div class="mt-3">
      <RichTextField label="Details" bind:value={draft.details} />
    </div>
  </CollapsibleSection>
</BlockEditor>
