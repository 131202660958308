<script lang="ts">
  import { Form } from "@ui/form";
  import { keyDown } from "@ui/keyDown";
  import { UrlField } from "@ui/form/fields";
  import { coerceUrl, EMAIL_URL_REGEXP, TEL_URL_REGEXP, URL_REGEXP } from "./richText";

  type OpenCallback = ((result: string | undefined) => void) | undefined;
  let data = {};
  let showForm: boolean = false;
  let mode: "insert" | "update" = "insert";

  // We use the open callback as our "is open" thing, it will be set by our "openToEdit" exported function
  let openCallback: OpenCallback;

  export function openForEdit(u: string, m: "insert" | "update", cb: OpenCallback) {
    data = { url: u };
    mode = m;

    // if we already have a callback, cancel it
    if (openCallback) {
      close();
    }
    openCallback = cb;

    showForm = true;
  }

  async function submitForm(dataToSave: Record<string, any>) {
    let { url } = dataToSave;
    /*
     * Since we are using hyperlink in the rich text editor for representing the url,
     * in case the user didn't provide the protocol, the link might becore relative to the application
     * mreaning: if the usere types 'smore.com' the link will become 'https://smore.com/app/n/abc/edit/smore.com'
     * Therefore, we identify this case and add 'https://' to the startr of the url to make it absolute.
     */

    if (!URL_REGEXP.test(url) && !EMAIL_URL_REGEXP.test(url) && !TEL_URL_REGEXP.test(url)) {
      url = "https://" + url;
      if (!URL_REGEXP.test(url) && !EMAIL_URL_REGEXP.test(url) && !TEL_URL_REGEXP.test(url)) {
        url = dataToSave.url;
      }
    }

    url = coerceUrl(url);

    openCallback && openCallback(url);
    openCallback = undefined;
    window.requestAnimationFrame(() => {
      showForm = false;
    });
  }

  function close() {
    // cancel
    openCallback && openCallback(undefined);
    openCallback = undefined;
  }

  function closeForm() {
    showForm = false;
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.preventDefault();
      closeForm();
      return false;
    }
  }
</script>

{#if showForm}
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25" use:keyDown={{ key: "Escape", handler: close }}>
    <div class="w-1/4 bg-white border rounded shadow">
      <Form
        bind:data
        submit={submitForm}
        on:cancel={closeForm}
        caption={(mode === "insert" ? "Insert" : "Update") + " Link"}
        submitButtonText={mode === "insert" ? "Insert" : "Update"}>
        <div class="mb-3">
          <UrlField
            key="url"
            name="Link target"
            required
            autofocus
            allowTelephoneNumber={true}
            placeholder="A url, like https://www.google.com"
            on:keydown={handleKeyDown} />
        </div>
      </Form>
    </div>
  </div>
{/if}
