<script lang="ts">
  import TextField from "../form/TextField.svelte";
  import EmojiPickerButton from "../form/EmojiPickerButton.svelte";
  import type { schema } from "@editor/schema";

  export let answer: schema.PollAnswer;
  export let index: number;
  export let autofocus: boolean = false;
  export let canDelete: boolean = false;
  export let required: boolean = false;
  export let error: string | undefined = undefined;

  let inputEl: TextField;

  function deleteClicked() {
    answer.deleted = true;
  }

  function addEmojiToInput(emoji: string) {
    inputEl.insertTextAtCursor(emoji);
  }
  $: hasText = answer.text.trim().length > 0;
  $: if (required && !hasText) {
    error = "Required";
  }
</script>

<TextField
  {error}
  label={undefined}
  bind:this={inputEl}
  bind:value={answer.text}
  placeholder={`Answer ${index + 1}...`}
  {autofocus}
  limit={60}
  {required}>
  <button
    on:click|preventDefault
    slot="beforeInput"
    let:isFocused
    class="items-center mr-1.5 outline-none align-left material-icons text-15 text-nxgray-300 clickable"
    class:hidden={!hasText || isFocused}
    class:flex={hasText && !isFocused}>menu</button>
  <div slot="afterInput" class="flex items-center gap-1 pl-1">
    <EmojiPickerButton on:selected={(e) => addEmojiToInput(e.detail)} />
    {#if canDelete}
      <button
        on:click|preventDefault|stopPropagation={deleteClicked}
        class="block p-1 text-gray-600 border rounded hover:text-red-600 material-icons sm-clickable text-14">
        delete_forever
      </button>
    {/if}
  </div>
</TextField>
