import type { schema } from "@editor/schema";
import type { ColorPack, Background, FontPack, URLImport, DesignDef, ResourcesJson, BackgroundImage, CSSColor, Category } from "./types";

import resources from "./resources.json";
import type { FontDef } from "../app/svgkit/fonts/types";
import merge from "lodash.merge";
import assign from "lodash.assign";
import groupBy from "lodash.groupby";

// setup our default colors
const DEFAULT_COLOR_PACK: ColorPack = {
  "id": "_default",
  "$order": 0,
  "dark": false,
  "thumbnail": {
    primary: "#00A4BC",
    secondary: "#505060"
  },
  "header": {
    bg: "#505060",
    title: "#fff",
    subtitle: "rgba(255,255,255,0.8)"
  },
  "global.text": "#505050",
  "global.link": "#00A4BC",
  "text.title": {
    text: "#505050",
    stripe: "#00A4BC"
  },
  "button": {
    background: "#505060",
    text: "#fff"
  }
};

export const emptyImport = new Promise<{ default: string }>((r) => r({ default: "empty" }));
const BG_DEFAULTS: Background = {
  id: "_default",
  $order: 0,
  assets: [],
  thumbnail: {
    color: "#ccc"
  },
  excludedColorIds: [],
  body: {
    background: "#fff",
    shadow: true
  },
  dark: false
};

export function setupHelpers(designKey: schema.DesignFeelKey) {
  function color(id: string, overrides?: Partial<ColorPack>): ColorPack {
    return {
      ...DEFAULT_COLOR_PACK,
      id,
      ...overrides
    } as ColorPack;
  }

  const bg = {
    full(id: string, overrides?: Partial<Background>): Background {
      const r = staticBg(designKey, id);

      return {
        ...BG_DEFAULTS,
        id,
        thumbnail: {
          image: r["sm"],
          color: "#ccc"
        },
        excludedColorIds: [],
        assets: [{ location: "page-full", type: "image", image: r }],
        ...overrides
      };
    },

    header(id: string, bgColor?: CSSColor, overrides?: Partial<Background>): Background {
      const r = staticBg(designKey, id);

      return {
        ...BG_DEFAULTS,
        id,
        thumbnail: {
          image: r["sm"],
          color: "#ccc"
        },
        excludedColorIds: [],
        assets: [
          { location: "header", type: "image", image: r },
          { location: "page-full", type: "solid", color: bgColor || "#505060" }
        ],
        ...overrides
      };
    },

    combined(id: string, overrides?: Partial<Background>): Background {
      const r = staticBg(designKey, id);

      return {
        ...BG_DEFAULTS,
        id,
        thumbnail: {
          image: r["sm"],
          color: "#ccc"
        },
        excludedColorIds: [],
        assets: [
          { location: "page-full", type: "image", image: r, filters: ["blur"] },
          { location: "header", type: "image", image: r }
        ],
        ...overrides
      };
    },

    override(source: Background, overrides?: Partial<Background>): Background {
      const r = staticBg(designKey, source.id);

      return {
        ...source,
        thumbnail: {
          image: r["sm"],
          color: "#ccc"
        },
        excludedColorIds: [],
        assets: [
          { location: "page-full", type: "image", image: r, filters: ["blur"] },
          { location: "header", type: "image", image: r }
        ],
        ...overrides
      };
    },

    solid(id: string, color: CSSColor, overrides?: Partial<Background>): Background {
      return {
        ...BG_DEFAULTS,
        id,
        thumbnail: {
          color: color
        },
        excludedColorIds: [],
        assets: [
          { location: "page-full", type: "solid", color },
          { location: "header", type: "solid", color }
        ],
        ...overrides
      };
    }
  };

  function helper(id: string): { svg: string; png: string } {
    // @ts-ignore
    const h = resources[designKey].helpers[id];
    if (!h) {
      return { svg: "", png: "" };
    }

    return { svg: toStatic(id, h.svg), png: toStatic(id, h.png) };
  }

  return {
    color,
    bg,
    helper
  };
}

const ROOT = "https://cdn.smore.com/_fr";
function toStatic(id: string, variant: string) {
  return `${ROOT}/${id}.${variant}`;
}
function staticBg<T extends schema.DesignFeelKey>(designKey: T, id: string): BackgroundImage {
  // @ts-ignore
  const bg = resources[designKey].backgrounds[id] as BackgroundImage;
  if (!bg) {
    throw new Error(`Can't find bg ${id} for ${designKey}`);
  }

  return {
    xs: bg.xs,
    hex: bg.hex,
    sm: toStatic(id, bg.sm),
    xl: toStatic(id, bg.xl),
    g: bg.g,
    blur: toStatic(id, bg.blur)
  };
}

export function fontDef(key: keyof typeof resources.fonts): FontDef {
  return resources.fonts[key] as FontDef;
}

export function processLoadedFontsJson(input: any[]): FontPack[] {
  input.forEach((fp) => {
    traverse(fp, (key, value, o) => {
      if (key === "customFont" && typeof value === "string") {
        o[key] = fontDef(value as keyof typeof resources.fonts);
      }
    });
  });

  return input as FontPack[];
}

export function processLoadedBackgroundsJson(input: Category<Background>[], backgrounds: Background[]): Category<Background>[] {
  input.forEach((backgroundCategory) => {
    backgroundCategory.items.forEach((background) => {
      if (backgrounds.find((obackground) => obackground.id === background.id)) {
        assign(
          background,
          backgrounds.find((obackground) => obackground.id === background.id)
        );
      }
    });
  });

  return input as Category<Background>[];
}

export function processLoadedColorsJson(input: any[]): Category<ColorPack>[] {
  const groups = groupBy(
    input.map((c) => merge({}, DEFAULT_COLOR_PACK, c)),
    "$category"
  );

  return Object.keys(groups).map((g) => ({
    name: g,
    items: groups[g].sort(sortItem)
  }));
}

function sortItem(a: any, b: any): number {
  return a.$order - b.$order || a.$id - b.$id;
}

export function traverse(o: any, action: (key: string, v: any, o: any) => void) {
  for (var i in o) {
    if (!!o[i] && typeof o[i] == "object") {
      action(i, o[i], o);
      traverse(o[i], action);
    } else {
      action(i, o[i], o);
    }
  }
}
