<script lang="ts">
  import BlockEditor from "../BlockEditor.svelte";
  import type { schema } from "@editor/schema";
  import TextField from "../form/TextField.svelte";
  import RichTextField from "../form/RichTextField.svelte";
  import PhotoField from "../form/PhotoField.svelte";
  import { isRTL } from "sbelt/text";

  import CTAField from "../form/CTAField.svelte";
  import CollapsibleSection from "@ui/CollapsibleSection.svelte";

  import cloneDeep from "lodash.clonedeep";
  import AlignButton from "../form/AlignButton.svelte";
  import EmojiPickerButton from "../form/EmojiPickerButton.svelte";
  const ResizeInformation = {
    min: { w: 10, h: 10 },
    max: { w: 600, h: 500 },
    default: { w: 192, h: 192 }
  };

  export let block: schema.ParagraphBlock;
  let draft: schema.ParagraphBlock = cloneDeep(block);

  let textField: TextField;

  export let focusField: string = "content";

  async function submitAction(data: schema.ParagraphBlock) {
    if (!block.picture && data.picture && block._l === "-") {
      data._l = "side-r";
    }

    return data;
  }

  let isUploading: boolean = false;

  $: if (isRTL(draft.title)) {
    const a = 1;
  }
</script>

<BlockEditor {draft} on:done on:cancel {submitAction}>
  <TextField
    label="Title"
    autofocus={focusField === "title"}
    bind:value={draft.title}
    placeholder="Enter a title"
    align={draft.align}
    bind:this={textField}>
    <div slot="afterInput" class="flex items-center gap-1 pl-1" let:rtl>
      <AlignButton bind:align={draft.align} {rtl} />
      <EmojiPickerButton on:selected={(e) => textField.insertTextAtCursor(e.detail)} />
    </div>
  </TextField>

  <div class="mt-2">
    <RichTextField
      label="Paragraph"
      required
      autofocus={focusField === "content"}
      bind:value={draft.content}
      placeholder="Paste or enter your content here" />

    <div class="flex mt-2">
      <div
        class:w-full={!draft.picture && !isUploading}
        class:w-48={draft.picture || isUploading}
        class:h-24={draft.picture || isUploading}>
        <PhotoField
          bind:value={draft.picture}
          bind:isUploading
          handoffId="side-photo-{block._id}"
          on:logged_out
          mode="resize"
          ar={ResizeInformation}
          showErrorMessage
          errorMessagePosition="above">
          <!-- Empty -->
          <div
            class="flex items-center justify-center h-12 text-gray-600 transition-all bg-white border-2 border-dashed rounded clickable hover:border-green-600 hover:bg-green-200 hover:text-green-600"
            class:border-green-600={isRelevantDrag}
            class:text-green-600={isRelevantDrag}
            class:bg-green-200={isOver}
            slot="empty"
            let:isOver
            let:isRelevantDrag>
            {#if isRelevantDrag}
              <span class="font-bold text-14">Drop here <span class="font-normal">(to start your upload)</span></span>
            {:else}
              <span class="font-bold text-14">Add a Picture <span class="font-normal">(or drag one in)</span></span>
            {/if}
          </div>

          <!-- Preview -->
          <img
            src={previewUrl}
            class="block object-contain w-full h-24 bg-gray-200 rounded max-w-none"
            slot="preview"
            let:previewUrl
            alt="Photo preview" />
        </PhotoField>
      </div>
      {#if draft.picture}
        <div class="flex-grow mx-2">
          <TextField label="Alt Text" bind:value={draft.picture.alt_text} placeholder="Enter an alt text" />
          <CollapsibleSection
            title="Make this picture linkable"
            captionClasses="mt-2"
            icon="link"
            startOpen={!!draft.picture.cta}
            autofocus>
            <div class="mt-3">
              <CTAField
                label="Target url"
                bind:value={draft.picture.cta}
                placeholder="Enter a URL for the title target (like https://www.google.com)" />
            </div>
          </CollapsibleSection>
        </div>
      {/if}
      {#if !draft.picture && isUploading}
        <div class="flex-grow m-2 text-14">Processing...</div>
      {/if}
    </div>
  </div></BlockEditor>
