<script lang="ts" context="module">
  type MailingList = {
    id: string;
    name: string;
  };
</script>

<script lang="ts">
  import BlockEditor from "./BlockEditor.svelte";
  import type { schema } from "@editor/schema";
  import CollapsibleSection from "@ui/CollapsibleSection.svelte";
  import TextField from "./form/TextField.svelte";
  import SocialProfileField from "./form/SocialProfileField.svelte";
  import RichTextField from "./form/RichTextField.svelte";
  import UrlField from "./form/UrlField.svelte";
  import PhotoField from "./form/PhotoField.svelte";
  import cloneDeep from "lodash.clonedeep";
  import { userStorage } from "@ui/localStorage";
  import { getCurrentUser } from "@ui/currentUser";
  import SubscribeToListField from "./form/SubscribeToListField.svelte";

  export let block: schema.SignatureBlock;
  export let focusField: string = "content";

  let draft: schema.SignatureBlock = cloneDeep(block);

  const currentUser = getCurrentUser();
  const pastSignature = userStorage<Partial<schema.SignatureBlock> | undefined>(`past-signature`, {});

  async function submitAction(d: schema.SignatureBlock) {
    $pastSignature = { ...d, id: $currentUser.id };
    return d;
  }
</script>

<div class="p-2 rounded bg-nxgray-600">
  <BlockEditor {draft} on:done on:cancel {submitAction} let:logged_out>
    <div class="flex items-center justify-start w-full">
      <div class="w-28">
        <PhotoField bind:value={draft.picture} on:logged_out={logged_out} mode="crop" showErrorMessage>
          <!-- preview -->
          <img
            alt={""}
            slot="preview"
            let:previewUrl={preview}
            src={preview}
            class="block object-contain bg-gray-100 rounded-full h-28 w-28 max-w-none" />
          <!-- Empty -->
          <div slot="empty" let:isOver let:isRelevantDrag class="">
            <div
              class="flex flex-col items-center justify-center transition-all bg-gray-300 rounded-full w-28 h-28 hover:bg-green-300 hover:text-green-700"
              class:bg-green-600={isOver}
              class:text-white={isOver}
              class:text-nxgray-400={!isOver}>
              <div class="text-4xl transition-all material-icons notranslate">add_a_photo</div>
              {#if isRelevantDrag}
                <p class="font-medium transition-all text-14">Drop here</p>
              {:else}
                <p class="font-medium transition-all text-14">Add a Picture</p>
              {/if}
            </div>

            {#if isRelevantDrag}
              <div
                class="absolute transition-all border-2 border-dashed rounded-full text-13 inset-2"
                class:border-white={isOver}
                class:border-nxgray-400={!isOver} />
            {/if}
          </div>
        </PhotoField>
      </div>
      <div class="w-full ml-4">
        <TextField label="Name (or title)" bind:value={draft.title} required autofocus limit={40} />
        <div class="mt-3">
          <TextField label="Title or website" limit={80} bind:value={draft.tagline} autocomplete="true" />
        </div>
      </div>
    </div>
    <div class="ml-32">
      {#if !$currentUser.isInsideConnect}
        <div>
          <label class="flex items-center mt-2 ml-1 cursor-pointer text-nxgray-600 text-12">
            <input type="checkbox" bind:checked={draft.contactButton} class="mr-1.5" />
            Contact button
          </label>
        </div>
        <div class="flex flex-col">
          <SubscribeToListField bind:draft />
        </div>
      {/if}
      <CollapsibleSection
        title="Add biography"
        icon="view_headline"
        captionClasses="collapsible-buttons mt-3"
        inline
        autoScroll
        autofocus
        startOpen={!!block.content?.toString().trim()}>
        <div class="mt-3">
          <RichTextField bind:value={draft.content} label="Bio" hideJustifyButtons />
        </div>
      </CollapsibleSection>
      <CollapsibleSection
        title="Add Social"
        icon="contact_mail"
        captionClasses="collapsible-buttons mt-3"
        inline
        autoScroll
        autofocus
        startOpen={!!block.facebook || !!block.twitter || !!block.instagram || !!block.tiktok || !!block.youtube || !!block.pinterest}>
        <div class="grid grid-cols-2 gap-3 my-3">
          <!-- <TextField label="Facebook" bind:value={draft.facebook} /> -->
          <SocialProfileField
            label="Facebook profile"
            preText="facebook.com/"
            bind:value={draft.facebook}
            pattern={`^([a-zA-Z0-9.\/\\-]{5,})$`} />
          <SocialProfileField pattern={`^([a-zA-Z0-9_]{1,15})$`} label="Twitter" bind:value={draft.twitter} icon="alternate_email" />
        </div>
        <div class="grid grid-cols-2 gap-3 my-3">
          <SocialProfileField pattern={`^([a-zA-Z0-9_.-]{3,30})$`} label="Pinterest" bind:value={draft.pinterest} icon="alternate_email" />
          <SocialProfileField pattern={`^([a-zA-Z0-9_.-]{3,24})$`} label="TikTok" bind:value={draft.tiktok} icon="alternate_email" />
        </div>
        <div class="grid grid-cols-2 gap-3 my-3">
          <SocialProfileField pattern={`^([a-zA-Z0-9_.-]{1,30})$`} label="Instagram" bind:value={draft.instagram} icon="alternate_email" />
          <SocialProfileField
            placeholder="channel id"
            pattern={`^([a-zA-Z0-9_.-/@]{1,200})$`}
            label="YouTube URL"
            bind:value={draft.youtube}
            preText="youtube.com/" />
        </div>
        <!-- <div class="mt-3">
          <UrlField label="Website" bind:value={draft.website} icon="public" />
        </div> -->
      </CollapsibleSection>
    </div>
  </BlockEditor>
</div>

<style>
  :global(.collapsible-buttons + .collapsible-buttons) {
    margin-left: theme("margin.2");
  }
</style>
