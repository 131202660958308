<script lang="ts">
  import { getRadioGroupStore } from "./groupStore";

  export let id: string;
  export let hoverover: boolean = false;
  export let value: any;
  export let name: string;
  export let checked: boolean = false;
  export let readonly: boolean = false;

  const store = getRadioGroupStore();

  function valueChanged() {
    $store = value;
  }
</script>

<label for={id} class="flex items-start w-full" on:mouseenter={() => (hoverover = true)} on:mouseleave={() => (hoverover = false)}>
  <input class="accent-blue-700" type="radio" {name} {checked} {id} {value} on:input={valueChanged} disabled={readonly} />
  <slot />
</label>
