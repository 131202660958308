import type { Newsletter, ItemsBlock, BaseBlock, Block } from "../../defs/version_1";
import clonedeep from "lodash.clonedeep";

type BlockFilter = string | ((b: BaseBlock) => boolean);
type BlockMutator<TOld extends BaseBlock, TResult extends Block> = (b: TOld) => TResult;

export function migrateBlocks<TOld extends BaseBlock = BaseBlock, TResult extends Block = Block>(
  c: Newsletter["content"],
  f: BlockFilter,
  mutator: BlockMutator<TOld, TResult>
) {
  // filter existing blocks
  const blocks = c.blocks.filter(typeof f === "function" ? f : (b) => b._t === f).map(clonedeep) as TOld[];
  if (!blocks.length) {
    return;
  }

  // we've found some blocks to mutate, let's go

  replace(c, blocks.map(mutator));
}

// mutate the newsletter content with the new blocks
function replace<TResult extends Block>(c: Newsletter["content"], nbs: TResult[]) {
  nbs.forEach((b) => {
    const idx = c.blocks.findIndex((ob) => ob._id === b._id);
    if (idx > 0) {
      c.blocks[idx] = b;
    }
  });
}
