<script lang="ts">
  import { fade } from "svelte/transition";
  import type { Background } from "@editor/design/types";
  import AssetRenderer from "./BackgroundAsset.svelte";

  export let background: Background;
  export let location: "header" | "page-full";

  // filter the assets and order them by the layer number
  $: assets = background.assets.filter((a) => a.location === location).sort((a, b) => (a.layer || 1) - (b.layer || 1));
</script>

<div class="absolute inset-0 pointer-events-none bg-container ada-hidden">
  {#each assets as asset}
    <AssetRenderer {asset} />
  {/each}
</div>
