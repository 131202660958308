<script lang="ts">
  import BlockEditor from "./BlockEditor.svelte";
  import type { schema } from "@editor/schema";

  import TextField from "./form/TextField.svelte";
  import TextAreaField from "./form/TextAreaField.svelte";
  import CTAField from "./form/CTAField.svelte";

  import CollapsibleSection from "@ui/CollapsibleSection.svelte";

  import cloneDeep from "lodash.clonedeep";

  export let block: schema.ButtonBlock;

  let draft: schema.ButtonBlock = cloneDeep(block);
  export let focusField: string = "text";

  let value: string = "";
</script>

<BlockEditor {draft} on:done on:cancel>
  <TextField
    bind:value={draft.text}
    label="Button label"
    required
    limit={80}
    autofocus={focusField === "text"}
    placeholder="Add a label for your button" />

  <div class="mt-3">
    <CTAField
      label="Button target"
      bind:value={draft.cta}
      required
      placeholder="Enter a URL for the button target (like https://www.google.com)" />
  </div>

  <CollapsibleSection
    title="Add an explanation text to the button"
    captionClasses="mt-2"
    icon="info"
    startOpen={!!block.details || focusField === "details"}
    autofocus>
    <div class="mt-3">
      <TextAreaField
        bind:value={draft.details}
        label="Details"
        limit={250}
        autofocus={focusField === "details"}
        placeholder="Add an explanation text that will show up next to your button" />
    </div>
  </CollapsibleSection>
</BlockEditor>
