/**
 * Converts a number into its ordinal representation.
 * @example
 * 1 -> "1st"
 * 2 -> "2nd"
 * 3 -> "3rd"
 * @param number
 * @returns string
 */
export function toOrdinal(number: number) {
  return number + { e: "st", o: "nd", w: "rd", h: "th" }[new Intl.PluralRules("en", { type: "ordinal" }).select(number)[2]];
}
