<script lang="ts">
  import { getCurrentUser } from "./currentUser";
  import type { ProCaps } from "./currentUser";
  import { fly } from "svelte/transition";

  export let cap: keyof ProCaps;
  export let upgradeReason: string;

  const currentUser = getCurrentUser();

  $: hasCap = $currentUser.caps[cap];
</script>

<div class="relative inline-block w-full parent">
  {#if !hasCap}
    <slot name="locked">
      <div class:pointer-events-none={!hasCap}>
        <slot />
      </div>
      {#if !hasCap}
        <a
          target="_blank"
          href={`/app/reporting/upgrade_click?ur=${upgradeReason}`}
          class="absolute inset-0 flex items-center justify-between px-4 transition-all delay-200 bg-black bg-opacity-75 rounded-md opacity-0 overlay">
          <div class="flex flex-col pr-3">
            <div class="flex items-center justify-start">
              <i class="mr-1 -ml-0.5 text-white opacity-50 material-icons text-21">lock</i>
              <h1 class="font-bold text-white text-15">Upgrade your plan to use this feature</h1>
            </div>
            <p class="mt-1 text-white text-13">Available on our Pro, Premium and Smore for Teams plans</p>
          </div>

          <span
            class="flex items-center justify-center px-6 py-2 font-medium text-white truncate bg-purple-400 rounded shadow clickable text-14 hover:bg-purple-300"
            >Upgrade your plan</span>
        </a>
      {/if}
    </slot>
  {:else}
    <slot />
  {/if}
</div>

<style>
  .parent:hover .overlay {
    opacity: 1;
    backdrop-filter: blur(8px);
    z-index: 999;
  }
</style>
