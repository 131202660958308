<script lang="ts">
  import type { schema } from "@editor/schema";
  import UrlField from "./UrlField.svelte";
  import { randId } from "sbelt/id";

  export let value: schema.CallToAction | undefined = undefined;
  export let label: string | undefined;
  export let required: boolean = false;
  export let icon: string | undefined = "link";
  export let autofocus: boolean = false;
  export let error: string | undefined = undefined;
  export let placeholder: string = "";
  export let disabled: boolean = false;

  let id = value?.id || randId("cta");
  let url: string | undefined = value?.url;

  $: if (url) {
    value = value || { id, url };
    value.url = url;
    value.id = id;
  }

  $: if (!url) {
    value = undefined;
  }
</script>

<UrlField {label} {required} bind:value={url} {placeholder} {disabled} {icon} {autofocus} bind:error urlOnly={false} />
