<script lang="ts">
  import { onMount } from "svelte";
  import FieldLabel from "./FieldLabel.svelte";
  import RichTextEditor from "@ui/rich/RichTextEditor.svelte";
  import type { RichText } from "ui/rich/richText";

  import { field } from "./store";
  import { requiredTest } from "./validations";

  export let value: RichText | undefined;
  export let label: string | undefined;
  export let required: boolean = false;
  export let autofocus: boolean = false;
  export let error: string | undefined = undefined;
  export let placeholder: string = "";
  export let disabled: boolean = false;
  export let hideJustifyButtons: boolean = false;

  let editor: RichTextEditor;
  const { id, input } = field<RichText | undefined>(value, (c) => ([value, error] = c), {
    validate: [required && requiredTest()]
  });
  // update from outside
  $: $input = value;

  if (autofocus) {
    onMount(() => editor.focus());
  }
</script>

<FieldLabel {label} {id} {required} {error}>
  <RichTextEditor bind:value={$input} bind:this={editor} {placeholder} {hideJustifyButtons}>
    <slot />
  </RichTextEditor>
</FieldLabel>
