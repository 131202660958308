import type { version_0 as ver_0, version_1 as ver_1 } from "../defs";

export type { ver_0, ver_1 };

export type AnyVersionNewsletter = ver_0.Newsletter | ver_1.Newsletter;

export type RawServerNewsletter = {
  id: string;
  short: string;
  _v: AnyVersionNewsletter["_v"];
  design: AnyVersionNewsletter["design"];
  js_content: string;
  announcementId?: string;
};

export function isVer1(newsletter: AnyVersionNewsletter): newsletter is ver_1.Newsletter {
  return newsletter && "_v" in newsletter && newsletter._v === 1;
}

export function isVer0(input: AnyVersionNewsletter): input is ver_0.Newsletter {
  return (input && !("_v" in input)) || input._v === 0;
}
