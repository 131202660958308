export function timeout(numberMs: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, numberMs));
}

export function waitForLoad(t: EventTarget) {
  if (t instanceof HTMLImageElement && t.complete) {
    return true;
  }

  return new Promise((resolve, reject) => {
    t.addEventListener("load", resolve);
    t.addEventListener("error", reject);
  });
}

export function makeCancelable<T>(p: Promise<T>): [Promise<T>, () => void] {
  let canceled = false;
  return [
    new Promise<T>((resolve, reject) => {
      p.then((v: T) => {
        if (canceled) {
          reject("canceled");
        } else {
          resolve(v);
        }
      }, reject);
    }),
    () => (canceled = true)
  ];
}

export async function minWait(p: PromiseLike<unknown>, timeMS: number) {
  const [r, _] = await Promise.all([p, new Promise((resolve) => setTimeout(resolve, timeMS))]);
  return r;
}

export async function finishAnimation(el: HTMLElement, subtree: boolean = true): Promise<void> {
  await Promise.all(el.getAnimations({ subtree }).map((animation) => animation.finished));
}