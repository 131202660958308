import { TextComponent, Component, FontDef, Position, Size, LineInfo, glyphIterator, glyphToSVG } from "./base";
import { splitColor } from "./base";
import type { Glyph } from "../../fonts/types";
import { layout, sizeInPx, Word } from "../text/process";

type TextProps = {
  children?: [string];
  align?: "left" | "center" | "right";
  maxLines?: number;
  font: FontDef;
  color?: string;
  limits: { min: number; max: number };
  fieldKey?: string;
  lineHeight?: number;
  letterSpacing?: number;
  rtl: boolean;
};

// TODO: Do not return anything if the text is empty
//       This can be a safer way instead of checking if the subtext exists in the header themselves.

export function Text(props: TextProps): TextComponent {
  const text = props.children?.[0] || "";
  const def = props.font;
  const leading = props.lineHeight || 0;
  const tracking = props.letterSpacing || 0;
  const { max, min } = props.limits;
  const align = props.align || "left";
  const linesMax = props.maxLines || 3;
  const fieldKey = props.fieldKey || "";
  // split color to fix opacity issues with RGBAs
  const [color, opacity] = splitColor(props.color || "#999999");
  const rtl = props.rtl;

  // const words = glyphWordsFromText(props.children[0] || "", props.font);
  // const { lineHeight, letterSpacing, align } = props;

  function fit(as: Size, forceMinSize: boolean = false) {
    return layout(text, {
      align,
      font: { def, leading, max: forceMinSize ? min : max, min, tracking },
      container: { height: as.h, width: as.w, linesMax }
    });
  }

  return {
    size: (as) => sizeInPx(fit(as)),

    linesInfo(as, p) {
      const { lines, scale } = fit(as);

      return lines.map((l) => {
        const dx = calcAlign(as.w, l.width * scale, 0, align);
        return { p: { x: p.x + dx, y: p.y + l.dy * scale }, size: { w: l.width * scale, h: l.height * scale } };
      });
    },
    toSVG(as, p: Position) {
      // if (!words.length) {
      //   return "";
      // }

      const { lines, scale } = fit(as);

      const output: string[] = [];

      lines.forEach((l) => {
        const dx = calcAlign(as.w, l.width * scale, 0, align);
        // console.log("line", l);
        const dy = (l.dy + l.yMax) * scale;
        const lh = l.height * scale;
        const lw = l.width * scale;

        const svgs: string[] = [];

        for (let [glyph, gdx] of glyphIterator(l.words)) {
          svgs.push(glyphToSVG(glyph, gdx));
        }

        output.push(
          // actual text,
          `<g transform="translate(${dx}, ${dy}) scale(${scale})">${svgs.join("")}</g>`,
          /* add a rect for hit tests */ `<rect x="${dx}" y="${
            dy - l.yMax * scale
          }" fill="#a10" opacity="0.0" width="${lw}" height="${lh}" />`,
          `<text x="${dx}" textLength="${lw}" lengthAdjust="spacingAndGlyphs" height="${lh}" fill="transparent" opacity="1" font-family="arial" font-weight="bold" font-size="${lh}" y="${dy}">${l.words
            .map((w) => w.text)
            .join("")
            .replace(/&/gi, "&amp;")
            .replace(/</gi, "&lt;")
            .replace(/>/gi, "&gt;")}</text>`
        );
      });

      return (
        `<g role="text" transform="translate(${p.x}, ${p.y})" fill="${color}" ${fieldKey ? `data-field-key="${fieldKey}"` : ""}
        ${opacity !== "1.00" ? `opacity="${opacity}"` : ""} class="svg-text-group"
        >${output.join("")}</g>` +
        (DEBUG
          ? `<rect transform="translate(${p.x}, ${p.y})" height="${this.size(as).h}" width="${
              this.size(as).w
            }" style="stroke:green;stroke-width:2px;fill:transparent" />`
          : "")
      );
    }
  };
}

function calcAlign(availableWidth: number, lineWidth: number, offsetX: number, align: "center" | "right" | "left" = "left") {
  switch (align) {
    case "center":
      return availableWidth / 2 - lineWidth / 2 + offsetX;
    case "right":
      return availableWidth - lineWidth;
    case "left":
      return offsetX;
  }
}

const DEBUG = false;
