import type { version_0 as ver_0, version_1 as ver_1 } from "../defs";
import { migrate as migrate_0_to_1, migrateWidget as migrate_widget_0_to_block_1 } from "./migrate_0_to_1";
import { migrate as migrate_1_to_0 } from "./migrate_1_to_0";

import { AnyVersionNewsletter, isVer0, isVer1, RawServerNewsletter } from "./types";
import { migrateAllV1 } from "./v1";

export function migrateToCurrent(newsletter: AnyVersionNewsletter | RawServerNewsletter, currentUserId: string): ver_1.Newsletter {
  // import from our js content
  newsletter = fromRawServer(newsletter);

  if (isVer1(newsletter)) {
    return v1_migrations(newsletter);
  }

  return migrate_0_to_1(newsletter, currentUserId);
}

export function migrateBlockToCurrent(input: ver_0.ContentWidget) {
  return migrate_widget_0_to_block_1(input);
}

// try and execute all the migrations for v_1
export function v1_migrations(newsletter: ver_1.Newsletter): ver_1.Newsletter {
  // TODO: this is kinda lame solution at the moment, we should have a proper solution for data migrations in the future
  migrateAllV1(newsletter);
  return newsletter;
}

export function migrateV1toV0(input: RawServerNewsletter | ver_0.Newsletter | ver_1.Newsletter, currentUserId: string): ver_0.Newsletter {
  // import from our js content
  input = fromRawServer(input);

  if (isVer0(input)) {
    return input;
  }

  return migrate_1_to_0(input, currentUserId);
}

export function fromRawServer(newsletter: AnyVersionNewsletter | RawServerNewsletter): AnyVersionNewsletter {
  if ("js_content" in newsletter) {
    let data = {
      id: newsletter.id,
      short: newsletter.short,
      design: newsletter.design,
      _v: newsletter._v,
      content: JSON.parse(newsletter.js_content)
    } as AnyVersionNewsletter;

    if ("announcementId" in newsletter) {
      // @ts-ignore: announcementId is only part of ver_1.Newsletter type
      data.announcementId = newsletter.announcementId;
    }

    return data;
  }

  return newsletter;
}
