<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./EmailBlock.svelte";
  import { getNewsletterStores } from "../../newsletterStores";
  import { safeHex } from "../utils";
  export let block: schema.SeparatorBlock;

  const { design } = getNewsletterStores();
  $: color = safeHex($design!.color["separator"].stripe);

  function height(layout: schema.BlockLayout) {
    switch (layout) {
      case "lg":
        return 8;
      case "md":
        return 4;

      case "sm":
      default:
        return 2;
    }
  }
  function borderStyle(style: schema.BlockStyle) {
    switch (style) {
      case "dotted":
        return "dotted";
      case "transparent":
        return "transparent";

      case "solid":
      default:
        return "solid";
    }
  }
</script>

<Block {block} let:layout let:style>
  {@const borderColor = style === "transparent" ? "transparent" : color}
  <table
    width="100%"
    role="presentation"
    cellspacing="0"
    cellpadding="8"
    style="display:block;width:100%;max-width:700px;height:0px;border-top:{height(layout)}px {borderStyle(style)} {borderColor};"
    bgcolor="#ffffff">
    <tr>
      <td width="100%" />
    </tr>
  </table>
</Block>
