/**
 * Process
 *
 * text => Word[](Run[](Glpyh[])) => Line[](Word[])
 *
 */

import { Script, scriptFromChar } from "../../fonts/script";
import type { FontDef, Glyph } from "../../fonts/types";
import { reverseBidiChars } from "./bidi";
import { loadedGlyphFor } from "./glyphs";

export type Word = {
  // width in px
  width: number;
  yMin: number;
  yMax: number;
  // baseline in px
  base: number;
  text: string;
  runs: Run[];
};

type Run = {
  script: Script;
  chars: string[];
  glyphs: Glyph[];
  rtl?: boolean;
  width: number;
  base: number;
  // todo - update this to proper offsets
  offsets: number[];
  yMin: number;
  yMax: number;
};

const EMPTY_GLYPH: Glyph = {
  bottom: 0,
  top: 0,
  code: 0,
  lsb: 0,
  char: "",
  path: "",
  w: 128,
  font: {
    asc: 0,
    baseline: 0,
    desc: 0,
    gap: 0,
    glyphs: {},
    kern: {},
    script: "en"
  }
};

function emojiGlyph(char: string) {
  // convert the char to a list of hex codepoints
  let key = Array.from(char)
    .map((v) => v.codePointAt(0)!.toString(16))
    .join("-");

  if (key in { "fe0f": 1, "200d": 1 }) {
    // zero width emoji helper char
    return {
      bottom: 0,
      top: 0,
      w: 0,
      lsb: 0,
      char,
      code: char.charCodeAt(0),
      path: `emoji:${key}`,
      font: {
        asc: 0,
        baseline: 110,
        desc: 10,
        gap: 0,
        glyphs: {},
        kern: {},
        script: "emoji"
      }
    };
  }

  return {
    bottom: -24,
    top: 104,
    w: 120,
    lsb: 0,
    char,
    code: char.charCodeAt(0),
    path: `emoji:${key}`,
    font: {
      asc: 104,
      baseline: 104,
      desc: 0,
      gap: 0,
      glyphs: {},
      kern: {},
      script: "emoji"
    }
  };
}

function glyphFor(char: string, font: FontDef, script: Script): Glyph {
  if (script === "space") {
    script = "en";
  }
  if (script === "emoji") {
    // @ts-ignore
    return emojiGlyph(char);
  }
  if (script === "unknown") {
    return EMPTY_GLYPH;
  }
  return (
    loadedGlyphFor(char, font, script) ?? EMPTY_GLYPH
    // If a glypth was not found here (loadedGlyphFor returned null) then we used to return the following dict.
    // However, "<path d="char:<,codePoints:60">" returned for the character "<" caused sharp to crash with the following error:
    // "Input buffer has corrupt header: glib: XML parse error: Error domain 1 code 68 on line 4 column 83 of data: StartTag: invalid element name"
    // So now, we just return an empty glyph.
    // Ideally, we would want in this case to fallback to the default font for this script and fetch this
    // glyph specifically from there (see loadSvgFont() method).
    // {
    //   ...EMPTY_GLYPH,
    //   path: `char:${char},codePoints:${Array.from(char)
    //     .map((c) => c.charCodeAt(0))
    //     .join("-")}`
    // }
  );
}

function isRtl(s: Script) {
  return s === "he" || s === "arab";
}

// Iterate the texts, return a shaped run
function* runIterator(text: string, o: LayoutOptions): Iterable<Run> {
  /*
    We go through the text and "slice it" based on script/space
  */
  let current: undefined | Run;
  let prevRun: undefined | Run;

  // walk throguh the texts and yield our runs
  for (let char of text) {
    // test script
    const script = scriptFromChar(char, /* with space */ true);

    let glyph = glyphFor(char, o.font.def, script);

    if (script === "space" && o.font.spaceTracking) {
      // make a clone + adjust the space
      glyph = { ...glyph, w: glyph.w + o.font.spaceTracking };
    }

    // oh my, we have a NEW run now.
    if (current && current.script !== script) {
      yield shapeRun(current, o, prevRun);
      // start fresh
      prevRun = current;
      current = undefined;
    }

    // no current run, create one
    if (!current) {
      current = {
        script,
        chars: [],
        base: glyph.font.baseline,
        rtl: isRtl(script),
        glyphs: [],
        offsets: [],
        width: 0,
        yMin: 0,
        yMax: 0
      };
    }

    current.glyphs.push(glyph);
    current.chars.push(char);
    current.yMax = Math.max(current.yMax, glyph.top);
    current.yMin = Math.min(current.yMin, glyph.bottom);
  }

  // the last run
  if (current) {
    yield shapeRun(current, o, prevRun);
  }
}

// Ligaturize, reverse (for RTL), run kerning, etc.
function shapeRun(r: Run, o: LayoutOptions, prevRun?: Run): Run {
  ligaturize(r);
  const trk = o.font.tracking;

  let prevGlyph: Glyph | undefined = lastItem(prevRun?.glyphs);
  let offset = -r.glyphs[0].lsb; // handle the LSB properly for the run
  // add the offset to the space because we're "stealing" offset from it
  if (prevGlyph && prevRun && prevRun.script === "space") {
    prevRun.width + offset;
  }
  const glyphCount = r.glyphs.length;

  for (let i = 0; i < glyphCount; i++) {
    const g = r.glyphs[i];
    // try the kerning
    const k = r.script !== "emoji" ? kern(prevGlyph, g) : 0;

    // space we take is kerning from previous + our actual width
    let w = k + g.w;
    // don't track emojis
    if (r.script !== "emoji") {
      w += trk;
    }

    r.width += w;
    r.offsets.push(offset + k);
    offset += w;
    prevGlyph = g;
  }

  return r;
}

function lastItem<T>(arr: T[] | undefined): T | undefined {
  if (!arr || !arr.length) {
    return undefined;
  }
  return arr[arr.length - 1];
}

function kern(g1?: Glyph, g2?: Glyph): number {
  if (!g1 || !g2) {
    return 0;
  }

  const k = g1.font.kern[g1.code];

  if (!k || !k[g2.code]) {
    return 0;
  }

  return k[g2.code];
}

function* ligatureIterator(glyphs: Glyph[], lig: (current: Glyph, next: Glyph) => Glyph | false): Iterable<Glyph> {
  if (!glyphs.length) {
    return;
  }

  let current: Glyph | undefined;

  for (let g of glyphs) {
    if (!current) {
      current = g;
      continue;
    }

    const result = lig(current, g);
    if (result) {
      current = result;
    } else {
      yield current;
      current = g;
    }
  }

  if (current) {
    yield current;
  }
}

// avoid using a string literal like '\u200D' here because minifiers expand it inline
const U200D = String.fromCharCode(0x200d);

function ligaturize(r: Run) {
  // When we ligaturize, we're basically re-writing the glyph list,
  // before we're moving it to the shaper (for offset calculation etc)

  if (r.script === "emoji") {
    // "ligaturize" process:
    // We're looking for "Zero Width Joiner \u200d" or "modifiers \p{Sk}"
    // Modifiers are merged into the previous glyph (but included)
    // Zero Width Joiner basically creates a merge for the next glyph (but without the zero width joiner)

    function lig(current: Glyph, next: Glyph): Glyph | false {
      // we're pending on merging
      if (current.char.endsWith(U200D)) {
        return {
          ...current,
          char: current.char + next.char,
          path: current.path + next.path.replace("emoji:", "-")
        };
      }

      // if it's a modifier
      if (/[\p{Sk}\uFE0F\u200d]/iu.test(next.char)) {
        return {
          ...current,
          char: current.char + next.char,
          path: current.path + next.path.replace("emoji:", "-")
        };
      }

      return false;
    }

    r.glyphs = Array.from(ligatureIterator(r.glyphs, lig));
  }
}

function* wordsIterator(runs: Iterable<Run>): Iterable<Word> {
  let current: Word | undefined;

  for (let run of runs) {
    if (run.script === "space") {
      if (current) {
        yield current;
        current = undefined;
      }
      // yield a space word
      yield {
        base: run.base,
        text: run.chars.join(""),
        runs: [run],
        width: run.width,
        yMax: run.yMax,
        yMin: run.yMin
      };
    } else {
      // create a new, empty word
      if (!current) {
        current = {
          base: 0,
          text: "",
          runs: [],
          width: 0,
          yMax: 0,
          yMin: 0
        };
      }

      // maximum base
      current.base = Math.max(current.base, run.base);
      current.yMax = Math.max(current.yMax, run.yMax);
      current.yMin = Math.min(current.yMin, run.yMin);
      // update text
      current.text += run.chars.join("");
      // update width
      current.width += run.width;
      // add run
      current.runs.push(run);
    }
  }

  // last one
  if (current) {
    yield current;
  }
}

export function textToWords(text: string, o: LayoutOptions): Word[] {
  text = reverseBidiChars(text);
  return Array.from(wordsIterator(runIterator(text, o)));
}

type LayoutOptions = {
  align: "left" | "center" | "right";
  font: {
    def: FontDef;
    leading: number;
    tracking: number;
    spaceTracking?: number;
    max?: number;
    min?: number;
  };
  container: { width: number; height: number; linesMax: number };
};

export function isSpace(word: Word) {
  return word.runs && word.runs[0].script === "space";
}

export class Line {
  dy: number = 0;
  dx: number = 0;
  words: Word[] = [];

  get width(): number {
    return sum(this.words.map((w) => w.width)) - this.lsb;
  }

  get base(): number {
    return Math.max(...this.words.map((w) => w.base));
  }
  get yMin(): number {
    return Math.min(...this.words.map((w) => w.yMin));
  }
  get yMax(): number {
    return Math.max(...this.words.map((w) => w.yMax));
  }
  get gap(): number {
    return Math.max(...this.words.flatMap((w) => w.runs.map((r) => r.glyphs[0].font.gap)));
  }

  get asc(): number {
    return Math.max(...this.words.flatMap((w) => w.runs.map((r) => r.glyphs[0].font.asc)));
  }

  get height(): number {
    return this.yMax + Math.abs(this.yMin);
  }

  // return the lsb for the first glyph in this line
  get lsb(): number {
    return this.words[0]?.runs[0]?.glyphs[0]?.lsb || 0;
  }
}

export type LayoutResult = { lines: Line[]; scale: number; success: boolean };
type LayoutEngine = (words: Word[], o: LayoutOptions) => LayoutResult;

export function layout(text: string, o: LayoutOptions, engine: LayoutEngine = naiveLayoutEngine): LayoutResult {
  return engine(textToWords(text, o), o);
}

const MIN_SIZE = 12;
const MAX_SIZE = 80;

// when we compile the fonts, we render them for 128px "size"
const RENDERED_SVG_FONT_SIZE = 128;

const DEFAULT_GAP = 12 / RENDERED_SVG_FONT_SIZE;
function naiveLayoutEngine(words: Word[], o: LayoutOptions): LayoutResult {
  // Naive layout option does the eager layout
  const maxW = o.container.width;
  const maxH = o.container.height;
  const maxLines = o.container.linesMax;

  const minSize = o.font.min || MIN_SIZE;

  let currentSize = o.font.max || MAX_SIZE;
  let scale = currentSize / RENDERED_SVG_FONT_SIZE;
  let lines: Line[] = [];

  while (currentSize >= minSize) {
    lines = [];
    scale = currentSize / RENDERED_SVG_FONT_SIZE;

    let line: Line = new Line();

    for (let i = 0; i < words.length; i++) {
      const w = words[i];

      if (isSpace(w)) {
        // we can break here
        const next = words[i + 1];
        // test if we need to break
        if (next && line.width + w.width + next.width > maxW / scale) {
          // we do need to break, so we create a new line and skip the space
          const prevLine = line;
          lines.push(line);

          line = new Line();
          // console.log(
          //   "New line!",
          //   "prev:dy",
          //   prevLine.dy,
          //   "prev:gap",
          //   prevLine.gap,
          //   "prev:height",
          //   prevLine.height,
          //   "prev:asc",
          //   prevLine.asc,
          //   "font:leading",
          //   o.font.leading,
          //   "currentSize",
          //   currentSize
          // );
          line.dy = prevLine.dy + o.font.leading + prevLine.base + currentSize * DEFAULT_GAP;
          // next
          continue;
        }
      }

      line.words.push(w);
    }

    // add the last line
    lines.push(line);

    // are we done?
    const lastLine = lines[lines.length - 1];
    const lineMaxW = Math.max(...lines.map((l) => l.width));
    if (lines.length <= maxLines && sum([lastLine.dy, lastLine.height]) <= maxH / scale && lineMaxW <= maxW / scale) {
      return {
        lines,
        scale,
        success: true
      };
    }

    // scale it down by 2
    currentSize -= 1;
  }

  // if we're here, we've failed to fit, so return a result:
  return {
    lines,
    scale,
    success: false
  };
}

function sum(input: number[]) {
  return input.reduce((prev, curr) => prev + curr, 0);
}

export function sizeInPx(layoutResult: LayoutResult): { w: number; h: number } {
  const { lines, scale, success } = layoutResult;

  // max w
  const w = Math.max(...lines.map((l) => l.width));
  const lastLine = lines[lines.length - 1];
  // height is the height of last line, with the offset from top
  const h = lastLine.dy + lastLine.height;

  // scale them down to match PX size
  return { w: w * scale, h: h * scale };
}
