export function cmdRun(cmd: string, value?: any) {
  document.execCommand(cmd, false, value);
}

export function cmdState(cmd: string): boolean {
  try {
    return document.queryCommandState(cmd);
  } catch (e) {
    return false;
  }
}

export function cmdEnabled(cmd: string): boolean {
  try {
    return document.queryCommandEnabled(cmd);
  } catch (e) {
    return false;
  }
}

function ancestor<T extends HTMLElement>(
  node: Node | undefined,
  testFunc: (node: null | (Node & ParentNode)) => boolean,
  limit: number
): T | undefined {
  if (!node) {
    return;
  }
  // do nothing about limits
  if (!limit) {
    limit = -1;
  }
  var p = node.parentNode;
  while (p && !testFunc(p) && limit !== 0) {
    p = p.parentNode;
    limit--;
  }

  return testFunc(p) ? (p as T) : undefined;
}

function replaceWithChildren(element: HTMLElement) {
  // extract the children
  var children = Array.from(element.childNodes);
  var p = element.parentNode;

  if (!p) {
    return;
  }

  for (var i = 0; i < children.length; i++) {
    p.insertBefore(children[i], element);
  }
  // loose the original
  p.removeChild(element);
}

function isAnchor(node: (Node & ParentNode) | null) {
  return !!node && node.nodeType === 1 && node.nodeName === "A";
}

function selRange() {
  var sel = window.getSelection && window.getSelection();
  if (sel) {
    return sel.getRangeAt(0);
  }
  return null;
}

export function querySelectedLink(): HTMLAnchorElement | undefined {
  return ancestor<HTMLAnchorElement>(selRange()?.endContainer, isAnchor, 2);
}

export function doUnlink() {
  // get the selection
  const selection = window.getSelection ? window.getSelection() : null;

  // If there's a selection AND it's collapsed (means it's a not a range but a cursor point)
  if (selection && selection.isCollapsed) {
    // cancel if we have nothing
    if (!selection.rangeCount) {
      return;
    }

    const range = selection.getRangeAt(0);
    const selNode = range.startContainer;
    const offset = range.startOffset;

    // Find the parent anchor.
    // we do it so if we're inside an html anchor, we can remove it
    const a = ancestor(selNode, isAnchor, 2);
    if (a) {
      try {
        replaceWithChildren(a as HTMLElement);

        // restore the selection properly
        selection.removeAllRanges();

        // create a new selection range
        var r = document.createRange();

        r.setStart(selNode, offset);
        r.setEnd(selNode, offset);

        // add to the window's selection
        selection.addRange(r);
      } catch (e) {
        // fallback to the browser's unlink
        document.execCommand("unLink", false);
      }
    }
  } else {
    // no selection, fallback to the browser
    document.execCommand("unLink", false);
  }
}

type SelectionInfo = {
  start: { node: Node; offset: number };
  end: { node: Node; offset: number };
  isCollapsed: boolean;
};

export function saveSelection(): SelectionInfo | null {
  const sel = window.getSelection();
  const r = sel?.getRangeAt(0);

  if (!sel || !r) {
    return null;
  }

  return {
    start: { node: r.startContainer, offset: r.startOffset },
    end: { node: r.endContainer, offset: r.endOffset },
    isCollapsed: sel.isCollapsed
  };
}

export function restoreSelection(info: SelectionInfo | null) {
  const selection = window.getSelection();
  const range = document.createRange();

  // no selection so bye
  if (!info || !selection) {
    return;
  }
  const { start, end } = info;

  if (start.node === end.node && start.node.nodeType === 1) {
    // If the start and end nodes point to the same node and it's type is an HTML element (nodeType 1)
    // Then this is probably a "command + a" command to select all, so let's do that.
    document.execCommand("selectAll", false);
    return;
  }

  const startOffset = coerceNodeOffset(start.node as Text, start.offset);
  const endOffset = coerceNodeOffset(end.node as Text, end.offset);

  range.setStart(start.node, startOffset);
  range.setEnd(end.node, endOffset);

  selection.removeAllRanges();
  selection.addRange(range);
}

function coerceNodeOffset(node: Text, offset: number): number {
  return Math.min(node.length, offset);
}
