<script lang="ts">
  import { onMount } from "svelte";

  import Pop from "@ui/Pop.svelte";

  let open = false;
  let butt: HTMLElement;
  let pickerComp: any;

  export let inlineStyle = false;

  // We load the module dynamically because it's a fatty
  onMount(async () => {
    if (import.meta.env.SSR) {
      return;
    }
    pickerComp = (await import("./EmojiPicker.svelte")).default;
    return () => {
      pickerComp = undefined;
      open = false;
    };
  });
</script>

<button
  type="button"
  bind:this={butt}
  class:bg-gray-100={open}
  class:text-gray-600={!inlineStyle}
  class:text-14={!inlineStyle}
  class:hover:bg-gray-100={!inlineStyle}
  class:text-18={inlineStyle}
  class:border-transparent={inlineStyle}
  class:hover:border-gray-200={inlineStyle}
  class="block p-1 border rounded material-icons sm-clickable"
  on:mousedown|preventDefault|stopPropagation={() => (open = !open)}>mood</button>
<Pop target={butt} bind:open>
  {#if pickerComp}
    <div class="mt-0.5">
      <svelte:component this={pickerComp} on:selected />
    </div>
  {/if}
</Pop>
