<script lang="ts">
  import type { schema } from "@editor/schema";
  import BlockEditor from "./BlockEditor.svelte";

  import EmbedField from "./form/EmbedField.svelte";

  import cloneDeep from "lodash.clonedeep";
  import { getCurrentUser } from "@ui/currentUser";

  export let focusField: string = "embed";

  export let block: schema.EmbedLinkBlock;
  let draft: schema.EmbedLinkBlock = cloneDeep(block);

  let b = cloneDeep(block);
  const currentUser = getCurrentUser();

  $: if (b) {
    draft = { ...b, _id: draft._id, _lu: draft._lu };
  }
</script>

<BlockEditor {draft} on:done on:cancel>
  <EmbedField
    type="video"
    label={$currentUser.caps.youtube_search ? "Enter video link or search YouTube" : "Video url"}
    urlOnly={!$currentUser.caps.youtube_search}
    required
    autofocus
    bind:value={b}
    placeholder={$currentUser.caps.youtube_search ? "Enter or search" : undefined}
    previewPlaceholder={$currentUser.caps.youtube_search
      ? "&nbsp;"
      : "Paste a video URL to embed it. example: http://vimeo.com/29872340"} />
</BlockEditor>
