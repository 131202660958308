import type { Newsletter } from "../../defs/version_1";
import { migrate_columns_layout_change } from "./columns_layout_change";
import { migrate_event_start_and_end_dates_structure_change } from "./event_block_date_structure_changes";

export function migrateAllV1(n: Newsletter) {
  // TODO: this is kinda lame solution at the moment, we should have a proper solution for data migrations in the future
  // run all migrations here
  migrate_columns_layout_change(n);
  migrate_event_start_and_end_dates_structure_change(n);
}
