<script lang="ts">
  import { onMount } from "svelte";
  import Field from "./Field.svelte";
  import { field } from "./store";
  import { requiredTest } from "./validations";
  import { Loader } from "@googlemaps/js-api-loader";

  export let value: string = "";
  export let label: string | undefined;
  export let required: boolean = false;
  export let icon: string | undefined = "place";
  export let autofocus: boolean = false;
  export let error: string | undefined = undefined;
  export let placeholder: string = "";
  export let disabled: boolean = false;
  export let googlePlacesApiKey: string;

  let el: HTMLInputElement;
  let isFocused: boolean = false;

  const { id, input } = field<string>(value, (c) => ([value, error] = c), {
    validate: [required && requiredTest()]
  });
  // update from outside
  $: input && ($input = value || "");

  if (autofocus) {
    onMount(() => focus());
  }

  export function focus() {
    el && el.select();
  }

  function disableReturn(e: KeyboardEvent) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  function onPlaceChanged(e) {
    value = el.value;
  }

  onMount(async () => {
    const loader = new Loader({
      apiKey: googlePlacesApiKey,
      version: "weekly",
      libraries: ["places"]
    });

    const placesAPI = await loader.importLibrary("places");

    const placesAutoComplete = new placesAPI.Autocomplete(el);
    const listener = placesAutoComplete.addListener("place_changed", onPlaceChanged);
    return listener.remove;
  });
</script>

<Field {label} {id} {required} {error} {value} {icon} size="md" {isFocused}>
  <input
    type="text"
    bind:value={$input}
    bind:this={el}
    on:keydown={disableReturn}
    on:focus={() => (isFocused = true)}
    on:blur={() => (isFocused = false)}
    autocomplete="off"
    {required}
    {placeholder}
    {id}
    {disabled}
    {autofocus}
    class="flex-grow block mx-1 outline-none text-13" />
</Field>
