<script lang="ts">
  import { RunningText, loadGlyphsForText } from "../../svgkit";
  import type { FontDef } from "../../svgkit/fonts/types";

  export let font: FontDef;
  export let color: string = "#000000";
  export let size: number = 24;
  export let text: string = "";
  export let align: "left" | "right" | "center" = "left";
  export let rtl: boolean;
  export let lineHeight: number = 0;
  export let letterSpacing: number = 0;
  export let spaceTracking: number = 0;

  function isSpace(w: string) {
    return !w.replace(/\s/giu, "").trim();
  }
</script>

<span
  role="text"
  {...$$restProps}
  style="font-size:{size}px;"
  class="block leading-none translate-hidden ada-text-black"
  class:text-center={align === "center"}
  class:text-right={align === "right"}
  class:flex={rtl}
  class:flex-wrap={rtl}
  dir={rtl ? "rtl" : "ltr"}>
  {#await loadGlyphsForText(text, font)}
    {#each text.split(/\b/iu) as word}
      <span
        style="font-size: {size}px"
        class="inline-block mb-1 text-transparent rounded-sm animate-pulse"
        class:bg-gray-200={!isSpace(word)}>
        {#if isSpace(word)}&nbsp;{:else}
          {word}
        {/if}
      </span>
    {/each}
  {:then r}
    {@html RunningText({ font, size, children: [text], color, lineHeight, letterSpacing, spaceTracking, rtl })
      .map((s) => s.svg)
      .join("")}
  {/await}
</span>
<span
  class="hidden font-bold translate-block translate-rtl ada-text-black"
  class:translate-text-right={align === "left"}
  style="font-size:{size}px;text-align:{align};color:{color}">{text}</span>

<style>
  span {
    line-height: 1;
  }

  span.bg-gray-200 + span.bg-gray-200 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>
