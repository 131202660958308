<script lang="ts">
  import type { schema } from "@editor/schema";
  import { getSpacing } from "../spacing/spacing";
  import { setBlockCtx } from "../utils";
  // The item we're actually showing
  export let block: schema.Block;
  // Box -> Regular widgets (with margin)
  // Clear -> Widgets outside of the newsletter (logo, signature)
  // Full -> Edge to edge widgets (like photo etc)
  export let size: "full" | "box" | "clear" = "box";
  export let background: string | undefined = undefined;
  export let bgcolor: string | undefined = undefined;
  export let bgsize: "contain" | "cover" = "cover";
  export let align: "left" | "center" | "right" = "left";
  const space = getSpacing();
  $: layout = block._l;
  $: style = block._style;
  $: shape = block._shape;

  setBlockCtx(block);
</script>

<!-- All block are part of a big table -->
<tr>
  <td
    class:box={size === "box"}
    style="{background ? `background-size:${bgsize};background-repeat:no-repeat;background-position:center;` : ''}{size === 'box'
      ? `padding:${$space.before}px 20px ${$space.after}px 20px`
      : ''}"
    {background}
    {bgcolor}
    {align}
    valign="top">
    <slot {layout} {style} {shape} />
  </td>
</tr>
