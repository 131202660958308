<script lang="ts">
  import { DateTimeTZUtils } from "../event/DateTimeTZUtils";
  import type { schema } from "@editor/schema";
  import DateTimeField from "./DateTimeField.svelte";

  export let value: schema.DateTimeTZ | undefined = undefined;
  export let label: string | undefined;
  export let required: boolean = false;

  let startDate = value ? DateTimeTZUtils.start(value) : createDefaultStartDate();
  let endDate = value ? DateTimeTZUtils.end(value) : undefined;

  const minStartDate = new Date();
  minStartDate.setUTCHours(0);
  minStartDate.setUTCMilliseconds(0);
  minStartDate.setUTCMinutes(0);
  minStartDate.setUTCSeconds(0);

  function createDefaultStartDate() {
    const sDate = new Date();
    // Set the time to 7am tomorrow (add 24 hours and 7 more)
    sDate.setHours(31, 0, 0, 0);
    return sDate;
  }

  $: value = DateTimeTZUtils.fromDate(startDate, endDate);
</script>

<DateTimeField {label} bind:value={startDate} {required} {minStartDate} />
