import type { Component } from "./base";

type RectProps = {
  width: number;
  height: number;
  fill: string;
  opacity?: number;
  borderRadius?: number;
};

export function Rect(props: RectProps): Component {
  const { width, height, fill, opacity, borderRadius } = props;

  return {
    toSVG(as, p) {
      return `<rect x="${p.x}" y="${p.y}" 
      rx="${borderRadius || "0"}" 
      ry="${borderRadius || "0"}" 
      width="${width}" height="${height}" 
      style="fill:${fill || "#000"};opacity:${opacity || 1}" />`;
    },

    size(a) {
      return { w: width, h: height };
    },
  };
}
