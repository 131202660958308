<script lang="ts">
  import DropTarget from "./DropTarget.svelte";
  import FileSelect from "sbelt/ui/FileSelect.svelte";
  import { isAcceptable } from "./store";
  import type { DragPayload } from "./store";
  import { createEventDispatcher } from "svelte";

  export let disabled = false;
  export let canDrop = true;
  export let canClick = true;
  export let accept: string | undefined = undefined;
  export let multiple = false;

  let selector: FileSelect;

  export function select() {
    selector && selector.open();
  }

  const dispatch = createEventDispatcher();
  $: filter = (d: DragPayload) => isAcceptable(d, accept) && d!.multi === multiple;

  function drop(e: any) {
    const files = e.detail.items.map((i: any) => i.value);
    files.length && dispatch("files", files);
  }
</script>

<DropTarget disabled={disabled || !canDrop} {filter} on:drop={drop} let:isOver let:activeDrag let:isRelevantDrag>
  <FileSelect disabled={disabled || !canClick} {accept} {multiple} on:files bind:this={selector}>
    <slot {isOver} {activeDrag} {isRelevantDrag} />
  </FileSelect>
</DropTarget>
