<script lang="ts">
  import type { schema } from "@editor/schema";
  import { getNewsletterStores } from "../newsletterStores";
  import Block from "./Block.svelte";
  import { isEmpty } from "./blocksConfig";
  import PhotoField from "./form/PhotoField.svelte";
  import Rich from "./utils/Rich.svelte";
  import { isEditor, withDefaults, LinkContent, photoToCTA, fancyPicClassIfNoCTA } from "./utils";

  export let block: schema.ItemsBlock;
  const { design, content, ctx } = getNewsletterStores();

  // get defaults
  $: [b, isDefault] = withDefaults(
    block,
    // extra defaults
    {
      items: [{ id: "1" }, { id: "2" }, { id: "3" }]
    },
    // transform to make sure we use the extra default
    (b, defaults) => {
      for (let i = 0; i < 3; i++) {
        b.items[i] = b.items[i] ?? b._defaults?.items[i] ?? defaults?.items?.[i] ?? { id: `${i}` };
      }

      // Handle the shit case where items are dict instead of array :(
      if (!Array.isArray(b.items)) {
        b.items = Object.values(b.items);
      }
      return b;
    }
  );

  $: isTemplate = ctx.mode === "template";

  function photoUpdated(photo: schema.Photo | undefined, idx: number) {
    content.updateById<schema.ItemsBlock>(block._id, (b) => {
      b.items[idx] = photo;
    });
  }

  function filterItems(items: schema.ItemsBlock["items"], layout: string): schema.ItemsBlock["items"] {
    if (!isEditor() && !isTemplate) {
      items = items.filter((i) => i && !isDefault(i));
    }

    // slice items based on our layout
    return items.slice(0, layout === "2-columns" ? 2 : 3);
  }
</script>

<Block
  {block}
  on:click
  on:delete
  on:duplicate
  let:layout
  let:logged_out
  layouts={["2-columns", "3-columns", "list"]}
  let:isEmpty
  showEmpty
  preventClicks={!isEmpty}>
  <div
    class:flex={layout === "list"}
    class:flex-col={layout === "list"}
    class:grid={layout !== "list"}
    class:grid-cols-2={layout !== "list"}
    class:gap-x-4={layout !== "list"}
    class:md:grid-cols-3={layout === "3-columns" && filterItems(b.items, layout).length > 2}
    class:print:grid-cols-3={layout === "3-columns" && filterItems(b.items, layout).length > 2}>
    {#each [0, 1, 2].slice(0, layout === "2-columns" ? 2 : 3) as _, idx}
      {@const item = b.items[idx]}
      <!-- Pointer events auto here, because we usually disable all pointer events to empty blocks -->
      {#if item && (isEditor() || isTemplate || !isDefault(item))}
        <div
          class="flex flex-col w-full pointer-events-auto"
          class:sm:flex-row={layout === "list"}
          class:items-center={layout === "list"}
          class:flex-col={layout !== "list"}
          class:sm:items-start={layout === "list"}
          class:list-view-item={layout === "list"}>
          {#if isEditor() || isTemplate}
            <div class:w-48={layout === "list"} class:w-full={layout !== "list"} class="flex-shrink-0">
              <PhotoField
                handoffId="side-photo-{block._id}-{idx}"
                disabled={!isEditor()}
                value={item}
                on:logged_out={logged_out}
                on:clear={(e) => photoUpdated(undefined, idx)}
                on:upload_done={(e) => photoUpdated(e.detail, idx)}
                mode="crop"
                showErrorMessage>
                <img
                  slot="preview"
                  let:previewUrl={preview}
                  src={preview}
                  alt={""}
                  class="block object-contain w-full rounded max-w-none"
                  class:max-h-32={layout === "list"}
                  class:sm:h-32={layout === "3-columns"}
                  class:sm:h-48={layout === "2-columns"} />
              </PhotoField>
            </div>
          {:else if item.legacy_thumb_url && !isEditor()}
            <div class="relative flex-shrink-0 group" class:w-48={layout === "list"}>
              <LinkContent
                cta={photoToCTA(item)}
                type="photo"
                linkText={item.alt_text || item.title}
                class="lock w-full h-full skip-tracking image-container {layout === 'list' ? 'sm:w-48' : 'w-full'} {fancyPicClassIfNoCTA(
                  item
                )}"
                data-title={item.alt_text || item.title}
                data-img-full={item.legacy_full_url}>
                <img
                  src={item.legacy_thumb_url}
                  alt={item.alt_text}
                  class="block object-contain w-full rounded max-w-none"
                  class:max-h-32={layout === "list"}
                  class:sm:h-32={layout === "3-columns"}
                  class:sm:h-48={layout === "2-columns"} />
                <div
                  role="img"
                  aria-label={item.alt_text}
                  title={item.alt_text}
                  class="absolute inset-0 opacity-0 group-hover:bg-gray-100 group-hover:bg-opacity-60 group-hover:opacity-100" />
                <div class="absolute top-0 right-0 opacity-0 group-hover:opacity-100">
                  <div class="flex items-center">
                    {#if item.cta?.url}
                      <LinkContent
                        cta={photoToCTA(item)}
                        type="photo"
                        linkText={item.alt_text || item.title}
                        class="relative flex p-1 mr-2 rounded bg-opacity-80 bg-nxgray-500"
                        data-title={item.alt_text || item.title}
                        data-img-full={item.legacy_full_url}
                        title="Open link"
                        tabindex="-1">
                        <span aria-hidden="true" class="text-white material-icons notranslate text-18"> open_in_new </span>
                      </LinkContent>
                    {/if}
                    <a
                      class="p-1 rounded bg-opacity-80 fancy-pic bg-nxgray-500 skip-tracking"
                      href={item.legacy_full_url}
                      title="Expand"
                      tabindex="-1"
                      ><span aria-hidden="true" class="text-white material-icons notranslate text-18"> zoom_out_map </span>
                    </a>
                  </div>
                </div>
              </LinkContent>
            </div>
          {/if}

          <div class="mt-3" class:sm:ml-3={layout === "list"} class:sm:mt-0={layout === "list"} class:flex-grow={layout === "list"}>
            {#if !isDefault(b.items[idx]?.title) || isEditor()}
              <h4
                data-field-key="title-{idx}"
                class="font-bold leading-tight text-18"
                class:text-center={item?.align === "center"}
                class:opacity-50={isDefault(item?.title)}
                style="color:{$design.background.dark ? '#fff' : $design.color['global.text']}">
                {b.items[idx]?.title || "Add a title"}
              </h4>
            {/if}
            {#if !isDefault(b.items[idx]?.description) || isEditor()}
              <div
                class="text-15"
                class:opacity-50={isDefault(item?.description)}
                style="color:{$design.background.dark ? '#fff' : $design.color['global.text']}"
                data-field-key="content-{idx}">
                <Rich content={b.items[idx]?.description ?? "Add description"} blockId={block._id} context={{ type: "photo" }} />
              </div>
            {/if}
          </div>
        </div>
      {/if}
    {/each}
  </div>
</Block>

<style>
  .list-view-item + .list-view-item {
    margin-top: 1.5rem;
  }

  @media (min-width: 640px) {
    .list-view-item + .list-view-item {
      margin-top: 1rem;
    }
  }

  .fancy-pic {
    position: relative !important;
    display: flex !important;
  }
</style>
