import type { CompressedKerning, CompressedSvgFont, FontDef, FontPack, Glyph, SvgFont, UncompressedKerning } from "../../fonts/types";
import fetch from "cross-fetch";

import { LoadableScript, LOADABLE_SCRIPTS, Script, scriptsFromText } from "../../fonts/script";

const ROOT = "https://cdn.smore.com/_fr/f/";

// A fallback font for all the missing languages
const FALLBACK_FONT_DEF: FontDef = {
  id: "_fallback",
  s: {
    en: "v4.en.a194c25f.json",
    ext: "v4.ext.14ba9eca.json",
    he: "v4.he.0eb02a28.json",
    arab: "v4.arab.a98b36ea.json",
    grk: "v4.grk.8f66d120.json",
    cyr: "v4.cyr.301fa594.json",
    music: "v4.music.ee69c50c.json",
    han: "v4.han.3b06490a.json"
    // TODO: Add a fallback font containing custom unicode symbols
    //       Ask Or to find such a font. Add it to the font directory and re-compile.
    //       Lastly, add the output SVG-font JSON filepath here:
    // symbols: "v4.symbols.XXX.json"
  }
};

// A helper to make sure we loaded all the glyphs we need
export function loadGlyphsForText(text: string, ...defs: FontDef[]): Promise<void> | void {
  const scripts = scriptsFromText(text, /*only loadable*/ true) as LoadableScript[];

  const loaders = defs.map((def) => scripts.map((s) => loadSvgFont(def, s))).flat();

  if (allResolved(loaders)) {
    return;
  }

  // not everything is loaded, return
  return Promise.all(loaders).then(() => undefined);
}

export function previewUrl(def: FontDef, size: "sm" | "lg" = "sm"): string {
  if (!def.t) {
    return "";
  }
  return `${ROOT}${def.t[size]}`;
}

// fetch url -> json
type FetchHandler = (url: string) => Promise<object>;

export async function loadAllLangsForFont(def: FontDef, fetch?: FetchHandler): Promise<Record<string, SvgFont>> {
  const result: Record<string, SvgFont> = {};

  await Promise.all(
    LOADABLE_SCRIPTS.map(async (script) => {
      // console.log("[start] Loading svg font", def, script);
      result[script] = await loadSvgFont(def, script as LoadableScript, fetch, /*withGlyphs*/ true);
      // console.log("[done] Loaded svg font", def, script);
    })
  );

  return result;
}

// A map from <def.id>_<lang> to a loaded SVG font
const _loadedFonts: Record<string, SvgFont> = {};
// server side glyphs: url -> {code: path}
const _glyphPaths: Record<string, { [id: string]: string }> = {};
// A cache from the url to a loaded svg font,
// to prevent us loading tons of fonts multiple times
const _fetchRequests: Record<string, Promise<SvgFont>> = {};

// We return promise / non promise to support SSR rendering
export function loadSvgFont(
  def: FontDef,
  script: LoadableScript,
  f = (url: string) => fetch(url).then((r) => r.json()),
  withGlyphs: boolean = false
): Promise<SvgFont> | SvgFont {
  const key = `${def.id}_${script}`;

  if (!_loadedFonts[key]) {
    // take the url from the language from the given font def OR fallback font.
    // We do this when we have for example, hebrew inside a latin string for
    // a latin only font
    let url = def.s[script] || FALLBACK_FONT_DEF.s[script]!;

    function load(url: string): Promise<SvgFont> {
      // preload the svg only if we need it
      const svgPromise = withGlyphs ? new Promise((resolve) => resolve(true)) : fetch(`/_fr/f/${url.replace("json", "svg")}`);
      if (withGlyphs) {
        url = url.replace(".json", ".g.json");
      }
      const metaPromise = f(`${ROOT}${url}`).then((j) => (withGlyphs ? loadCompressedWithGlyphs(j, url) : loadCompressed(j, url)));
      return new Promise((resolve) => {
        Promise.all([metaPromise, svgPromise]).then(([font, _]: [SvgFont, any]) => {
          resolve(font);
        });
      });
    }

    const r =
      // Do we have it in cache?
      (url && _fetchRequests[url]) ||
      // No? fetch it
      (_fetchRequests[url] = load(url));

    // save the result
    return r.then((result) => {
      return (_loadedFonts[key] = result);
    });
  }

  return _loadedFonts[key];
}

function loadCompressedWithGlyphs(input: { font: CompressedSvgFont; glyphs: Record<string, string> }, url: string): SvgFont {
  const { font, glyphs } = input;
  url = url.replace(".g.json", ".json");
  Object.keys(glyphs).forEach((code) => {
    (_glyphPaths[url] = _glyphPaths[url] || {})[code] = glyphs[code];
  });

  return loadCompressed(font, url);
}

function loadCompressed(input: CompressedSvgFont, url: string): SvgFont {
  const { asc, script, desc, kern, gap, baseline } = input;
  const glyphs: Record<number, Glyph> = {};
  const font: SvgFont = {
    asc,
    script,
    desc,
    glyphs,
    kern: loadCompressedKern(kern),
    baseline,
    gap,
    pathsUrl: url.replace("json", "svg")
  };

  input.glyphs.forEach((g) => {
    const [code, w, lsb, gyMax, gyMin] = g;

    glyphs[code] = {
      code,
      char: String.fromCodePoint(code),
      lsb,
      font,
      path: (_glyphPaths[url] ? _glyphPaths[url][code] : "") || "",
      top: gyMax,
      bottom: gyMin,
      w
    };
  });

  return font;
}

function loadCompressedKern(input: CompressedKerning[]): UncompressedKerning {
  const r: UncompressedKerning = {};

  for (let i of input) {
    const [g1, g2, space] = i;
    (r[g1] = r[g1] || {})[g2] = space;
  }

  return r;
}

export type GlyphWord = {
  // the actual word text
  text: string;
  glyphs: Glyph[];
  space?: boolean;
  rtl?: boolean;
  // a kerning offset for the word
  kerning: number[];
};

export type GlyphLine = {
  dy: number; // delta from the first rendering position
  words: GlyphWord[];
  spacing: number; // absolute letter spacing
  scale: number; // scaling factor,
  w: number; // width of the line
  h: number;
  top: number;
  bottom: number;
};

export function loadedGlyphFor(char: string, f: FontDef, script: LoadableScript): Glyph {
  return _loadedFonts[`${f.id}_${script}`]?.glyphs[charToUnicode(char) as number];
}

function allResolved(input: Array<PromiseLike<any> | any>): boolean {
  return input.every((i) => !("then" in i));
}

/**
 * Converts a character to unicode
 *
 * Unlike "charCodeAt" this works for all unicode characters (and not only ASCII ones)
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/charCodeAt#fixing_charcodeat_to_handle_non-basic-multilingual-plane_characters_if_their_presence_earlier_in_the_string_is_known
 * @param char
 */
function charToUnicode(char: string) {
  let index = 0;
  const end = char.length;

  const surrogatePairs = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
  while (surrogatePairs.exec(char) !== null) {
    const li = surrogatePairs.lastIndex;
    if (li - 2 < index) {
      index++;
    } else {
      break;
    }
  }

  if (index >= end || index < 0) {
    return NaN;
  }

  const code = char.charCodeAt(index);

  if (0xd800 <= code && code <= 0xdbff) {
    const hi = code;
    const low = char.charCodeAt(index + 1);
    // Go one further, since one of the "characters"
    // is part of a surrogate pair
    return (hi - 0xd800) * 0x400 + (low - 0xdc00) + 0x10000;
  }
  return code;
}
