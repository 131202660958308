import type { schema } from "../schema";
import { derived, get, Readable, writable, Writable } from "svelte/store";
import { getContext, setContext } from "svelte";
import type { DesignDef, FontPack, Background, ColorPack, Category, BackgroundAsset } from "../design/types";
import { assets, Designs } from "../design";
import cloneDeep from "lodash.clonedeep";
import debounce from "lodash.debounce";
import type { NewsletterAPI } from "@editor/api";
import type { ContextMode } from "../../../rendering/utils";

type DesignMutator = (d: schema.NewsletterDesign) => void;

export type DesignStoreContent = {
  feel: DesignDef;
  fontPack: FontPack;
  background: Background;
  customBackground?: Background;
  color: ColorPack;
  ext?: schema.NewsletterDesign["ext"];
};

type DesignStore = Readable<DesignStoreContent | undefined> & {
  preview: (m: DesignMutator) => void; // preview + clear preview
  clearPreview(): void;
  update(mut: DesignMutator): void;
  // data to commit
  data: Readable<schema.NewsletterDesign | undefined>;
};

type ContentStore = Readable<schema.NewsletterContent | undefined> & {
  updateById<T extends schema.Block>(id: string, updater: BlockUpdater<T>, unlock?: boolean): void;
  setBlock<T extends schema.Block>(id: string, newBlock: T, unlock?: boolean): void;
  setLogo(newLogo: schema.LogoBlock | undefined): void;
  remove(id: string): void;
  insert(newBlock: schema.Block, insertAfterId: string | undefined): void;
  duplicateBlock(oldBlock: schema.Block): void;
  move(id: string, insertAfterId?: string): void;
};

type BlockUpdater<T extends schema.Block = schema.Block> = (block: T) => void;

/*
 This is a copy (and an extension) of LinkTypes from "rendering/utils.ts"
 WARNING: Do not make changes!
**/
// Deprecated types for compatibility
export type DeprecatedLinkTypes =
  | "lnk" // regular text link
  | "btn" // button link
  | "vid" // video link
  | "img" // open original image
  | "img-l" // open a linked image
  | "emb" // click on embed
  | "form-emb" // clicked on embed form
  | "trns" // translation button
  | "web-v" // open web version
  | "cont" // click on contact author in email newsletter
  | "sub"; // click on

export type LinkTypes =
  | DeprecatedLinkTypes
  // Links in blocks
  | "logo"
  | "title"
  | "paragraph"
  | "paragraph-image"
  | "button"
  | "link" // link block
  | "photo"
  | "video"
  | "event"
  | "file"
  | "form"
  | "poll"
  | "signature"
  // Other links
  | "contact-author"
  | "subscribe" // subscribe to a mailing list the author selected in the Signature
  // Email-specific links
  | "email-translate"
  | "email-open-web"
  | "email-header"
  | "email-unsubscribe"
  | "email-rsvp";

export type LinkTypesWithOutDeprecated = Exclude<LinkTypes, DeprecatedLinkTypes>;

export type LinkOptions = {
  id?: string; // the link id
  type: LinkTypesWithOutDeprecated;
  text?: string; // the link text,
  blockId?: string;
  ignoreStorage?: boolean;
};

export type SVGOptions = {
  background?: { url?: string; color?: string };
  // store in the shared space, useful for cross newsletter images
  shared?: boolean;
};

export type RenderContext = {
  userId: string;
  mode: ContextMode;
  href(url: string, o: LinkOptions): string;
  svg(data: string, o?: SVGOptions): string;
  dialect?: "mns" | "non_standard_mns" | "customer.io";
};

const CONTEXT_KEY = {};
const DEFAULT_CTX = {
  href(url: string, o: LinkOptions) {
    return url;
  },
  svg(data: string) {
    return `data:image/svg+xml;base64,${btoa(data)}`;
  }
};

type NewsletterStores = {
  design: DesignStore;
  content: ContentStore;
  editors: EditorsStore;
  status: NewsletterAPI["status"];
  ctx: RenderContext;
  api: NewsletterAPI;

  ready: Readable<boolean>;
  id: string;
  short: string;
  allowTableOfContent: Writable<boolean>;
};

export function createNewsletterStores(
  api: NewsletterAPI,
  ctx: Partial<RenderContext> & Pick<RenderContext, "userId" | "mode">,
  nl: { id: string; short: string; allowTableOfContent: boolean }
): NewsletterStores {
  const stores: NewsletterStores = {
    design: createDesignStore(api),
    content: createContentStore(api),
    editors: createEditorsStore(api),
    ctx: { ...DEFAULT_CTX, ...ctx },
    api,
    status: api.status,
    ready: derived([api.data], ([$data]) => $data !== undefined),
    ...nl,
    allowTableOfContent: writable(nl.allowTableOfContent)
  };
  setContext(CONTEXT_KEY, stores);
  return stores;
}

type EditorsStore = Readable<schema.Newsletter["editors"]> & {};

export function createEditorsStore(api: NewsletterAPI): EditorsStore {
  // oru source data is the API
  const data = derived([api.data], ([$nl]) => {
    return ($nl ? $nl.editors : {}) || {};
  });

  return {
    subscribe: data.subscribe
  };
}

export function getNewsletterStores() {
  return (getContext(CONTEXT_KEY) || []) as NewsletterStores;
}

// A shortcut for the render context
export function getRenderContext(): RenderContext {
  return getNewsletterStores().ctx;
}

function createContentStore(api: NewsletterAPI): ContentStore {
  const data = derived([api.data], ([$nl]) => {
    return $nl?.content;
  });

  const { commit, duplicate, remove, insert, move } = api.blocks;

  function setBlock<T extends schema.Block>(id: string, newBlock: T, unlock: boolean = true) {
    commit(
      id,
      (b) => {
        for (let k in b) {
          // @ts-ignore
          delete b[k];
        }

        for (let k in newBlock) {
          // @ts-ignore
          b[k] = newBlock[k];
        }
      },
      unlock
    );
  }

  return {
    setLogo: api.setLogo,
    updateById: commit,
    setBlock,
    duplicateBlock: duplicate,
    move,
    insert,
    remove,
    subscribe: data.subscribe
  };
}

function toCustomBackground(ext: schema.NewsletterDesign["ext"] | undefined, feelBg: Background | undefined): Background | undefined {
  if (!ext || (!ext.custom_header_bg && !ext.custom_page_bg) || !feelBg) {
    return undefined;
  }

  const assets: BackgroundAsset[] = [];

  if (ext.custom_page_bg) {
    const pbg = ext.custom_page_bg;

    assets.push({
      location: "page-full",
      type: "image",
      color: "#ccc",
      image: {
        hex: "",
        blur: "",
        g: "",
        xl: pbg.background.legacy_full_url!,
        sm: pbg.background.legacy_thumb_url!,
        xs: pbg.background.legacy_thumb_url!
      }
    });
  }

  if (ext.custom_header_bg) {
    const hbg = ext.custom_header_bg;

    assets.push({
      location: "header",
      type: "image",
      color: "#ccc",
      image: {
        hex: "",
        blur: "",
        g: "",
        xl: hbg.background.legacy_full_url!,
        sm: hbg.background.legacy_thumb_url!,
        xs: hbg.background.legacy_thumb_url!
      }
    });
  }

  return {
    id: "__custom",
    $order: 0,
    body: { ...feelBg.body },
    thumbnail: { ...feelBg.thumbnail },
    dark: feelBg.dark,
    assets,
    excludedColorIds: []
  };
}

function createDesignStore(api: NewsletterAPI): DesignStore {
  // oru source data is the API
  const data = derived([api.data], ([$nl]) => {
    return $nl ? coerceDesign($nl.design) : undefined;
  });

  // We use the globalThis.__design thing for when the app refreshs itself and tries to
  // re-load the data from the dom in development
  // @ts-ignore
  // const data = writable(coerceDesign(globalThis.__design || content));
  const previewStore = writable<schema.NewsletterDesign | undefined>(undefined);

  const store = derived([data, previewStore, assets], ([$data, $preview, $assets]) => {
    if (!$data) {
      return;
    }

    const p = $preview || $data;

    // We assume everything here is ALREADY COERCED
    const feel = $assets.designsById[p.feel];
    const fontPack = $assets.byId<FontPack>(p.feel, p.font);
    const background = $assets.byId<Background>(p.feel, p.bg);
    const color = $assets.byId<ColorPack>(p.feel, p.color);
    const customBackground = toCustomBackground(p.ext, background);

    return {
      feel,
      fontPack,
      background,
      customBackground,
      color,
      ext: p.ext
    };
  });

  function getData() {
    return cloneDeep(get(data));
  }

  function clearPreview() {
    preview.cancel();
    previewStore.set(undefined);
  }

  const preview = debounce((m: DesignMutator) => {
    const d = getData();
    // mutate
    m(d);
    // set the preview
    previewStore.set(coerceDesign(d, false));
  }, 50);

  function update(m: DesignMutator) {
    // clear the preview
    clearPreview();
    const d = getData();
    // mutate
    m(d);
    // set the preview
    api.setDesign(coerceDesign(d));
    // @ts-ignore
    globalThis.__design = coerceDesign(d);
  }

  return {
    subscribe: store.subscribe,
    preview,
    update,
    clearPreview,
    data
  };
}

// Make sure we select the right values
export function coerceDesign(p: schema.NewsletterDesign, filter: boolean = true): schema.NewsletterDesign {
  const { feel: feelId, font: fontId, bg: bgId, color: colorId, _li, _lu, ext } = p;

  const feel = Designs[feelId] || Designs["modern"];
  const fontPack = byIdOrFirst(feel.fontPacks, fontId);
  const background = byIdOrFirst(feel.backgrounds, bgId);
  const color = byIdOrFirst(feel.colorPacks, colorId, filter ? (c) => filterColors(background, c.items) : undefined);

  return {
    feel: feel.id,
    font: fontPack.id,
    bg: background.id,
    color: color.id,
    ext,
    _li,
    _lu
  };
}

// in the future we can create "a cache" for all the resources by their ids
function byIdOrFirst<T extends { id: string }>(categories: Category<T>[], id: string, filter?: (c: Category<T>) => T[]): T {
  const items = categories.map((c) => (filter ? filter(c) : c.items)).flat();

  for (let j = 0; j < items.length; j++) {
    if (items[j].id === id) {
      return items[j];
    }
  }

  // first item out of first category
  return items[0];
}

export function filterColors(bg: Background, colorPacks: ColorPack[]): ColorPack[] {
  if (!bg.excludedColorIds || !bg.excludedColorIds.length) {
    return colorPacks;
  }

  return colorPacks.filter((p) => !bg.excludedColorIds.includes(p.id));
}

// Return background assets per location + sort them by layer
export function backgroundAssets(
  background: Background | undefined,
  location?: "page-full" | "page-top" | "header" | "page-footer"
): BackgroundAsset[] {
  if (!background) {
    return [];
  }
  return background.assets.filter((i) => (location ? i.location === location : true)).sort((a, b) => (a.layer || 1) - (b.layer || 1));
}

export function bgAssetUrl(asset: BackgroundAsset | undefined): string | undefined {
  if (!asset || !asset.image) {
    return;
  }

  if (asset.filters) {
    return asset.image.blur;
  }

  return asset.image.xl;
}
