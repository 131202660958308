<script lang="ts">
  import BlockEditor from "./BlockEditor.svelte";
  import type { schema } from "@editor/schema";
  import TextField from "./form/TextField.svelte";
  import FieldLabel from "./form/FieldLabel.svelte";
  import cloneDeep from "lodash.clonedeep";
  import EmojiPickerButton from "./form/EmojiPickerButton.svelte";
  import RadioGroupField from "./form/RadioGroupField.svelte";
  import RadioField from "./form/RadioField.svelte";
  import Tooltip from "@ui/Tooltip.svelte";
  import DurationEditor from "./poll-block-editor/DurationEditor.svelte";
  import AnswersField from "./poll-block-editor/AnswersField.svelte";
  import { start, toStartOfDay } from "./poll-block-editor/durationUtils";

  export let block: schema.PollBlock;
  export let focusField: string = "content";

  let draft: schema.PollBlock = cloneDeep(block);
  let questionField: TextField;

  $: draft.answers = draft.answers || [{ text: "" }];
  const isResultsReadonly: boolean = start(block.dateRange) <= toStartOfDay(new Date());

  async function submitAction(dataToSubmit: schema.PollBlock) {
    dataToSubmit.answers = dataToSubmit.answers.filter((answer) => answer.text.trim().length > 0);
    return dataToSubmit;
  }

  // TODO: Add the EmojiPicker into TextField as an option!!!
</script>

<BlockEditor {draft} on:done on:cancel {submitAction}>
  <TextField
    label="Question"
    bind:this={questionField}
    required
    autofocus
    limit={60}
    bind:value={draft.question}
    placeholder="Enter a question">
    <div slot="afterInput" class="flex items-center gap-1 pl-1">
      <EmojiPickerButton on:selected={(e) => questionField.insertTextAtCursor(e.detail)} />
    </div>
  </TextField>

  <div class="mt-7">
    <AnswersField bind:answers={draft.answers} required={true} />
  </div>

  <div class="mt-7">
    <FieldLabel label="Voting Available" required={true}>
      <span class="inline-flex items-center justify-center w-4 h-4 -mt-1 font-bold bg-gray-200 rounded-full text-11" slot="after-required"
        >?
        <Tooltip>Poll will be active from 00:00 to 23:59 inclusive in the creator's time zone.</Tooltip>
      </span>
      <DurationEditor bind:value={draft.dateRange} />
    </FieldLabel>
  </div>

  <div class="my-7">
    <RadioGroupField label="Results" required bind:value={draft.showLiveResults}>
      <span slot="after-required" class="ml-1 text-nxgray-400 text-12">(cannot be changed once poll is active.)</span>
      <RadioField id="private" name="results" value={false} checked={!draft.showLiveResults} readonly={isResultsReadonly}>
        <div class="flex flex-col ml-1 -mt-1 text-12 text-nxgray-600">
          Do not show results
          <span class="text-11 text-nxgray-400">Only the author(s) will see live and final results</span>
        </div>
      </RadioField>
      <div class="mt-4" />
      <RadioField id="public" name="results" value={true} checked={draft.showLiveResults} readonly={isResultsReadonly}>
        <div class="flex flex-col items-start ml-1 -mt-1 text-12 text-nxgray-600">
          Show live results
          <span class="text-11 text-nxgray-400">Voters and author(s) will see live and final results</span>
        </div>
      </RadioField>
    </RadioGroupField>
  </div>
</BlockEditor>
