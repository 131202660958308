<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./Block.svelte";
  import { getNewsletterStores } from "../newsletterStores";
  import { LinkContent } from "./utils";
  import { unescapeHtml } from "@ui/textUtils";

  export let block: schema.EmbedLinkBlock;

  const { design, ctx } = getNewsletterStores();

  $: cta = { id: `l-${block._id}`, url: block.url };
</script>

<Block {block} on:click on:delete on:duplicate let:layout layouts={["md", "lg"]} let:isEmpty showEmpty>
  {#if isEmpty}
    <div class="flex items-center justify-center h-32 p-2 text-gray-600 rounded-lg">
      <div class="flex flex-col items-center justify center">
        <div class="">
          <span class="text-6xl text-gray-500 material-icons-round notranslate">add_link</span>
        </div>

        <h1 class="text-15">Embed a link to a blog or a website</h1>
      </div>
    </div>
  {:else if layout === "lg"}
    <LinkContent {cta} type="link" linkText={unescapeHtml(block.title || "")}>
      <div>
        <div
          style="min-height: 240px;"
          class="flex items-center justify-center w-full overflow-hidden bg-white bg-center bg-no-repeat bg-contain border rounded image-container">
          <img
            src={block.thumbnail_url}
            alt={unescapeHtml(block.title || "")}
            onerror="this.src='https://cdn.smore.com/images/nopreview.1.png'" />
        </div>

        <h1
          class="mt-4 font-bold leading-tight text-16"
          style="color:{$design.background.dark ? '#fff' : $design.color['text.title'].text}">
          {unescapeHtml(block.title || "")}
        </h1>
        <p class="mt-1 text-15" style="color:{$design.background.dark ? '#fff' : $design.color['text.title'].text}">
          {unescapeHtml(block.description || "")}
        </p>

        {#if block.host}
          <div
            class="flex items-center justify-start mt-3 text-13"
            style="color:{$design.background.dark ? '#fff' : $design.color['text.title'].text}">
            <i class="mr-1 material-icons notranslate text-13">launch</i>
            {block.host.replace("www.", "")}
          </div>
        {/if}
      </div>
    </LinkContent>
  {:else}
    <LinkContent {cta} type="link" linkText={unescapeHtml(block.title || "")}>
      <div class="flex flex-col items-start justify-center sm:flex-row">
        {#if block.thumbnail_url}
          <div
            style="max-height:130px;"
            class="flex items-center justify-center flex-shrink-0 mb-4 overflow-hidden bg-white bg-center bg-no-repeat bg-contain border rounded sm:mr-4 image-container sm:w-48">
            <img
              src={block.thumbnail_url}
              alt={unescapeHtml(block.title || "")}
              onerror="this.src='https://cdn.smore.com/images/nopreview.1.png'"
              class="object-contain max-w-full max-h-full bg-transparent" />
          </div>
        {/if}

        <div class="flex-grow">
          <h1 class="font-bold leading-tight text-16" style="color:{$design.background.dark ? '#fff' : $design.color['text.title'].text}">
            {unescapeHtml(block.title || "")}
          </h1>
          <p class="mt-1 text-15" style="color:{$design.background.dark ? '#fff' : $design.color['text.title'].text}">
            {unescapeHtml(block.description || "")}
          </p>

          {#if block.host}
            <div
              class="flex items-center justify-start mt-3 text-13"
              style="color:{$design.background.dark ? '#fff' : $design.color['text.title'].text}">
              <i class="mr-1 material-icons notranslate text-13">launch</i>
              {block.host.replace("www.", "")}
            </div>
          {/if}
        </div>
      </div>
    </LinkContent>
  {/if}
</Block>
