<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./Block.svelte";
  import { getNewsletterStores } from "../newsletterStores";

  export let block: schema.SeparatorBlock;
  const { design } = getNewsletterStores();

  $: color = $design!.color.separator.stripe;

  function height(layout: schema.BlockLayout, style: schema.BlockStyle) {
    const isDotted = style == "dotted";
    switch (layout) {
      case "lg":
        return isDotted ? 9 : 8;
      case "md":
        return isDotted ? 7 : 4;

      case "sm":
      default:
        return isDotted ? 4 : 2;
    }
  }
  function dashArrayPercentage(layout: schema.BlockLayout, style: schema.BlockStyle) {
    let size;
    switch (layout) {
      case "lg":
        return 20;
      case "md":
        return 15;

      case "sm":
      default:
        return 10;
    }
  }

  // The "stroke-dasharray" first argument should be "0" to make the "dotted" line elements rounded!
  // See https://github.com/mapbox/mapbox-gl-style-spec/issues/546
</script>

<Block
  {block}
  on:click
  on:delete
  on:duplicate
  let:layout
  layouts={["sm", "md", "lg"]}
  let:style
  styles={["solid", "dotted", "transparent"]}>
  {@const size = height(layout, style)}
  <div class="ada-bg-black w-full border-none">
    <svg width="100%" height={size}>
      <line
        x1="0"
        y1={size / 2}
        x2="100%"
        y2={size / 2}
        stroke={style === "transparent" ? "transparent" : color}
        stroke-width={size}
        stroke-dasharray={style === "dotted" ? `0, ${dashArrayPercentage(layout, style)}` : 0}
        stroke-dashoffset="-{size}"
        stroke-linecap="round" />
    </svg>
  </div>
</Block>
