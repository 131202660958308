<script lang="ts" context="module">
  export const ResizeInformation = {
    min: { w: 5, h: 2 },
    max: { w: 1500, h: 1500 },
    default: { w: 500, h: 300 }
  };
</script>

<script lang="ts">
  import type { schema } from "@editor/schema";
  import { getNewsletterStores } from "../newsletterStores";
  import Block from "./Block.svelte";
  import { id } from "./utils";
  import { getCurrentUser } from "@ui/currentUser";
  import LogoBlockWeb from "./LogoBlockWeb.svelte";
  import PhotoField from "./form/PhotoField.svelte";
  import { isEmpty as isEmptyTest } from "./blocksConfig";
  import ProBlocker from "@ui/ProBlocker.svelte";

  const currentUser = getCurrentUser();
  const { content, api } = getNewsletterStores();

  export let block: schema.LogoBlock;

  $: isEmpty = isEmptyTest(block);

  function setLogo(picture: schema.Photo) {
    if (!block) {
      block = { _t: "logo", _l: "-", _id: id(), picture, _o: "", _style: "-" };
    }

    content.setLogo({ ...block, picture });
  }

  function removeLogo() {
    content.setLogo(undefined);
  }

  function commitSize() {
    if (!block) {
      return;
    }

    content.updateById<schema.LogoBlock>(block._id, (b) => {
      b.size = size;
    });
  }

  let size = block?.size ?? 50;
</script>

<div class="w-full logo-block">
  {#if isEmpty && $currentUser}
    <ProBlocker cap="newsletter_logo" upgradeReason="add_logo">
      <div class="flex items-center justify-center w-full py-3.5">
        <PhotoField
          value={block?.picture}
          handoffId="logo-photo"
          on:upload_done={(e) => setLogo(e.detail)}
          on:logged_out={api.reLogin}
          ar={ResizeInformation}
          mode="resize"
          showErrorMessage>
          <!-- Empty  -->
          <div slot="empty" let:isOver let:isRelevantDrag let:errorMessage>
            <div
              class="p-1 font-medium bg-white bg-opacity-70 rounded-lg shadow-lg group text-15 active:outline-none focus:outline-none clickable w-56 h-[44px] backdrop-blur-md backdrop-filter">
              {#if errorMessage}
                <div class="w-full py-1 mb-1 text-sm text-center text-white bg-red-600 bg-opacity-75 rounded">
                  {errorMessage}
                </div>
              {/if}
              <div
                class="flex items-center justify-center h-full border-2 border-dashed rounded border-nxgray-200 group-hover:bg-teal-50 group-hover:border-teal-400"
                class:text-teal-600={isOver}
                class:border-teal-400={isRelevantDrag}
                class:bg-teal-100={isOver}>
                <i class="mr-2 material-icons notranslate text-20" class:text-nxgray-400={!isOver}>add_a_photo</i>
                {#if isRelevantDrag}
                  Drop logo here
                {:else}
                  Add your logo
                {/if}
              </div>
            </div>
          </div>
          <img
            class="object-contain"
            style="max-width: 400px; max-height: {size}px;"
            src={previewUrl}
            alt={"Logo"}
            let:previewUrl
            slot="preview" />
        </PhotoField>
      </div>
    </ProBlocker>
  {:else if block}
    <Block {block} let:layout layouts={["side-l", "-", "side-r"]} let:isEmpty on:delete={removeLogo} on:click size="clear">
      <div slot="layout_extras" let:preview>
        <label class="flex flex-col pl-2">
          <span class="block text-xs tracking-wider text-gray-300">SIZE</span>
          <input type="range" class="mt-1 mr-2 w-14" min={40} max={120} step={10} bind:value={size} on:mouseup={commitSize} />
        </label>
      </div>

      <LogoBlockWeb {block} {size} {layout} />
    </Block>
  {/if}
</div>
