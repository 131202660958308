<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./Block.svelte";
  import { LinkContent } from "./utils";

  export let block: schema.EmbedFileBlock;

  let fileSize = 0;
  let fileSizeUnit = "KB";

  $: {
    fileSize = block.file_size_in_bytes / 1024;
    if (fileSize > 1024) {
      fileSize = fileSize / 1024;
      fileSizeUnit = "MB";
    }
  }
</script>

<Block {block} on:click on:delete on:duplicate let:isEmpty showEmpty layouts={["md"]}>
  <LinkContent
    type="file"
    linkText={block.file_name}
    cta={{
      id: `file-${block._id}`,
      url: block.access_url
    }}>
    <div
      class="flex flex-col items-start justify-between w-full gap-2 p-3 pr-4 bg-white border rounded-md shadow md:items-center sm:flex-row ada-no-shadow ada-border-black">
      <div class="flex items-center justify-start text-gray-700">
        <div class="relative hidden -mr-2 sm:block">
          <div class="absolute inset-0 flex items-center justify-center font-bold tracking-wider text-white uppercase text-10">
            {block.file_type}
          </div>
          <div class="text-5xl material-icons-round notranslate text-nxgray-300 ada-text-black">insert_drive_file</div>
        </div>
        <div class="sm:ml-4">
          <div class="font-bold leading-tight break-all text-md text-nxgray-700" title={block.file_name}>{block.file_name}</div>
          <div class="mt-1 text-sm leading-tight text-brand-gray-70">{block.file_description || ""}</div>
        </div>
      </div>
      <span class="flex-shrink-0 font-medium text-center text-teal-600 sm:ml-4 ada-text-black">
        <div class="text-14">Download</div>
        <div class="font-normal text-11 text-brand-gray-70 ada-text-black">{fileSize.toFixed(1)} {fileSizeUnit}</div>
      </span>
    </div>
  </LinkContent>
</Block>
