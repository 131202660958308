<script lang="ts">
  import type { schema } from "@editor/schema";
  import Rich from "../utils/Rich.svelte";
  import Block from "./EmailBlock.svelte";
  import { getNewsletterStores } from "../../newsletterStores";
  import { LinkContent, photoToCTA } from "../utils";
  import IfOutlook from "./helpers/IfOutlook.svelte";
  import EndIfOutlook from "./helpers/EndIfOutlook.svelte";
  import IfNotOutlook from "./helpers/IfNotOutlook.svelte";
  import EndIfNotOutlook from "./helpers/EndIfNotOutlook.svelte";

  export let block: schema.ItemsBlock;

  // WARNING!!!
  // DO NOT RE-FORMAT THE HTML OF THIS COMPONENT!!!
  // Formatting (or prettifying it) causes additional whitespaces that breaks the 3 columns into 2 in Gmail.

  const { design, ctx } = getNewsletterStores();

  let items: schema.ArrayWithHoles<schema.ItemsBlockContent> = Array.isArray(block.items) ? block.items : Object.values(block.items);
  if (block._l === "2-columns") {
    items = items.slice(0, 2);
  }
  // Fixing bug #10250, when there's only one photo in the middle, and the items collection length is 2
  // This means that the first item is null and the second is real photo
  // And we render the null as empty TD, which cause the final result to have two centered cells when the left is empty
  // what looks like the only photo in the items block is not centered as expected and as in the web version.
  // In that specific case, we need to add another null to the end of the items array in order for the only cell with value will look centered
  if (block._l === "3-columns" && items.length === 2) {
    items = [...items, null];
  }

  function responsiveWidthStyle(layout: string) {
    return `min-width:${itemWidth(layout)}px!important;max-width:100%!important;width:calc((580px - 100%) * 1000)!important;`;
  }

  function responsiveHeightStyle(layout: string) {
    return `height:calc(${itemHeight(layout)}px - ${100 / (layout === "2-columns" ? 2 : 3)}vw);min-height:${itemHeight(layout)}px`;
  }

  function itemWidth(layout: string) {
    // total width is 660
    // block is adding horizontal padding of 40
    // we got 620 left
    // we add 10 px padding between images
    // so:
    // for 2 columns, we have 610 / 2 = 305
    // for 3 columns, we have 600 / 3 = 200
    return layout === "2-columns" ? 305 : 200;
  }

  function itemHeight(layout: string) {
    return layout === "2-columns" ? 240 : 160;
  }
</script>

<Block {block} let:layout align={block._l === "list" ? "left" : items.length === 1 ? "left" : "center"}>
  <!-- List layout -->
  {#if layout === "list"}
    <table width="660" style="max-width:660px;width:100%" role="presentation" cellspacing="0" cellpadding="0">
      {#each items as item, index}
        {#if item && item.legacy_thumb_url}
          {#if index > 0}
            <tr><td height="16" /></tr>
          {/if}
          <tr>
            <td align="center" valign="top" width="176">
              <table width="176" cellpadding="0" cellspacing="0">
                <tr
                  ><td
                    style="vertical-align:top;overflow:hidden;border-radius:4px;background-color:transparent"
                    width="176"
                    align="center"
                    bgcolor="transparent">
                    <LinkContent cta={photoToCTA(item)} type="photo" linkText={item.alt_text} class="m-if">
                      <img
                        src={item.legacy_thumb_url}
                        height="120"
                        style="max-height:120px;width:176px;display:block;min-width:100%;height:100%;object-fit:contain;"
                        alt={item.alt_text} />
                    </LinkContent>
                  </td>
                </tr>
              </table>
            </td>

            <td width="16"><span style="display:block">&nbsp;</span></td>
            <td style="vertical-align:top;text-align:left;width:100%" align="left" width="100%">
              {#if item.title}
                <h6 style="margin:0;font-size:18px;font-weight:bold;font-family:sans-serif">
                  {item.title}
                </h6>
              {/if}
              {#if item.description}
                <div style="font-size:15px;color:#505050;">
                  <Rich content={item.description} inline context={{ type: "photo" }} />
                </div>
              {/if}
            </td>
          </tr>
        {/if}
      {/each}
    </table>
  {:else}
    <!-- Columns layout  -->
    <IfOutlook />
    <table cellpadding="0" cellspacing="0" width="660px" role="presentation" align="center" style="width:660px;">
      <tr>
        <EndIfOutlook />

        {#each items as item, index}
          <IfOutlook />
          <td width={itemWidth(layout)} style="padding:0!important;width:{itemWidth(layout)}px;" valign="top" align="center">
            <EndIfOutlook />
            <div style="display:inline-block;vertical-align:top;margin:0 10px 0 0!important;{responsiveWidthStyle(layout)};">
              <table
                style="padding:0!important;margin:0!important;max-width:{itemWidth(layout)}px;float:left;"
                width={itemWidth(layout)}
                align="center"
                cellpadding="0"
                cellspacing="0"
                role="presentation">
                <tr>
                  <td
                    align="center"
                    valign="middle"
                    bgcolor="transparent"
                    style="border-radius: 4px;overflow:hidden;height:{itemHeight(layout)}px;"
                    height="{itemHeight(layout)}px">
                    {#if item}
                      <LinkContent
                        cta={photoToCTA(item)}
                        type="photo"
                        linkText={item.alt_text}
                        class="m-if"
                        style={`display:block;border-radius:4px;height:${itemHeight(
                          layout
                        )}px;vertical-align:middle;background-color:transparent;`}>
                        <IfOutlook />
                        <v:rect
                          stroked="false"
                          style="width:{itemWidth(layout)}px;height:{itemHeight(layout)}px;"
                          xmlns:v="urn:schemas-microsoft-com:vml">
                          <v:fill type="frame" color="#ffffff" aspect="atmost" src={item.legacy_thumb_url} />
                        </v:rect>
                        <EndIfOutlook />
                        <IfNotOutlook />
                        <img
                          width={itemWidth(layout)}
                          src={item.legacy_thumb_url}
                          style="display:block;width:100%;height:100%;object-fit: contain;max-height:{itemHeight(
                            layout
                          )}px;max-width:{itemWidth(layout)}px;"
                          alt={item.alt_text} />
                        <EndIfNotOutlook />
                      </LinkContent>
                    {/if}
                  </td>
                </tr>
                {#if item && item.title}
                  <tr>
                    <td style="padding-top:10px;" align={item.align || "left"} valign="top">
                      <h6
                        style="word-wrap:break-word;margin:0;font-size:18px;font-weight:bold;font-family:sans-serif;text-align:{item.align ||
                          'left'};">
                        {item.title}
                      </h6>
                    </td>
                  </tr>
                {/if}
                {#if item && item.description}
                  <tr>
                    <td style="padding-top:10px;" align="left" valign="top">
                      <div style="word-wrap:break-word;font-size:15px;color:#505050;text-align:{item.align || 'left'};">
                        <Rich content={item.description} inline context={{ type: "photo" }} />
                      </div>
                    </td>
                  </tr>
                {/if}
              </table>
            </div>
            <IfOutlook />
          </td>
          <EndIfOutlook />
        {/each}
        <IfOutlook />
      </tr>
    </table>
    <EndIfOutlook />
    <div style="clear:both;" />
  {/if}
</Block>
