import { Component, Padding, Position, toChildren, Size, childrenSize, padSize, padPosition } from "./base";

type StageProps = {
  children?: Component[] | Component;
  width: number;
  height?: number;
  borderRadius?: number;
};

export function Stage(props: StageProps): Component {
  const { width, height, borderRadius } = props;

  const children = toChildren(props.children);

  const size = childrenSize({ w: width, h: height }, children, {
    type: "layers"
  });

  return {
    size(av: Size) {
      return { w: width, h: height! };
    },

    toSVG() {
      // TODO: Handle auto height
      return `
      <svg viewbox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg" style="${
        borderRadius ? `border-top-right-radius:${borderRadius}px;border-top-left-radius:${borderRadius}px;` : ""
      }">
      ${children.map((c) => c.toSVG({ w: width, h: height }, { x: 0, y: 0 })).join("")}
      </svg>
      `;
    }
  };
}

type LayerProps = {
  children?: Component[] | Component;
  padding?: Padding;
  transform?: TransformFunction;
};

export type ChildTransformInfo = { c: Component; pos: Position; size: Size };

export type TransformFunction = (av: Size, p: Position) => [[Size, Position], (svgOut: string, children: ChildTransformInfo[]) => string];

// Simple transform with no op
const noopT: TransformFunction = (av: Size, p: Position) => [[av, p], (svg, c) => svg];

export function Layer(props: LayerProps): Component {
  const { padding } = props;
  const children = toChildren(props.children);

  return {
    toSVG(as: Size, p: Position) {
      const [[at, pt], tr] = (props.transform || noopT)(as, p);

      p = padPosition(padding, pt);
      const ap = padSize(padding, at);

      return tr(
        children.map((c) => c.toSVG(ap, p)).join(""),
        children.map((c) => ({ c, pos: p, size: ap }))
      );
    },
    size(as) {
      // transform
      const [[at, pt], tr] = (props.transform || noopT)(as, { x: 0, y: 0 });

      // available size after padding
      const ap = padSize(padding, at);

      return childrenSize(ap, children, { type: "layers" });
    }
  };
}

type FlexProps = {
  children?: Component[] | Component;
  padding?: Padding;
  dir?: "row" | "col";
  justify?: "start" | "end" | "center" | "between" | "around";
  align?: "stretch" | "start" | "end" | "center";
  spacing?: number;
  transform?: TransformFunction;
};

export function Flex(props: FlexProps): Component {
  const { padding, dir, justify, align } = props;
  const children = toChildren(props.children);

  const spacing = props.spacing || 0;

  return {
    toSVG(as, p) {
      // transform
      const [[at, pt], tr] = (props.transform || noopT)(as, p);

      p = padPosition(padding, pt);
      const ap = padSize(padding, at);
      let out = "";
      let h = 0;

      const ci: ChildTransformInfo[] = [];

      // calculate the children size so we could position them properly
      const cs = this.size(as);
      const flexO: FlexOpts = { dir, justify, align };
      const flexP = flexPos(ap, cs, flexO);
      // console.log("flex", "cs", cs, "flexO", flexO, "flexP", flexP, "ap", ap, "p", p, "at", at, "pt", pt);

      children.forEach((c, idx) => {
        // handle the spacing
        idx && (h += spacing);

        const s = c.size({ w: ap.w, h: Math.max(ap.h - h, 0) });
        // render using the available space
        // TODO: handle alignment
        // TODO: handle spacing, direction
        const size = { w: cs.w, h: s.h };
        const pos = { x: p.x + flexP.x, y: p.y + h + flexP.y };
        // TODO: this is a hack for now, we need to center everything, we means we should call flexPos for every item
        out += c.toSVG(size, pos);

        // add for the child transformation
        ci.push({ c, pos, size });
        h += s.h;
      });

      return tr(out, ci);
    },
    size(as) {
      // flex elements always take over all the size

      // transform
      const [[at, pt], tr] = (props.transform || noopT)(as, { x: 0, y: 0 });

      // available size after padding
      const ap = padSize(padding, at);

      return childrenSize(ap, children, { type: "stack", spacing });
    }
  };
}

type FlexOpts = {
  dir?: "row" | "col";
  justify?: "start" | "end" | "center" | "between" | "around";
  align?: "stretch" | "start" | "end" | "center";
};

// Return an accurate position based on the size and the flex options
function flexPos(as: Size, size: Size, opts: FlexOpts): Position {
  const justify = opts.justify || "start";
  const align = opts.align || "start";
  const dir = opts.dir || "col";

  const r: Position = { x: 0, y: 0 };

  // handle justify (vertical align for col)
  switch (justify) {
    case "start":
      r.y = 0;
      break;
    case "end":
      // end - align to the bottom
      r.y = as.h - size.h;
      break;
    case "center":
      r.y = (as.h - size.h) / 2;
      break;
  }

  switch (align) {
    case "stretch":
    case "start":
      r.x = 0;
      break;
    case "center":
      r.x = (as.w - size.w) / 2;
      break;
    case "end":
      r.x = as.w - size.w;
  }

  return r;
}
